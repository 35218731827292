import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class AsideStateService {
  private asideStateService = new Subject<any>(); // open aside and get active header item

  private asideState: boolean; // state of aside component
  private asideItem: string; // item to be displayed in aside

  private renderer: Renderer2;
  private listenerFn: () => void;

  constructor(private rendererFactory: RendererFactory2, private router: Router) {
    router.events.pipe(filter(e => e instanceof NavigationEnd)).forEach(e => this.closeAside());

    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.asideState = false;
    this.asideItem = '';
  }

  /**
   * subscibe to recieve state and selected item as observable
   */
  getAsideState(): Observable<any> {
    return this.asideStateService.asObservable();
  }

  /**
   * functionality to opens aside component, requires item parametar
   * @param item user selected header item to be presented in
   * aside component. Options: 'settings', 'notifications' or 'user'
   */
  openAside(item: string) {
    if (item === this.asideItem) {
      this.setAsideState(false);
    } else {
      this.setAsideState(true, item);
    }
  }

  closeAside() {
    this.setAsideState(false);
  }

  private setAsideState(state: boolean, item: string = ''): void {
    if (state === true) {
      // request to open
      this.asideState = true;
      this.asideItem = item;

      // add state class for custom styles
      document.body.classList.add('aside-open');
      // add reference to eventListener
      setTimeout(() => {
        this.listenerFn = this.renderer.listen(document, 'click', this.onClick.bind(this));
      }, 10);
    } else {
      // request to close
      this.asideState = false;
      this.asideItem = '';

      // remove state class
      document.body.classList.remove('aside-open');
      // remove reference to eventListener if exists
      if (this.listenerFn) {
        this.listenerFn();
      }
    }

    // send data to subscibers
    this.asideStateService.next({ state: this.asideState, item: this.asideItem });
  }

  /**
   * event listener to close aside when user clicks away from this component
   * @param event click event from user
   */
  private onClick(event): void {
    const ignoredElements =
      'app-header .menu-icon, app-navbar [data-ignore-close], app-header .header-options, app-header [data-ignore-close], app-header [data-ignore-close] *, .aside-wrapper, .aside-wrapper *';

    if (!event.target.matches(ignoredElements)) {
      this.closeAside();
    }
  }
}
