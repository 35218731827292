<div class="userAccessPage">
  <div class="app-subheader-wrapper">
    <app-subheader
      [title]="
        (userFullName == null || userFullName == '') && (userEmail == null || userEmail == '')
          ? userCode
          : (userFullName == '' || userFullName == null ? '' : userFullName + '/') + userEmail + ' Permisions'
      "
      [back]="true">
      <button subheader-right class="btn btn-light" (click)="addNewUserGroup()">
        <span class="icon tt-icon"></span> Add new
      </button>
    </app-subheader>
  </div>

  <div class="content content-tabs" [appLoadingDirective]="loadingStatus">
    <div class="content-inner content-tabs-inner">
      <div class="tabs">
        <div
          class="tab"
          *ngFor="let userAccess of userData; let i = index"
          [class.active]="activeUserAccess.index == i"
          (click)="updateActiveLevel(userAccess, i)">
          <div class="type">Type: {{ userAccess.userTypeName }}</div>
          <div *ngIf="userAccess.userGroupCode != ''">Group type: {{ userAccess.userGroupName }}</div>
          <div *ngIf="userAccess.userGroupCode != ''">Group: {{ userAccess.userGroupValueName }}</div>
          <div class="status" *ngIf="userAccess.userStatusCode == 'I'">{{ userAccess.userStatusName }}</div>
          <div class="statusSelect" *ngIf="userAccess.userStatusCode !== 'I'">
            <div class="ui-control text-center" style="margin-top: 0 !important">
              <span
                class="ui-box"
                [class.color-white]="activeUserAccess.index == i"
                style="padding-bottom: 0 !important">
                <ng-container *ngFor="let status of userStatus">
                  <p-radioButton
                    class="box-success"
                    (onClick)="userGroupStatusChange(userAccess, i); $event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    *ngIf="
                      (status.userStatusCode !== 'I' && status.userStatusCode !== 'D') ||
                      userAccess.userStatusCode == status.userStatusCode
                    "
                    [disabled]="
                      userAccess.userStatusCode == 'I' ||
                      ((status.userStatusCode == 'P' || status.userStatusCode == 'B') &&
                        userCode == myUserData.userCode)
                    "
                    [label]="status.userStatusName"
                    [value]="status.userStatusCode"
                    [(ngModel)]="userAccess.userStatusCode"
                    [name]="i"></p-radioButton>
                </ng-container>
              </span>
            </div>
          </div>
          <button
            class="btn btn-icon options"
            [disabled]="userCode == myUserData.userCode"
            [class.active]="activeUserAccess.index == i"
            (click)="
              showAccessOptions($event, { userAccess: userAccess, index: i }, accessOptions); $event.stopPropagation()
            ">
            <span [appIcon]="'tt-more'"></span>
          </button>
        </div>

        <div class="tab" *ngIf="userData.length == 0" style="padding: 1em; text-align: center">
          <button class="btn btn-primary" style="width: 100%; padding: 0px 8px" (click)="addNewUserGroup()">
            <span class="icon tt-icon"></span> Add new
          </button>
        </div>
      </div>

      <div class="tab-section" *ngIf="loadingStatus.show === false && activeUserAccess.index != null">
        <div [appLoadingDirective]="loadingFirstLevelStatus">
          <div class="block-divider">
            <span class="divider-title">Permision modules</span>
            <label class="divider-line"></label>
          </div>

          <div class="permisionModules">
            <div
              class="permisionModule"
              [class.hasDetails]="module.hasDetails"
              *ngFor="let module of activeUserAccess.moduleData; let i = index">
              <div class="header" (click)="openModulePostTypes(module, i)">
                <div class="name">Module: {{ module.userModuleName }}</div>
                <div>Level: {{ module.userAccessName }}</div>
                <button
                  class="btn btn-icon options"
                  (click)="showOptions($event, { module: module, moduleIndex: i }, options); $event.stopPropagation()">
                  <span [appIcon]="'tt-more'"></span>
                </button>
              </div>

              <div
                class="toggleDetails"
                [class.opened]="module.openDetails"
                (click)="openModulePostTypes(module, i); $event.stopPropagation()">
                <i (click)="openModulePostTypes(module, i); $event.stopPropagation()" class="fa fa-chevron-down"></i>
              </div>
              <div class="modulePostTypes" *ngIf="module.openDetails" [appLoadingDirective]="loadingSecondLevelStatus">
                <div class="block-divider" style="padding: 0em 1em">
                  <span class="divider-title">Post type permisions</span>
                  <label class="divider-line"></label>
                </div>

                <div class="detailRow" *ngFor="let modulePostType of module.postTypes; let j = index">
                  <div
                    class="header"
                    (click)="openModuleTypeDetails(module, i, modulePostType, j); $event.stopPropagation()">
                    <div class="name">Type: {{ modulePostType.postTypeName }}</div>
                    <div>Level: {{ modulePostType.userAccessName }}</div>
                    <button
                      class="btn btn-icon options"
                      (click)="
                        showOptions(
                          $event,
                          { module: module, moduleIndex: i, modulePostType: modulePostType, modulePostTypeIndex: j },
                          options
                        );
                        $event.stopPropagation()
                      ">
                      <span [appIcon]="'tt-more'"></span>
                    </button>
                  </div>

                  <div
                    class="toggleDetailDetails"
                    *ngIf="!modulePostType.openDetails"
                    [class.opened]="modulePostType.openDetails">
                    <i
                      (click)="openModuleTypeDetails(module, i, modulePostType, j); $event.stopPropagation()"
                      class="fa fa-chevron-down"></i>
                  </div>

                  <div
                    class="detailDetails"
                    *ngIf="modulePostType.openDetails"
                    [appLoadingDirective]="loadingThirdLevelStatus">
                    <div class="block-divider" style="padding: 0em 1em">
                      <span class="divider-title">Post type category permisions</span>
                      <label class="divider-line"></label>
                    </div>
                    <div class="categoryRow" *ngFor="let category of modulePostType.postTypeCategories; let k = index">
                      <div class="header">
                        <div class="name">Category: {{ category.postTypeCategoryName }}</div>
                        <div>Level: {{ category.userAccessName }}</div>
                        <button
                          class="btn btn-icon options"
                          (click)="
                            showOptions(
                              $event,
                              {
                                module: module,
                                moduleIndex: i,
                                modulePostType: modulePostType,
                                modulePostTypeIndex: j,
                                modulePostTypeCategory: category,
                                modulePostTypeCategoryIndex: k
                              },
                              options
                            );
                            $event.stopPropagation()
                          ">
                          <span [appIcon]="'tt-more'"></span>
                        </button>
                      </div>
                    </div>
                    <div class="addNew">
                      <button
                        class="btn btn-default addNew"
                        (click)="addNewModuleTypeCategory(module, i, modulePostType, j)">
                        <span class="icon tt-icon"></span> Add new post type category for
                        {{ modulePostType.postTypeName }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="addNew">
                  <button class="btn btn-default addNew" (click)="addNewModuleType(i)">
                    <span class="icon tt-icon"></span> Add new post type
                  </button>
                </div>
              </div>
            </div>
            <div class="addNew">
              <button class="btn btn-default addNew" (click)="addNewModule()">
                <span class="icon tt-icon"></span> Add new module
              </button>
            </div>
          </div>

          <div *ngIf="availableProperties.length > 0" class="propertyAccessContainer">
            <div class="block-divider">
              <span class="divider-title">Property access</span>
              <label class="divider-line"></label>
            </div>

            <div class="note">
              <p>By default users have access to all properties of the client</p>
              <p>If you only want them to have access to certain properties, please check them below</p>
            </div>

            <div class="propertyAccess">
              <p-checkbox
                class="box-rounded"
                [value]="property.businessPartnerPropertyCode"
                [label]="property.businessPartnerPropertyName"
                [(ngModel)]="selectedPropertyCodes"
                *ngFor="let property of availableProperties"></p-checkbox>

              <button class="btn btn-default" (click)="updateUserProperties()">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-overlayPanel appendTo="body" #options>
  <div class="panel-option">
    <button class="btn" (click)="options.hide(); deleteOption(activeDropdownOptionsData)">
      <i class="fa fa-trash-o" aria-hidden="true"></i>
      Delete
    </button>
  </div>
</p-overlayPanel>

<p-overlayPanel appendTo="body" #accessOptions>
  <div class="panel-option">
    <button class="btn" (click)="accessOptions.hide(); deleteAccessOption(activeAccessDropdownOptionsData)">
      <i class="fa fa-trash-o" aria-hidden="true"></i>
      Delete
    </button>
  </div>
</p-overlayPanel>

<p-dialog
  header="Add module"
  [draggable]="false"
  [(visible)]="addNewModuleView"
  modal="modal"
  width="450"
  [responsive]="true"
  [appendTo]="'body'">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-control">
        <span class="ui-float-label">
          <p-dropdown
            [options]="userModules"
            [autoDisplayFirst]="false"
            [(ngModel)]="userModuleModel"
            optionLabel="userModuleName"
            [appendTo]="'body'"></p-dropdown>
          <label>User module</label>
        </span>
      </div>
    </div>
    <div class="ui-g-12">
      <div class="ui-control">
        <span class="ui-float-label">
          <p-dropdown
            [options]="userAccess"
            [autoDisplayFirst]="false"
            [(ngModel)]="userAccessModel"
            optionLabel="userAccessName"
            [appendTo]="'body'"></p-dropdown>
          <label>User access level</label>
        </span>
      </div>
    </div>
  </div>

  <p-footer>
    <button
      class="btn btn-default"
      [disabled]="userModuleModel === null || userAccessModel === null"
      (click)="saveNewUserModule()">
      Add
    </button>
    <button class="btn btn-default" (click)="addNewModuleView = false">Close</button>
  </p-footer>
</p-dialog>

<p-dialog
  header="Add module type"
  [draggable]="false"
  [(visible)]="addNewPostTypeView"
  modal="modal"
  width="450"
  [responsive]="true"
  [appendTo]="'body'">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-control">
        <span class="ui-float-label">
          <p-dropdown
            [options]="postType"
            [autoDisplayFirst]="false"
            [(ngModel)]="postTypeModel"
            optionLabel="postTypeName"
            [appendTo]="'body'"></p-dropdown>
          <label>Post type</label>
        </span>
      </div>
    </div>
    <div class="ui-g-12">
      <div class="ui-control">
        <span class="ui-float-label">
          <p-dropdown
            [options]="userAccess"
            [autoDisplayFirst]="false"
            [(ngModel)]="userAccessModel"
            optionLabel="userAccessName"
            [appendTo]="'body'"></p-dropdown>
          <label>User access level</label>
        </span>
      </div>
    </div>
  </div>

  <p-footer>
    <button
      class="btn btn-default"
      [disabled]="postTypeModel === null || userAccessModel === null"
      (click)="saveNewUserModuleType()">
      Add
    </button>
    <button class="btn btn-default" (click)="addNewPostTypeView = false">Close</button>
  </p-footer>
</p-dialog>

<p-dialog
  header="Add module type category"
  [draggable]="false"
  [(visible)]="addNewPostTypeCategoryView"
  modal="modal"
  width="450"
  [responsive]="true"
  [appendTo]="'body'">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-control">
        <span class="ui-float-label">
          <p-dropdown
            [options]="postTypeCategory"
            [autoDisplayFirst]="false"
            [(ngModel)]="postTypeCategoryModel"
            optionLabel="postTypeCategoryName"
            [appendTo]="'body'"></p-dropdown>
          <label>Post type category</label>
        </span>
      </div>
    </div>
    <div class="ui-g-12">
      <div class="ui-control">
        <span class="ui-float-label">
          <p-dropdown
            [options]="userAccess"
            [autoDisplayFirst]="false"
            [(ngModel)]="userAccessModel"
            optionLabel="userAccessName"
            [appendTo]="'body'"></p-dropdown>
          <label>User access level</label>
        </span>
      </div>
    </div>
  </div>

  <p-footer>
    <button
      class="btn btn-default"
      [disabled]="postTypeCategoryModel === null || userAccessModel === null"
      (click)="saveNewUserModuleTypeCategory()">
      Add
    </button>
    <button class="btn btn-default" (click)="addNewPostTypeCategoryView = false">Close</button>
  </p-footer>
</p-dialog>

<p-dialog
  header="Add new user group"
  [draggable]="false"
  [(visible)]="addNewUserGroupView"
  modal="modal"
  width="550"
  [responsive]="true"
  [appendTo]="'body'">
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-control">
        <span class="ui-float-label">
          <p-dropdown
            [options]="allowedUserTypes"
            [autoDisplayFirst]="false"
            [(ngModel)]="inviteUserDataForUser.userType"
            optionLabel="userTypeName"
            [appendTo]="'body'"
            (onChange)="userTypeChangedForUser()"></p-dropdown>
          <label>User type</label>
        </span>
      </div>
    </div>

    <div
      class="ui-g-12"
      *ngIf="
        inviteUserDataForUser.userType !== null &&
        (inviteUserDataForUser.userType.userTypeCode == '2' || inviteUserDataForUser.userType.userTypeCode == '3') &&
        institutionUserGroup.length > 0
      ">
      <div class="ui-control">
        <span class="ui-float-label">
          <p-dropdown
            [disabled]="institutionUserGroup.length == 1"
            [options]="institutionUserGroup"
            [autoDisplayFirst]="false"
            [(ngModel)]="inviteUserDataForUser.userGroup"
            optionLabel="userGroupName"
            [appendTo]="'body'"
            (onChange)="userGroupChangedForUser()"></p-dropdown>
          <label>User group</label>
        </span>
      </div>
    </div>

    <div class="ui-g-12" *ngIf="inviteUserDataForUser.userGroup !== null">
      <div class="ui-control">
        <span class="ui-float-label">
          <p-dropdown
            [options]="institutionUserGroupValues"
            [autoDisplayFirst]="false"
            [(ngModel)]="inviteUserDataForUser.userGroupValue"
            [optionLabel]="inviteUserDataForUser.userGroup.userGroupNameColumnName"
            [appendTo]="'body'"></p-dropdown>
          <label>User group value</label>
        </span>
      </div>
    </div>
  </div>
  <p-footer>
    <button
      class="btn btn-default"
      [disabled]="
        inviteUserDataForUser.userType == null ||
        ((inviteUserDataForUser.userType.userTypeCode == '2' || inviteUserDataForUser.userType.userTypeCode == '3') &&
          inviteUserDataForUser.userGroupValue == null)
      "
      (click)="inviteNewUserGroupConfirm()">
      Invite
    </button>
    <button class="btn btn-default" (click)="addNewUserGroupView = false">Close</button>
  </p-footer>
</p-dialog>
