import { Injectable } from '../../../../node_modules/@angular/core';

import { Database, DatabaseResponse } from '../../core/services/database';
import { SharedService } from '../../core/services/shared-service';

export interface HierarchySystemValue {
    hierarchySystemValueCode: number;
    hierarchySystemValueName: string;
    hierarchySystemValueDisplayName: string;
    hierarchySystemValueTable: string;
}
export interface HierarchySystemImage {
    hierarchySystemImageCode: number;
    hierarchySystemImageName: string;
    hierarchySystemImageDisplayName: string;
}
@Injectable()
export class SyncReportData {
    hierarchySystemValues: HierarchySystemValue[];
    hierarchySystemImages: HierarchySystemImage[];



    constructor(
        private _database: Database,
        private _shared: SharedService) {
        this.hierarchySystemValues = [];
        this.hierarchySystemImages = [];
    }

    private loadGathers(cb) {
        let total = 0;
        this._database.executeRequest('/api/hierarchySystemValues', 'get', {})
            .then((gatherRes: DatabaseResponse & { data: HierarchySystemValue[] }) => {
                this.hierarchySystemValues = gatherRes.data;
                finishedGather();
            }, (err) => {
                this.hierarchySystemValues = [];
                finishedGather();
            });
        this._database.executeRequest(`/api/hierarchySystemImagesGather`, 'get', {})
            .then((gatherRes: DatabaseResponse & { data: HierarchySystemImage[] }) => {
                this.hierarchySystemImages = gatherRes.data;
                finishedGather();
            }, (err) => {
                this.hierarchySystemImages = [];
                finishedGather();
            });
        function finishedGather() {
            total = total + 1;
            if (total === 2) {
                cb(true);
            }
        }
    }

    runReplacement(value): Promise<string> {
        return new Promise((resolve) => {
            this.loadGathers((res) => {
                const tempParent = document.createElement('div');
                tempParent.innerHTML = value;
                // prepare system values
                const $inputs = Array.from(tempParent.getElementsByTagName('input'));
                for (let i = 0; i < $inputs.length; i++) {
                    const element = $inputs[i];
                    if (element.getAttribute('data-systemvalue') === undefined || element.getAttribute('data-systemvalue') === null) {
                        const newElement = document.createElement('span');
                        element.parentNode.replaceChild(newElement, element);
                    } else {
                        const systemValue = element.getAttribute('data-systemvalue').replace(/\{\{/g, '').replace(/\}\}/g, '');
                        const systemTable = element.getAttribute('data-systemtable');
                        const displayValue = '';

                        const gatherRow: HierarchySystemValue | null = this._shared.getGatherRow(this.hierarchySystemValues, 'hierarchySystemValueName', systemValue);
                        const newElement = document.createElement('span');
                        newElement.setAttribute('data-systemcode', gatherRow !== null ? gatherRow.hierarchySystemValueCode.toString() : null);
                        newElement.setAttribute('data-systemvalue', systemValue);
                        newElement.setAttribute('data-displayvalue', gatherRow !== null ? gatherRow.hierarchySystemValueDisplayName.toString() : null);
                        newElement.setAttribute('data-systemtable', systemTable);
                        newElement.setAttribute('contenteditable', 'false');
                        newElement.innerHTML = `{{${systemValue}}}`;
                        newElement.classList.add('systemValuePlaceholder');
                        element.parentNode.replaceChild(newElement, element);
                    }
                }
                // prepare system images
                const $images = Array.from(tempParent.querySelectorAll('a.imgPlaceholderHref'));
                for (let i = 0; i < $images.length; i++) {
                    const element = $images[i];
                    const imageElement = element.querySelector('img');
                    if (imageElement) {
                        const systemValue = element.querySelector('img').getAttribute('data-systemvalue');
                        const width = element.querySelector('img').getAttribute('width');
                        const height = element.querySelector('img').getAttribute('height');

                        const gatherRow: HierarchySystemImage | null = this._shared.getGatherRow(this.hierarchySystemImages, 'hierarchySystemImageName', systemValue);
                        const newElement = document.createElement('img');
                        newElement.setAttribute('data-systemcode', gatherRow !== null ? gatherRow.hierarchySystemImageCode.toString() : null);
                        newElement.setAttribute('data-systemvalue', systemValue);
                        newElement.setAttribute('data-displayvalue', gatherRow !== null ? gatherRow.hierarchySystemImageDisplayName : null);
                        newElement.setAttribute('width', width);
                        newElement.setAttribute('height', height);
                        newElement.setAttribute('title', `{{${systemValue}}}`);
                        newElement.setAttribute('src', `http://via.placeholder.com/1100x620/56DF9A?text=${gatherRow.hierarchySystemImageDisplayName}`);
                        element.parentNode.replaceChild(newElement, element);
                    } else {
                        element.parentNode.removeChild(element);
                    }
                }
                resolve(tempParent.innerHTML);
            });
        });
    }
}
