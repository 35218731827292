import { NgModule } from '@angular/core';

// modules
import { SharedModule } from '../../shared/shared.module';

// components
import { NotificationService } from './notification.service';
import { NotificationComponent } from './notification.component';

@NgModule({
  imports: [SharedModule],
  declarations: [NotificationComponent],
  exports: [NotificationComponent],
  providers: [NotificationService]
})
export class NotificationModule { }
