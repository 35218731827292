<p-dialog header="Feedback view" [draggable]="false" [(visible)]="open" modal="modal" width="750" [responsive]="true" [appendTo]="'body'"
  (onHide)="emmitClose()">
  <div class="inline-form-info" [appLoadingDirective]="loadingStatus">
    <h3>Feedback information</h3>
    <div class="ui-control ui-inline-control">
      <span class="input-label">Title</span>
      <strong>{{userFeedback.userFeedbackTitle == '' ? '-' : userFeedback.userFeedbackTitle }}</strong>
    </div>
    <div class="ui-control ui-inline-control">
      <span class="input-label">Description</span>
      <strong>{{userFeedback.userFeedbackDescription == '' ? '-' : userFeedback.userFeedbackDescription }}</strong>
    </div>
    <div class="ui-control ui-inline-control">
      <span class="input-label">Time</span>
      <strong>{{userFeedback.createDateTime == '' ? '-' : getFormatDate(userFeedback.createDateTime) }}</strong>
    </div>
    <div class="ui-control ui-inline-control">
      <span class="input-label">Status</span>
      <strong>{{userFeedback.userFeedbackStatusName}}</strong>
    </div>

    <h3>User information</h3>
    <div class="ui-control ui-inline-control">
      <span class="input-label">Name</span>
      <strong>{{userFeedback.userFeedbackName == '' ? '-' : userFeedback.userFeedbackName }}</strong>
    </div>
    <div class="ui-control ui-inline-control">
      <span class="input-label">Email</span>
      <strong>{{userFeedback.userFeedbackEmail == '' ? '-' : userFeedback.userFeedbackEmail }}</strong>
    </div>
    <div class="ui-control ui-inline-control">
      <span class="input-label">Phone number</span>
      <strong>{{userFeedback.userFeedbackPhoneNumber == '' ? '-' : userFeedback.userFeedbackPhoneNumber }}</strong>
    </div>
    <div class="ui-control ui-inline-control">
      <span class="input-label">Contact me</span>
      <strong>{{userFeedback.userFeedbackContactMe}}</strong>
    </div>


    <div class="ui-control ui-inline-control" style="background: white" *ngIf="userFeedbackAttachments && userFeedbackAttachments.length">
      <div class="drop-panel" [class.panel-open]="showAttachments" style="width: 100%">
        <div class="panel-head" (click)="showAttachments = !showAttachments">
          <div class="block-divider">
            <h3 class="divider-title" style="width:100%;">
              Attachments
              <span [appIcon]="'fa-chevron-up'" *ngIf="showAttachments"></span>
              <span [appIcon]="'fa-chevron-down'" *ngIf="!showAttachments"></span>
            </h3>
            <div class="divider-arrow">
              <span class="icon tt-Path-221"></span>
            </div>
          </div>
        </div>

        <div class="panel-content" style="max-height: 15em;overflow-y: scroll">
          <div *ngIf="userFeedbackAttachments.length  === 0">No attachments</div>
          <div *ngIf="userFeedbackAttachments.length  > 0">
            <div *ngFor="let attachment of userFeedbackAttachments" style="margin-bottom: .5em;">
              <a [href]="attachment.documentsPath " download>- {{attachment.documentsOriginalName}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="ui-control ui-inline-control" style="background: white" *ngIf="userFeedbackImages && userFeedbackImages.length">
      <div class="drop-panel " [class.panel-open]="showImages " style="width: 100% ">
        <div class="panel-head " (click)="showImages=! showImages ">
          <div class="block-divider ">
            <h3 class="divider-title " style="width:100%;">
              Images
              <span [appIcon]="'fa-chevron-up'" *ngIf="showImages"></span>
              <span [appIcon]="'fa-chevron-down'" *ngIf="!showImages"></span>
            </h3>
            <div class="divider-arrow ">
              <span class="icon tt-Path-221 "></span>
            </div>
          </div>
        </div>

        <div class="panel-content " style="max-height: 15em;overflow-y: scroll ">
          <div *ngIf="userFeedbackImages.length === 0 ">No images</div>
          <div class="file-preview-box" *ngIf="userFeedbackImages.length > 0">
            <div class="file-preview" *ngFor="let image of userFeedbackImages; let i = index">
              <!-- image -->
              <div class="file-container" (click)="lightboxInit(i)">
                <div class="file-image" [style.background-image]="'url(' + image.mediaThumbPath + ')'"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-control ui-inline-control" style="background: white" *ngIf="userFeedback.userFeedbackLogData && userFeedback.userFeedbackLogData.length">
      <div class="drop-panel" [class.panel-open]="showLog" style="width: 100%">
        <div class="panel-head" (click)="showLog = !showLog">
          <div class="block-divider">
            <h3 class="divider-title" style="width:100%;">
              Log data
              <span [appIcon]="'fa-chevron-up'" *ngIf="showLog"></span>
              <span [appIcon]="'fa-chevron-down'" *ngIf="!showLog"></span>
            </h3>
            <div class="divider-arrow">
              <span class="icon tt-Path-221"></span>
            </div>
          </div>
        </div>

        <div class="panel-content" style="max-height: 15em;overflow-y: scroll">
          <div *ngIf="userFeedback.userFeedbackLogData == ''">No logs traced.</div>
          <div *ngIf="userFeedback.userFeedbackLogData != ''">
            <div *ngFor="let log of userFeedback.userFeedbackLogData" style="margin-bottom: 1em; border-bottom: 2px solid #edeff0">
              <div class="ui-control ui-inline-control">
                <span class="input-label">Url</span>
                <strong>{{log.url}}</strong>
              </div>
              <div class="ui-control ui-inline-control">
                <span class="input-label">Error</span>
                <strong>{{log.message}}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <p-footer>
    <button class="btn btn-primary" (click)="emmitClose();">
      Close
    </button>
  </p-footer>
</p-dialog>
