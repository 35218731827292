<div class="c-header-wrap">
  <div class="c-header-title">
    <h3 *ngIf="title"> {{title}} </h3>
    <ng-content select="[left]"></ng-content>
  </div>

  <div class="c-header-content">
    <ng-content select="[center]"></ng-content>
  </div>

  <div class="c-header-options">
    <ng-content select="[right]"></ng-content>
  </div>
</div>