<div class="app-master-wrapper">
  <header class="app-header-wrapper">
    <app-header></app-header>
  </header>
  <!-- END: HEADER -->

  <main class="app-main-wrapper">
    <aside class="app-navbar-wrapper" [@navState]="animationState">
      <app-navbar></app-navbar>
    </aside>
    <!-- END: ASIDE => MENU -->

    <section class="app-section-wrapper" [@mainState]="animationState">
      <router-outlet></router-outlet>
    </section>
    <!-- END: CONTENT => ROUTES  -->
  </main>
  <!-- END: MAIN -->

</div>