declare interface IconsInfo {
  name: string,
  path: string
};

// USAGE:       <span [appIcon]="'icon-name'"></span>
// EXAMPLES:    { "name": "iconName", "path": '<span class="path1">' }
// PARSED_ICONS
export const Icons: IconsInfo[] = [
  { "name": "", "path": "" },
  { "name": "tt", "path": "<span class='path1'></span><span class='path2'></span><span class='path3'></span>" },
  { "name": "tt2", "path": "" },
  { "name": "tt-add-goods", "path": "<span class='path1'></span><span class='path2'></span>" },
  { "name": "tt-bold-text-option", "path": "" },
  { "name": "tt-coverage-level", "path": "<span class='path1'></span><span class='path2'></span><span class='path3'></span><span class='path4'></span>" },
  { "name": "tt-diskette", "path": "<span class='path1'></span><span class='path2'></span><span class='path3'></span>" },
  { "name": "tt-download", "path": "" },
  { "name": "tt-download-button", "path": "" },
  { "name": "tt-drag", "path": "<span class='path1'></span><span class='path2'></span>" },
  { "name": "tt-envelope2", "path": "" },
  { "name": "tt-error", "path": "" },
  { "name": "tt-filter-filled-tool-symbol", "path": "" },
  { "name": "tt-four-expand-arrows", "path": "" },
  { "name": "tt-Group-30", "path": "<span class='path1'></span><span class='path2'></span><span class='path3'></span><span class='path4'></span>" },
  { "name": "tt-Group-149", "path": "<span class='path1'></span><span class='path2'></span>" },
  { "name": "tt-Group-151", "path": "<span class='path1'></span><span class='path2'></span>" },
  { "name": "tt-icon", "path": "" },
  { "name": "tt-information-icon", "path": "<span class='path1'></span><span class='path2'></span><span class='path3'></span>" },
  { "name": "tt-italicize-text", "path": "" },
  { "name": "tt-link-symbol", "path": "" },
  { "name": "tt-list2", "path": "" },
  { "name": "tt-menu", "path": "" },
  { "name": "tt-menu-closed", "path": "" },
  { "name": "tt-menu-halfopen", "path": "<span class='path1'></span><span class='path2'></span><span class='path3'></span>" },
  { "name": "tt-excel", "path": "<span class='path1'></span><span class='path2'></span>" },
  { "name": "tt-more", "path": "" },
  { "name": "tt-newspaper", "path": "<span class='path1'></span><span class='path2'></span>" },
  { "name": "tt-notification", "path": "" },
  { "name": "tt-numbered-list", "path": "" },
  { "name": "tt-package-for-delivery", "path": "<span class='path1'></span><span class='path2'></span><span class='path3'></span><span class='path4'></span>" },
  { "name": "tt-Path-110", "path": "" },
  { "name": "tt-Path-137", "path": "" },
  { "name": "tt-Path-220", "path": "" },
  { "name": "tt-Path-221", "path": "" },
  { "name": "tt-printer", "path": "<span class='path1'></span><span class='path2'></span><span class='path3'></span>" },
  { "name": "tt-right-arrow", "path": "" },
  { "name": "tt-right-quotation-mark", "path": "" },
  { "name": "tt-search", "path": "" },
  { "name": "tt-security-atenttion", "path": "" },
  { "name": "tt-security-atenttion-1", "path": "" },
  { "name": "tt-security-info", "path": "" },
  { "name": "tt-security-info-1", "path": "" },
  { "name": "tt-security-off", "path": "" },
  { "name": "tt-security-off-1", "path": "" },
  { "name": "tt-security-on", "path": "" },
  { "name": "tt-security-on-1", "path": "" },
  { "name": "tt-settings-work-tool", "path": "" },
  { "name": "tt-table-grid", "path": "" },
  { "name": "tt-text-height-filled", "path": "" },
  { "name": "tt-tick", "path": "" },
  { "name": "tt-two-columns-layout", "path": "" },
  { "name": "tt-warehouse-with-boxes", "path": "<span class='path1'></span><span class='path2'></span><span class='path3'></span><span class='path4'></span><span class='path5'></span><span class='path6'></span><span class='path7'></span>" },
  { "name": "tt-bird", "path": "" },
  { "name": "tt-bubble-question", "path": "" },
  { "name": "tt-calendar-empty", "path": "" },
  { "name": "tt-camera-flip", "path": "" },
  { "name": "tt-chat2-01", "path": "" },
  { "name": "tt-chevron-down", "path": "" },
  { "name": "tt-chevron-left", "path": "" },
  { "name": "tt-chevron-right-circle", "path": "" },
  { "name": "tt-chevron-up", "path": "" },
  { "name": "tt-chip", "path": "" },
  { "name": "tt-color-sampler", "path": "" },
  { "name": "tt-credit-card", "path": "" },
  { "name": "tt-cube", "path": "" },
  { "name": "tt-database", "path": "" },
  { "name": "tt-display", "path": "" },
  { "name": "tt-document2", "path": "" },
  { "name": "tt-envelope", "path": "" },
  { "name": "tt-file-zip", "path": "" },
  { "name": "tt-fingerprint-simple-outline", "path": "" },
  { "name": "tt-Group-36", "path": "" },
  { "name": "tt-Group-37", "path": "" },
  { "name": "tt-list", "path": "" },
  { "name": "tt-lock", "path": "" },
  { "name": "tt-map2", "path": "" },
  { "name": "tt-map-marker-user", "path": "" },
  { "name": "tt-Montions", "path": "" },
  { "name": "tt-phone", "path": "" },
  { "name": "tt-phoneq", "path": "" },
  { "name": "tt-pin-person-012", "path": "" },
  { "name": "tt-play", "path": "" },
  { "name": "tt-radio-button", "path": "" },
  { "name": "tt-screen-field", "path": "" },
  { "name": "tt-search-1", "path": "" },
  { "name": "tt-send-schat-01", "path": "" },
  { "name": "tt-share-01", "path": "" },
  { "name": "tt-share3", "path": "" },
  { "name": "tt-shield", "path": "" },
  { "name": "tt-shutter", "path": "" },
  { "name": "tt-support", "path": "" },
  { "name": "tt-user", "path": "" },
  { "name": "tt-arrow", "path": "" }
]