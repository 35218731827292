import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Database, DatabaseResponse } from '../../../core/services/database';
import { AppCommunication } from '../../../core/services/app.communication';
import { LoadingDirectiveInterface } from '../../../shared/directives/loading-directive/loading-directive.directive';
import { ITableColumns, ITableFormatDateFields } from '../../../shared/data-table/data-table.component';
import { UserStateService } from '../../../core/services/userstate.service';

export interface Feedback {
  userFeedbackCode: number;
  userFeedbackTitle: string | null;
  userFeedbackDescription: string | null;
  userFeedbackStatusName: string | null;
  createDateTime: string | null;
  createUserCode: number | null;
  userFeedbackName: string | null;
  userFeedbackEmail: string | null;
}

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.scss']
})
export class FeedbackListComponent implements OnInit {

  loadingStatus: LoadingDirectiveInterface;
  feedbackData: Feedback[];
  feedbackListType: 'all' | 'my';
  // View
  activeFeedbackCode: number;
  viewFeedback: boolean;
  // Table
  feedbackColumns: ITableColumns[];
  formatDateFields: ITableFormatDateFields[];


  constructor(
    private _database: Database,
    private _communication: AppCommunication,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private user: UserStateService
  ) {
    this.loadingStatus = { isLoading: true, text: 'Loading...', show: true, class: 'default' };
    this.feedbackData = [];
    this.feedbackColumns = [
      { field: 'userFeedbackTitle', header: 'Title' },
      { field: 'userFeedbackStatusName', header: 'Status' },
      { field: 'userFeedbackName', header: 'Created by' },
      { field: 'createDateTime', header: 'Created at' }
    ];

    this.activeFeedbackCode = null;
    this.viewFeedback = false;

    this.formatDateFields = [{
      field: 'createDateTime',
      format: 'MMM dd, y - hh:mm'
    }];
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.feedbackListType = params['listType'] || 'my';

      if (this.feedbackListType === 'all') {
        const userData = this.user.getUserData();
        if (userData.userTypeCode === 1) {
          this.loadFeedbackData();
        } else {
          this.router.navigate(['/feedback'], { replaceUrl: true });
          return;
        }
      } else {
        this.loadFeedbackData();
      }

      if (params['userFeedbackCode']) {
        this.activeFeedbackCode = params['userFeedbackCode'];
      }
    });
    this._communication.feedbackService.subscribe((oc: boolean) => {
      if (!oc) {
        this.loadingStatus = { isLoading: true, text: 'Loading...', show: true, class: 'default' };
        this.loadFeedbackData();
      }
    });
  }
  loadFeedbackData(): void {
    this.loadingStatus = { isLoading: true, text: 'Loading...', show: true, class: 'default' };
    this._database.executeRequest(`/api/feedback/${this.feedbackListType}`, 'get', {})
      .then((response: DatabaseResponse & { data: Feedback[] }) => {
        if (response.success) {
          this.feedbackData = response.data;
          this.loadingStatus = { isLoading: false, text: '', show: false, class: 'default' };
          if (this.activeFeedbackCode) {
            this.viewFeedback = true;
          }
        } else {
          this.loadingStatus = { isLoading: false, text: 'Error loading data, please try again later...', show: true, class: 'error' };
        }
      }, (err: DatabaseResponse) => {
        this.loadingStatus = { isLoading: false, text: 'Error loading data, please try again later...', show: true, class: 'error' };
      });
  }
  getFormatDate(date: string | null): string {
    if (date == null) {
      return null;
    }
    const _date = new Date(date);
    return ((_date.getDate() + 1) < 10 ? '0' + (_date.getDate() + 1) : (_date.getDate() + 1)).toString() + '.' +
      ((_date.getMonth() + 1) < 10 ? '0' + (_date.getMonth() + 1) : (_date.getMonth() + 1)).toString() + '.' +
      _date.getFullYear().toString();
  }
  openFeedback(feedback: Feedback): void {
    this.activeFeedbackCode = feedback.userFeedbackCode;
    this.viewFeedback = true;
  }
  onPreviewFeedbackClosed(): void {
    this.viewFeedback = false;
    this.activeFeedbackCode = null;
  }
  getType(): string {
    return `${this.feedbackListType.substring(0, 1).toUpperCase()}${this.feedbackListType.substring(1)}`;
  }
  leaveFeedback() {
    this._communication.feedbackService.next(true);
  }
}
