<div class="navigation-bar-wrapper">

  <nav class="nav" id="nav">

    <ul class="nav-list list-1">
      <li class="show-sm nav-options">
        <a routerLink="/">
          <p>
            <span class="menuItem-name">RiskDynamyx</span>
          </p>
          <span data-ignore-close (click)="openSettings('settings')" class="menuItem-arrow icon tt-settings-work-tool"></span>
        </a>
        <div class="search-box">
          <span class="icon tt-search"></span>
          <input type="text" placeholder="Search" />
        </div>
      </li>

      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngFor="let item of menuItems; let i = index;"
        [class.expanded]="!item.route && item.collapse">
        <a [routerLink]="item.route" [queryParams]="item.queryParams" *ngIf="item.route; else withoutRoute" (click)="expendHandler(item.code, null); $event.preventDefault();"
          #itemEl (mouseenter)="mouseEnter($event, itemEl)" (mouseleave)="mouseLeave(itemEl)">
          <p>
            <span class="menuItem-icon" [appIcon]="item.icon"></span>
            <span class="menuItem-name">{{item.name}}</span>
          </p>
        </a>

        <ng-template #withoutRoute>
          <a href="#" (click)="expendHandler(item.code, null); $event.preventDefault();" #itemEl (mouseenter)="mouseEnter($event, itemEl)"
            (mouseleave)="mouseLeave(itemEl)">
            <!--item.collapse = !item.collapse; $event.preventDefault();">-->
            <p>
              <span class="menuItem-icon" [appIcon]="item.icon"></span>
              <span class="menuItem-name">{{item.name}}</span>
            </p>
            <span class="menuItem-arrow icon tt-Path-221"></span>
          </a>
        </ng-template>


        <ul class="nav-list list-2" *ngIf="item.children.length" [@dropDownInOut]="item.collapse?'in':'out'">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngFor="let child of menuItems[i].children; let j = index"
            [class.expanded]="!child.route && child.collapse">
            <a [routerLink]="child.route" [queryParams]="child.queryParams" *ngIf="child.route; else withoutRouteL2"
              (click)="expendHandler(null, child.code); $event.preventDefault();" #childEl (mouseenter)="mouseEnter($event, childEl)"
              (mouseleave)="mouseLeave(childEl)">
              <p>
                <span class="menuItem-icon" [appIcon]="child.icon"></span>
                <span class="menuItem-name">{{child.name}}</span>
              </p>
            </a>

            <ng-template #withoutRouteL2>
              <a href="#" (click)="expendHandler(null, child.code); $event.preventDefault();" #childEl (mouseenter)="mouseEnter($event, childEl)"
                (mouseleave)="mouseLeave(childEl)">
                <!--child.collapse = !child.collapse; $event.preventDefault();">-->
                <p>
                  <span class="menuItem-icon" [appIcon]="child.icon"></span>
                  <span class="menuItem-name">{{child.name}}</span>
                </p>
                <span class="menuItem-arrow icon tt-Path-221"></span>
              </a>
            </ng-template>

            <ul class="nav-list list-3" *ngIf="child.children.length" [@dropDownInOut]="child.collapse?'in':'out'">
              <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngFor="let gChild of menuItems[i].children[j].children"
                [class.expanded]="gChild.collapse">
                <a [routerLink]="gChild.route" [queryParams]="gChild.queryParams" [@dropDownInOut]="child.collapse?'in':'out'"
                  #gChildEl (mouseenter)="mouseEnter($event, gChildEl)" (mouseleave)="mouseLeave(gChildEl)">
                  <p>
                    <!-- <span class="menuItem-icon" [appIcon]="gChild.icon"></span> -->
                    <span class="menuItem-name">{{gChild.name}}</span>
                  </p>
                </a>
              </li>
            </ul>
            <!-- Level 3 -->
          </li>
        </ul>
        <!-- Level 2 -->
      </li>

    </ul>
    <!-- Level 1 -->
  </nav>


  <div class="company-logo">
    <label class="levels-title">NATIONAL TERRORISM ALERT LEVEL</label>
    <div class="levels-block">
      <div class="levels-rainbow"></div>
      <button class="btn btn-primary" [class.active]="activeTerrorismLevel == 1">Certain</button>
      <button class="btn btn-primary" [class.active]="activeTerrorismLevel == 2">Expected</button>
      <button class="btn btn-primary" [class.active]="activeTerrorismLevel == 3">Probable</button>
      <button class="btn btn-primary" [class.active]="activeTerrorismLevel == 4">Possible</button>
      <button class="btn btn-primary" [class.active]="activeTerrorismLevel == 5">Not Expected</button>
    </div>
    <div class="cl-mini"></div>
    <div class="cl-normal"></div>
  </div>

</div>