<div *ngIf="loadingApp; else loading_done" class="main-loader">
  <app-loader></app-loader>
</div>

<ng-template #loading_done>
  <router-outlet></router-outlet>
  <app-feedback-add *ngIf="addFeedbackMode"></app-feedback-add>
</ng-template>

<app-notification></app-notification>
<!-- END: ALERTS -->