import { Injectable } from '@angular/core';
import { APP_API_HOST } from '../../../environments/environment';

declare const google: any;
@Injectable()
export class SharedService {


    constructor() {

    }

    getGatherRow(gather: any[], searchKey: string, searchValue: string | number | boolean): any {
        if (!gather) {
            return null;
        }
        const _row = gather.filter((value) => {
            return value[searchKey] === searchValue;
        });
        return _row[0] ? _row[0] : null;
    }
    getDataIndex(data: any[], searchKey: string, searchValue: string | number | boolean): number {
        const index: number = data.findIndex((value) => {
            return value[searchKey] === searchValue;
        });
        return index;
    }
    createGoogleMapsPin(color): { url: string; anchor: any; scaledSize: any } {
        color = color === undefined || color === null || color === '' ? '#fffff' : color;
        return {
            url: `${APP_API_HOST}/api/pin/fill/${color.replace(/#/g, '')}`,
            anchor: new google.maps.Point(16, 45),
            scaledSize: new google.maps.Size(27, 45)
        };
    }
    generateShowing(event: any, tableOptions: any, type: 'page' | 'filter' | 'limit' | 'init' | 'insert' | 'delete'): any {
        if (type === 'page') {
            tableOptions.showing.start = event.first + 1;
            tableOptions.showing.end = event.first + event.rows <= tableOptions.showing.total ? event.first + event.rows : tableOptions.showing.total;
        } else if (type === 'filter') {
            tableOptions.showing = { start: 1, end: event.filteredValue.length < tableOptions.showingPerPage.limit ? event.filteredValue.length : tableOptions.showingPerPage.limit, total: event.filteredValue.length };
        } else if (type === 'limit') {
            tableOptions.showing.end = tableOptions.showing.start + event.value.limit - 1 <= tableOptions.showing.total ?
                tableOptions.showing.start + event.value.limit - 1 : tableOptions.showing.total;
            tableOptions.showingPerPage.limit = event.value.limit;
        } else if (type === 'init') {
            tableOptions.showing = {
                start: 0,
                end: (event.total <= tableOptions.showingPerPage.limit ? event.total : tableOptions.showingPerPage.limit),
                total: event.total
            };
        } else if (type === 'insert') {
            tableOptions.showing.total = tableOptions.showing.total + 1;
        } else if (type === 'delete') {
            tableOptions.showing.total = tableOptions.showing.total - 1;
        }

        return tableOptions;
    }
    getHashValues(url) {
        return new Promise((resolve) => {
            if (url.toString().split('#')[1].split('&')[1]) {
                const data = {};
                const hashParams = window.location.toString().split('#')[1].split('&');
                hashParams.forEach((param: string) => {
                    const vals = param.split('=');
                    if (vals[0] && vals[1]) {
                        data[vals[0]] = vals[1];
                    }
                });
                resolve(data);
            }
        });
    }
}
