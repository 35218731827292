import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CodesRaw, CodesParsed, CodesTableHeader } from '../interfaces/codes.types';


@Injectable()
export class CodesReactiveParserService {

    constructor(
        private fb: FormBuilder
    ) {
    }

    public prepareRawData(rawData: CodesRaw[]): Promise<CodesParsed[]> {
        return new Promise((resolve) => {
            const _rawData: any = Object.assign([], rawData);
            for (const rawRow of _rawData) {
                let isVisible = true; // If input is visible;
                let isInsertParam = true; // If input value is being sent in insert;
                let isUpdateParam = true; // If input value is being sent in update;
                let isDeleteParam = false; // If insert value is being sent in delete;
                let readOnlyOnInsert = false; // If it is readonly/disabled on insert;
                let readOnlyOnUpdate = false; // If it is readonly/disabled on update;

                if (rawRow.colName === 'institutionCode' || rawRow.colAI > 0 || (rawRow.pk > 0 && rawRow.colSelect === 0 && rawRow.colType === 'int') || ((rawRow.colLS !== 0 && rawRow.colLS !== -1) || rawRow.colLS === -1)
                ) { isVisible = false; readOnlyOnInsert = true; readOnlyOnUpdate = true; }
                if (rawRow.colName === 'institutionCode' || rawRow.colAI > 0 || rawRow.colLS === -1) { isInsertParam = false; }
                if (rawRow.colName === 'institutionCode' || rawRow.colLS === -1) { isUpdateParam = false; }
                if (rawRow.colAI > 0 || rawRow.pk > 0) { isDeleteParam = true; }

                let inputType: 'text' | 'select' | 'autocomplete' | 'color' | 'textarea' | 'texteditor' | 'number' | 'date' | 'checkbox' = 'text';
                const inputAttributes = {};

                if (['nvarchar', 'char', 'varchar', 'ntext'].includes(rawRow.colType) && rawRow.maxLen <= 2000) { inputType = 'textarea'; }
                if (['nvarchar', 'char', 'varchar', 'ntext'].includes(rawRow.colType) && rawRow.maxLen <= 200) { inputType = 'text'; }
                if (['nvarchar', 'char', 'varchar', 'ntext'].includes(rawRow.colType) && rawRow.maxLen > 2000 || rawRow.maxLen === -1) { inputType = 'texteditor'; }
                if (rawRow.colType === 'int') { inputType = 'number'; inputAttributes['step'] = '1'; }
                if (rawRow.colType === 'decimal') { inputType = 'number'; inputAttributes['step'] = '0.01'; } // TODO: step should be based on the precision of the number
                if (rawRow.colType === 'date') { inputType = 'date'; inputAttributes['timeOnly'] = false; inputAttributes['showTime'] = false; }
                if (rawRow.colType === 'datetime') { inputType = 'date'; inputAttributes['timeOnly'] = false; inputAttributes['showTime'] = true; }
                if (rawRow.colType === 'time') { inputType = 'date'; inputAttributes['timeOnly'] = true; }
                if (rawRow.colType === 'bit') { inputType = 'checkbox'; }
                if (rawRow.colSelect[0] && rawRow.colSelect[0].codesSelectTypeCode === 0) { inputType = 'select'; }
                if (rawRow.colSelect[0] && rawRow.colSelect[0].codesSelectTypeCode === 1) { inputType = 'autocomplete'; }
                if (rawRow.colSelect[0] && rawRow.colSelect[0].codesSelectTypeCode === 2) { inputType = 'color'; }

                const required = rawRow.colNull === 'NO' && isVisible; // If it can be null
                rawRow['isVisible'] = isVisible;
                rawRow['isInsertParam'] = isInsertParam;
                rawRow['isUpdateParam'] = isUpdateParam;
                rawRow['isDeleteParam'] = isDeleteParam;
                rawRow['readOnlyOnInsert'] = readOnlyOnInsert;
                rawRow['readOnlyOnUpdate'] = readOnlyOnUpdate;
                rawRow['required'] = required;
                rawRow['inputType'] = inputType;
                rawRow['inputAttributes'] = inputAttributes;
                rawRow['label'] = (rawRow.columnDisplayName || rawRow.colName) + (rawRow.required ? '*' : '');
            }
            resolve(_rawData);
        });
    }
    public generateFormGroupControls(data: CodesParsed[]): Promise<any> {
        return new Promise((resolve) => {
            const formGroup = {};
            data.forEach((row: CodesParsed) => {
                if (row.colLS !== -1) { formGroup[row.colName] = this.fb.control(row.colType === 'bit' ? true : '', this.getValidators(row)); }
            });
            resolve(formGroup);
        });
    }
    public generateCodesTableHeader(data: CodesRaw[]): Promise<CodesTableHeader[]> {
        return new Promise((resolve) => {
            const tableCols: CodesTableHeader[] = [];
            data.forEach((value: CodesRaw, index: number) => {
                const position = value.columnDisplayOrderNumber - 1;
                if (value.colLS !== -1 && value.columnShow && value.colName !== 'institutionCode') {
                    let field: string = value.colName;
                    const colSelect = value.colSelect[0];
                    if (colSelect) {
                        field = colSelect.columnDisplayName || colSelect.valueSystemName || value.colName;
                    }
                    tableCols.splice(position, 0, {
                        'field': field,
                        'header': value.columnDisplayName,
                        'codesIndex': index
                    });
                }
            });
            resolve(tableCols);
        });
    }
    public prepareTableValue(value: any, data: CodesParsed): any {
        if (['date', 'time', 'datetime'].includes(data.inputType)) {
            const date = new Date(value);
            if (Object.prototype.toString.call(date) === '[object Date]') {
                if (isNaN(date.getTime())) {
                    // date is not valid
                    return '';
                } else {
                    // date is valid
                    const _ddata = this.getDateDetails(date);
                    if (data.inputType === 'date') {
                        return _ddata.day + '.' + _ddata.month + '.' + _ddata.year;
                    } else if (data.inputType === 'time') {
                        return _ddata.hours + ':' + _ddata.minutes;
                    } else if (data.inputType === 'datetime') {
                        return _ddata.day + '.' + _ddata.month + '.' + _ddata.year + ' ' + _ddata.hours + ':' + _ddata.minutes;
                    }
                    return date;
                }
            } else {
                return '';
            }
        } else {
            return value;
        }
    }
    public getGatherRowValue(data: any, codesItem: CodesParsed): { code: any, name: any } {
        const codeKey = codesItem.colSelect[0].keySystemName;
        const valueKey = codesItem.colSelect[0].valueSystemName;
        const row = { code: null, name: null };
        row['code'] = data[codeKey];
        row['name'] = data[valueKey];
        return row.code ? row : null;
    }
    public generatePOSTData(form: FormGroup, codes: CodesParsed[], type: 'insert' | 'update' | 'delete'): any {
        const postData = {};
        codes.forEach((codesItem, index) => {
            if (codesItem.colLS !== -1 && (
                (codesItem.isInsertParam && type === 'insert') ||
                (codesItem.isUpdateParam && type === 'update') ||
                (codesItem.isDeleteParam && type === 'delete')
            )) {
                if (codesItem.colSelect[0] && [0, 1].includes(codesItem.colSelect[0].codesSelectTypeCode)) {
                    postData[codesItem.colName] = form.get(codesItem.colName).value.code || null;
                } else {
                    postData[codesItem.colName] = form.get(codesItem.colName).value;
                }
            }
        });
        return postData;
    }
    public generatePOSTDataFromRaw(data: any, codes: CodesParsed[], type: 'insert' | 'update' | 'delete'): any {
        const postData = {};
        codes.forEach((codesItem, index) => {
            if (codesItem.colLS !== -1 && (
                (codesItem.isInsertParam && type === 'insert') ||
                (codesItem.isUpdateParam && type === 'update') ||
                (codesItem.isDeleteParam && type === 'delete')
            )) {
                if (codesItem.colSelect[0] && [0, 1].includes(codesItem.colSelect[0].codesSelectTypeCode)) {
                    postData[codesItem.colName] = data[codesItem.colName].code || null;
                } else {
                    postData[codesItem.colName] = data[codesItem.colName];
                }
            }
        });
        return postData;
    }
    private getValidators(data: CodesParsed): any[] {
        const validators = [];
        if (data.required) { validators.push(Validators.required); }
        if (data.maxLen > 0 && ['nvarchar', 'char', 'varchar', 'ntext'].includes(data.colType)) { validators.push(Validators.maxLength(data.maxLen)); }
        if (data.maxLen > 0 && ['int', 'decimal'].includes(data.colType)) { validators.push(Validators.max(data.maxLen)); }
        return validators;
    }
    private getDateDetails(date): any {
        const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        const month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
        const year = date.getFullYear();
        const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        return { day: day, month: month, year: year, hours: hours, minutes: minutes };
    }

}
