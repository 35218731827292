<p-dialog [header]="'Display settings'" [draggable]="false" [(visible)]="show" modal="modal" width="450" [responsive]="true"
  [appendTo]="'body'" (onHide)="emmitClose()">

  <div class="option" *ngFor="let codesItem of columnDisplaySettingsData;let i = index; let isFirst = first; let isLast = last;">
    <div class="reorder">
      <i class="fa fa-chevron-up" (click)="reorderDown(i)" [class.disabled]="isFirst"></i>
      <span>{{i+1}}.</span>
      <i class="fa fa-chevron-down" (click)="reorderUp(i)" [class.disabled]="isLast"></i>
    </div>
    <div class="option_content">
      <div class="option_header">
        {{codesItem.colName}}
      </div>
      <div class="option_body">
        <div class="display_name">
          <label>Display name:</label>
          <input type="text" [(ngModel)]="codesItem.columnDisplayName" pInputText/>
        </div>

        <div class="display_show">
          <label>Show in table:</label>
          <p-checkbox class="box-rounded" binary="true" [(ngModel)]="codesItem.columnShow"></p-checkbox>
        </div>
      </div>
    </div>

  </div>

  <p-footer>
    <button class="btn btn-default" (click)="emmitClose()">Close</button>
    <button class="btn btn-default" (click)="saveDisplaySettings()">Save </button>
  </p-footer>
</p-dialog>