import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CodesParsed } from '../../interfaces/codes.types';
import { Database, DatabaseResponse } from '../../../../core/services/database';
import { CodesReactiveService } from '../../services/codes-reactive.service';

@Component({
  selector: 'app-form-control-autocomplete',
  templateUrl: './form-control-autocomplete.component.html',
  styleUrls: ['./form-control-autocomplete.component.scss']
})
export class FormControlAutocompleteComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() inputData: CodesParsed;

  suggestionsData: any[];
  constructor(
    private _database: Database,
    private codesService: CodesReactiveService
  ) {
    this.suggestionsData = [];
  }

  ngOnInit() {
    this.inputData['suggestionsData'] = this.suggestionsData;
  }

  runAutocomplete(event) {
    const params = {};
    // Load params
    for (const param of this.inputData.colSelect[0].procedureGatherParameters) {
      if (param.columnNameParameter === '$THIS_VALUE') {
        params[param.orderNumber] = this.formGroup.get(this.inputData.colName).value;
      } else {
        params[param.orderNumber] = this.formGroup.get(param.columnNameParameter).value || null;
      }
    }

    const key = this.inputData.colSelect[0].keySystemName;
    const value = this.inputData.colSelect[0].valueSystemName;
    const gather = this.inputData.colSelect[0].procedureGather;

    this.codesService.getCodesGather(gather, params, key, value).then((res) => {
      this.suggestionsData = res;
      this.inputData['suggestionsData'] = this.suggestionsData;
    });
  }

}
