<p-growl [(value)]="msgs" [life]="3000"></p-growl>

<div class="login-form" *ngIf="loginStep == 'login'">
  <div class="form-header">
    <!-- <h1 class="name">{{appName}}</h1> -->
    <div class="company-logo"></div>
  </div>

  <div class="form-body">
    <div class="nav-links">
      <a routerLinkActive="active" routerLink="/login">Login</a>
    </div>

    <div class="main">
      <div class="ui-control">
        <span class="ui-float-label">
          <input pInputText [formControl]="userEmail" type="email" (keyup.enter)="loginHangler()" />
          <label>Email</label>
        </span>
        <span class="ui-error" *ngIf="formSubmited && userEmail.hasError('pattern') && !userEmail.hasError('required')">
          Please enter a valid email address
        </span>
        <span
          class="ui-error"
          *ngIf="formSubmited && !userEmail.hasError('pattern') && !userEmail.hasError('required') && loginError">
          {{ loginError }}
        </span>
        <span class="ui-error" *ngIf="formSubmited && userEmail.hasError('required')">
          Email is
          <strong>required</strong>
        </span>
      </div>

      <div class="ui-control">
        <span class="ui-float-label">
          <input pInputText [formControl]="userPassword" type="{{ passwordType }}" (keyup.enter)="loginHangler()" />
          <label>Password</label>
        </span>
        <span
          class="ui-error"
          *ngIf="formSubmited && userPassword.hasError('minlength') && !userPassword.hasError('required')">
          Password must be at least 6 characters
        </span>
        <span class="ui-error" *ngIf="formSubmited && userPassword.hasError('required')">
          Password is
          <strong>required</strong>
        </span>
        <a
          (click)="togglePasswordShow(); $event.stopPropagation()"
          class="password-show"
          [class.active]="passwordShow"></a>
      </div>

      <div class="r-f-block">
        <div>
          <p-checkbox
            class="box-rounded"
            label="Remember me"
            name="userRemember"
            [value]="userRemember"
            [(ngModel)]="userRemember"
            (keyup.enter)="loginHangler()"></p-checkbox>
          <!-- <label> Remember me</label> -->
        </div>
        <a class="forgot" routerLink="/forgotPassword">Forgot password?</a>
      </div>
    </div>
  </div>
  <div class="form-footer">
    <button class="btn btn-default" (click)="loginHangler()">Login</button>
  </div>
</div>

<div class="login-form" *ngIf="loginStep == 'sInstitution'">
  <div class="form-header">
    <h1 class="name">{{ appName }}</h1>
    <div class="company-logo"></div>
  </div>

  <div class="form-body">
    <div class="nav-links">
      <a routerLinkActive="active" routerLink="/login">Login</a>
    </div>

    <div class="main">
      <label class="section-description">Select institution:</label>

      <div class="itemList">
        <div
          class="item"
          ripple
          *ngFor="let institution of userLoginData; let i = index"
          (click)="selectInstitution(i, false)"
          [class.active]="i == userSelectInstitutionIndex">
          <div class="item-image">
            <div class="item-image-container">
              <i class="fa fa-institution"></i>
            </div>
          </div>
          <div class="item-content">
            <div class="item-main">{{ institution.institutionName }}</div>
            <div class="item-description">
              <ng-container *ngFor="let role of institution.roles; let isLast = last"
                >{{ role.userTypeName }}
                <font *ngIf="!isLast">,</font>
              </ng-container>
            </div>
          </div>
          <div class="item-icon">
            <i class="fa fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-footer">
    <button class="btn btn-default left" (click)="backForInstitution()">Back</button>

    <button class="btn btn-default" (click)="nextForInstitution()" [disabled]="userSelectInstitutionIndex == null">
      Next
    </button>
  </div>
</div>

<div class="login-form" *ngIf="loginStep == 'sInstitutionRole'">
  <div class="form-header">
    <h1 class="name">{{ appName }}</h1>
    <div class="company-logo"></div>
  </div>

  <div class="form-body">
    <div class="nav-links">
      <a routerLinkActive="active" routerLink="/login">Login</a>
    </div>

    <div class="main">
      <div class="institutionDescription" *ngIf="userSelectedInstitutionCode !== null">
        <div class="item-image-container">
          <i class="fa fa-institution"></i>
        </div>
        <div class="description">
          {{ userLoginData[userSelectInstitutionIndex].institutionName }}
        </div>
      </div>
      <label class="section-description">Select institution role:</label>
      <div class="itemList">
        <div
          class="item"
          ripple
          *ngFor="let role of userLoginData[userSelectInstitutionIndex].roles; let i = index"
          (click)="selectInstitutionRole(i, false)"
          [class.active]="i == userSelectInstitutionRoleIndex">
          <div class="item-image">
            <div class="item-image-container">
              <i class="fa fa-user"></i>
            </div>
          </div>
          <div class="item-content" style="flex-basis: 100%">
            <div class="item-main">{{ role.userTypeName }}</div>
            <div class="item-description">
              <ng-container *ngFor="let group of role.userTypeGroups; let isLast = last"
                >{{ group.userGroupValueName }}
                <font *ngIf="!isLast">,</font>
              </ng-container>
            </div>
          </div>
          <div class="item-icon">
            <i class="fa fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-footer">
    <button class="btn btn-default left" (click)="backForInstitutionRole()">Back</button>

    <button
      class="btn btn-default"
      (click)="nextForInstitutionRole()"
      [disabled]="userSelectInstitutionRoleIndex == null">
      Next
    </button>
  </div>
</div>

<div class="login-form" *ngIf="loginStep == 'sInstitutionRoleGroup'">
  <div class="form-header">
    <h1 class="name">{{ appName }}</h1>
    <div class="company-logo"></div>
  </div>

  <div class="form-body">
    <div class="nav-links">
      <a routerLinkActive="active" routerLink="/login">Login</a>
    </div>

    <div class="main">
      <div class="institutionDescription" *ngIf="userSelectedInstitutionCode !== null">
        <div class="item-image-container">
          <i class="fa fa-institution"></i>
        </div>
        <div class="description">
          {{
            userLoginData[userSelectInstitutionIndex].institutionName +
              ', ' +
              userLoginData[userSelectInstitutionIndex].roles[userSelectInstitutionRoleIndex].userTypeName
          }}
        </div>
      </div>
      <label class="section-description">Select institution role group:</label>
      <div class="itemList">
        <div
          class="item"
          ripple
          *ngFor="
            let type of userLoginData[userSelectInstitutionIndex].roles[userSelectInstitutionRoleIndex].userTypeGroups;
            let i = index
          "
          (click)="selectInstitutionRoleGroup(i, false)"
          [class.active]="i == userSelectInstitutionRoleGroupIndex">
          <div class="item-image">
            <div class="item-image-container">
              <i class="fa fa-briefcase"></i>
            </div>
          </div>
          <div class="item-content" style="flex-basis: 100%">
            <div class="item-main">{{ type.userGroupValueName }}</div>
          </div>
          <div class="item-icon">
            <i class="fa fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-footer">
    <button class="btn btn-default left" (click)="backForInstitutionRoleGroup()">Back</button>

    <button
      class="btn btn-default"
      (click)="nextForInstitutionRoleGroup()"
      [disabled]="userSelectInstitutionRoleGroupIndex == null">
      Next
    </button>
  </div>
</div>
