<div class="app-subheader-wrapper">
  <app-subheader [title]="getType() + ' Feedback'" [back]="true"></app-subheader>
</div>



<div class="content">
  <div class="content-inner hide-scroll" no-padding>
    <app-datatable [title]="getType() + ' Feedback'" [columns]="feedbackColumns" [data]="feedbackData" [limit]="20" [options]="'pfrein'"
      [lines]="'single'" [formatDateFields]="formatDateFields" [loading]="loadingStatus" (rowSelect)="openFeedback($event.data)">
      <button class="btn btn-default" (click)="leaveFeedback()">Leave feedback</button>
    </app-datatable>
  </div>
</div>


<app-feedback-view *ngIf="viewFeedback" (closed)="onPreviewFeedbackClosed($event)" [feedbackCode]="activeFeedbackCode"></app-feedback-view>