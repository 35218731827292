/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./aside.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/directives/ripple/ripple.directive";
import * as i3 from "./aside-notification/aside-notification.component.ngfactory";
import * as i4 from "./aside-notification/aside-notification.component";
import * as i5 from "./aside-notification/notification-loading.service";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "./aside.component";
import * as i9 from "../services/app.communication";
import * as i10 from "./aside-state.service";
import * as i11 from "../services/storage-service";
import * as i12 from "../services/userstate.service";
var styles_AsideComponent = [i0.styles];
var RenderType_AsideComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AsideComponent, data: { "animation": [{ type: 7, name: "slideInOut", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { transform: "scaleX(1)" }, offset: null }, options: undefined }, { type: 0, name: "out", styles: { type: 6, styles: { transform: "scaleX(0)" }, offset: null }, options: undefined }, { type: 1, expr: "out <=> in", animation: { type: 4, styles: null, timings: "100ms ease-in-out" }, options: null }], options: {} }] } });
export { RenderType_AsideComponent as RenderType_AsideComponent };
function View_AsideComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "user-aside"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "ua-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "ua-avatar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "./assets/img/user_placeholder.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "ua-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ", ", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn-default"], ["style", "width:100%;margin-bottom: 1em;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.feedback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 81920, null, 0, i2.RippleDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, ["Leave feedback"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "btn btn-default"], ["style", "width:100%;margin:0 !important"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logoutHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 81920, null, 0, i2.RippleDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, ["Sign out"]))], function (_ck, _v) { _ck(_v, 13, 0); _ck(_v, 16, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.institutionName; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.userFullName; var currVal_2 = _co.userTypeName; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = _co.userEmail; _ck(_v, 10, 0, currVal_3); }); }
function View_AsideComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-aside-notification", [], null, null, null, i3.View_AsideNotificationComponent_0, i3.RenderType_AsideNotificationComponent)), i1.ɵdid(1, 245760, null, 0, i4.AsideNotificationComponent, [i5.NotificationLoadingService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AsideComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_AsideComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_AsideComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_AsideComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "settings-aside"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "sa-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchSettingsTab("tab-general") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["General"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchSettingsTab("tab-account") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Account"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchSettingsTab("tab-notification") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Notifications"])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "sa-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsideComponent_5)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsideComponent_6)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsideComponent_7)), i1.ɵdid(14, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.activeSettingsTab == "tab-general"); _ck(_v, 10, 0, currVal_3); var currVal_4 = (_co.activeSettingsTab == "tab-account"); _ck(_v, 12, 0, currVal_4); var currVal_5 = (_co.activeSettingsTab == "tab-notification"); _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activeSettingsTab == "tab-general"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.activeSettingsTab == "tab-account"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.activeSettingsTab == "tab-notification"); _ck(_v, 6, 0, currVal_2); }); }
function View_AsideComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "aside-wrapper"]], [[24, "@slideInOut", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsideComponent_2)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsideComponent_3)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsideComponent_4)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.selectedItem === "user"); _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.selectedItem === "notifications"); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.selectedItem === "settings"); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animateState; _ck(_v, 0, 0, currVal_0); }); }
export function View_AsideComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsideComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.asideIsOpened; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AsideComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-aside", [], null, null, null, View_AsideComponent_0, RenderType_AsideComponent)), i1.ɵdid(1, 245760, null, 0, i8.AsideComponent, [i6.Router, i9.AppCommunication, i10.AsideStateService, i11.StorageService, i12.UserStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AsideComponentNgFactory = i1.ɵccf("app-aside", i8.AsideComponent, View_AsideComponent_Host_0, {}, { activeItem: "activeItem" }, []);
export { AsideComponentNgFactory as AsideComponentNgFactory };
