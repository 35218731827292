/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./c-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./c-header.component";
var styles_CHeaderComponent = [i0.styles];
var RenderType_CHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CHeaderComponent, data: {} });
export { RenderType_CHeaderComponent as RenderType_CHeaderComponent };
function View_CHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_CHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "c-header-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "c-header-title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CHeaderComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "c-header-content"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "c-header-options"]], null, null, null, null, null)), i1.ɵncd(null, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "c-header", [], null, null, null, View_CHeaderComponent_0, RenderType_CHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.CHeaderComponent, [], null, null)], null, null); }
var CHeaderComponentNgFactory = i1.ɵccf("c-header", i3.CHeaderComponent, View_CHeaderComponent_Host_0, { title: "title" }, {}, ["[left]", "[center]", "[right]"]);
export { CHeaderComponentNgFactory as CHeaderComponentNgFactory };
