/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/directives/icons/icons.directive";
import * as i3 from "../../shared/directives/ripple/ripple.directive";
import * as i4 from "@angular/common";
import * as i5 from "../aside/aside.component.ngfactory";
import * as i6 from "../aside/aside.component";
import * as i7 from "@angular/router";
import * as i8 from "../services/app.communication";
import * as i9 from "../aside/aside-state.service";
import * as i10 from "../services/storage-service";
import * as i11 from "../services/userstate.service";
import * as i12 from "./header.component";
import * as i13 from "../services/app.global";
import * as i14 from "../aside/aside-notification/notification-loading.service";
import * as i15 from "../navbar/navbar-state.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: { "animation": [{ type: 7, name: "searchInputGrow", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { width: "150px" }, offset: null }, options: undefined }, { type: 0, name: "out", styles: { type: 6, styles: { width: "50px" }, offset: null }, options: undefined }, { type: 1, expr: "in <=> out", animation: { type: 4, styles: null, timings: "150ms ease-in-out" }, options: null }], options: {} }] } });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "notification-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notificationService.newNotificationCount; _ck(_v, 1, 0, currVal_0); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "header-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "company"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "menu-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuToggleHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 81920, null, 0, i2.IconsDirective, [i1.ElementRef, i1.Renderer], { appIcon: [0, "appIcon"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "company-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toRoot() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["RiskDynamyx"])), (_l()(), i1.ɵeld(6, 0, null, null, 14, "div", [["class", "header-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "header-options"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "btn-header-icon hidden-sm"], ["data-ignore-close", ""]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAside("settings") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 81920, null, 0, i3.RippleDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [["class", "icon tt-settings-work-tool"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["class", "btn-header-icon"], ["data-ignore-close", ""]], [[2, "active", null], [2, "has-notification", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAside("notifications") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 81920, null, 0, i3.RippleDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 0, "span", [["class", "icon tt-notification"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "user-avatar"], ["data-ignore-close", ""], ["ripple", ""]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAside("user") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 81920, null, 0, i3.RippleDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 0, "img", [["src", "./assets/img/user_placeholder.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-aside", [], null, [[null, "activeItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeItem" === en)) {
        var pd_0 = (_co.activateItem($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_AsideComponent_0, i5.RenderType_AsideComponent)), i1.ɵdid(20, 245760, null, 0, i6.AsideComponent, [i7.Router, i8.AppCommunication, i9.AsideStateService, i10.StorageService, i11.UserStateService], null, { activeItem: "activeItem" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tt-menu"; _ck(_v, 3, 0, currVal_0); _ck(_v, 9, 0); _ck(_v, 12, 0); var currVal_4 = (_co.notificationService.newNotificationCount > 0); _ck(_v, 15, 0, currVal_4); _ck(_v, 17, 0); _ck(_v, 20, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.activeItem === "settings"); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.activeItem === "notifications"); var currVal_3 = (_co.notificationService.newNotificationCount > 0); _ck(_v, 11, 0, currVal_2, currVal_3); var currVal_5 = (_co.activeItem === "user"); _ck(_v, 16, 0, currVal_5); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i12.HeaderComponent, [i7.Router, i13.AppGlobal, i14.NotificationLoadingService, i15.NavbarStateService, i9.AsideStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i12.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
