<div class="header-wrapper">
  <div class="company">
    <span [appIcon]="'tt-menu'" class="menu-icon" (click)="menuToggleHandler()"></span>
    <h2 (click)="toRoot()" class="company-name">RiskDynamyx</h2>
  </div>

  <div class="header-details">
    <!--
    <div class="search-box hidden-sm" (click)="searchInput.focus()">
      <span class="icon tt-search"></span>
      <input [@searchInputGrow]="searchInputGrowState" (focus)="searchInputOnFocus()" (blur)="searchInputOnBlur()" [(ngModel)]="searchInputValue"
        #searchInput type="text" placeholder="Search" />
    </div>
    -->

    <div class="header-options">
      <!--
      <button
        routerLink="/messages"
        routerLinkActive="active-message"
        class="btn-header-icon"
        [class.has-notification]="messages">
        <span class="icon tt-envelope2" style="font-size: 13px"></span>
        <span class="notification-count" *ngIf="messages">{{ messages }}</span>
      </button>
    -->

      <button
        class="btn-header-icon hidden-sm"
        data-ignore-close
        [class.active]="activeItem === 'settings'"
        (click)="openAside('settings')">
        <span class="icon tt-settings-work-tool"></span>
      </button>

      <button
        class="btn-header-icon"
        data-ignore-close
        [class.active]="activeItem === 'notifications'"
        [class.has-notification]="notificationService.newNotificationCount > 0"
        (click)="openAside('notifications')">
        <span class="icon tt-notification"></span>
        <span class="notification-count" *ngIf="notificationService.newNotificationCount > 0">{{
          notificationService.newNotificationCount
        }}</span>
      </button>

      <div
        (click)="openAside('user')"
        ripple
        data-ignore-close
        class="user-avatar"
        [class.active]="activeItem === 'user'">
        <img src="./assets/img/user_placeholder.png" />
      </div>
    </div>

    <app-aside (activeItem)="activateItem($event)"></app-aside>
  </div>
</div>
