import { NgModule } from '@angular/core';

import { CodesComponent } from './codes.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [CodesComponent],
  exports: [],
  providers: []
})
export class CodesModule { }
