import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class AppCommunication {
  navbarState: boolean;
  constructor() {
    if (window.screen.width < 992) {
      this.navbarState = false;
    } else {
      this.navbarState = true;
    }
    // console.log('communication', this.navbarState, window.screen.width);
  }

  feedbackService = new Subject<boolean>(); // open feedback
  asideService = new Subject<any>(); // open aside and get active header item (mini arrow indicator)
  filterService = new Subject<any>(); // open filter
  logoutUser = new Subject<any>();
  themeService = new Subject<any>();

  // NAVBAR STATE SERVICE
  navbarStateService = new Subject<any>();
  navbarStateHandler() {
    this.navbarState = !this.navbarState;
    this.navbarStateService.next({ state: this.navbarState });
  }
  getNavbarState(): Observable<any> {
    return this.navbarStateService.asObservable();
  }
}
