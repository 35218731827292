import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { AppGlobal } from '../services/app.global';
import { MenuItemsService } from '../../app.menu-items';
import { AsideStateService } from '../aside/aside-state.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('dropDownInOut', [
      state('in', style({
        overflow: 'visible',
        maxHeight: '100vh',
      })),
      state('out', style({
        overflow: 'hidden',
        maxHeight: '0px',
      })),
      transition('in => out', animate('300ms ease-out')),
      transition('out => in', animate('300ms ease-in'))
    ])
  ]
})
export class NavbarComponent implements OnInit {
  menuItems: any = [];
  activeTerrorismLevel: number;
  constructor(
    public _global: AppGlobal,
    private _menuService: MenuItemsService,
    private _aside: AsideStateService,
  ) {
    this._menuService.getMenuItems().then((menuItems) => {
      this.menuItems = menuItems;
    });
    this.activeTerrorismLevel = 3;
  }

  ngOnInit() {

  }

  mouseEnter(event, item) {
    const menuItemName: any = item.querySelector('.menuItem-name');
    const rect = item.getBoundingClientRect();
    menuItemName.style['top'] = rect.y + 10 + 'px';
    menuItemName.classList.add('_hovering');
    // console.log('enter', rect.y, rect, '\n', menuItemName);
  }
  mouseLeave(item) {
    const menuItemName: any = item.querySelector('.menuItem-name');
    menuItemName.classList.remove('_hovering');
    menuItemName.removeAttribute('style');
  }

  // open aside for settings when screen resolution < 992
  openSettings(item) {
    this._aside.openAside(item);
  }

  // items autoclose functionality
  expendHandler(parentCode, childCode) {
    // console.log(parentCode, childCode);
    this.menuItems.forEach(item => {
      if (parentCode) {
        if (item.code === parentCode) {
          item.collapse = !item.collapse;
        } else {
          item.collapse = false;
        }
      } else {
        item.children.forEach(child => {
          if (child.code === childCode) {
            child.collapse = !child.collapse;
          } else {
            child.collapse = false;
          }
        });
      }
    });
  }


}


