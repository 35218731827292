var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SharedService } from '../../core/services/shared-service';
import { StorageService } from '../../core/services/storage-service';
import { Table } from 'primeng/table';
var DataTableComponent = /** @class */ (function () {
    function DataTableComponent(_shared, _storage) {
        this._shared = _shared;
        this._storage = _storage;
        // stored user data
        this.userData = [];
        // stored table options(eg.limit)
        this.tableStorage = [];
        // Table COLUMNS
        this._columns = [];
        this.selectedColumns = [];
        // Table DATA
        this._data = [];
        // LOADING
        this.loadingStatus = { isLoading: false, text: '', show: false, class: 'default' };
        // LIMIT
        this.showingOptions = {
            showing: { start: null, end: null, total: null },
            showingPerPage: { limit: 10 },
            showingPerPageOptions: [10, 20, 50, 100]
        };
        // ROW OPTIONS buttons
        this.rowOptionSelectedData = null;
        // FORMAT DATE FIELDS
        this.dateFields = [];
        this._formatDateFields = [];
        // EVENTS
        this.rowSelect = new EventEmitter();
    }
    Object.defineProperty(DataTableComponent.prototype, "columns", {
        get: function () {
            return this._columns;
        },
        set: function (data) {
            this._columns = data;
            this.selectedColumns = (this.selectedColumns.length > 0 ? this.selectedColumns : data);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataTableComponent.prototype, "data", {
        get: function () {
            return this._data;
        },
        set: function (data) {
            this._data = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataTableComponent.prototype, "formatDateFields", {
        get: function () {
            return this._formatDateFields;
        },
        set: function (data) {
            this._formatDateFields = data;
            this.dateFields = data.map(function (obj) { return obj.field; });
        },
        enumerable: true,
        configurable: true
    });
    DataTableComponent.prototype.ngOnChanges = function (changes) {
        var allowedInputChanges = ['columns', 'data', 'options', 'rowOptions', 'formatDateFields', 'transformFields'];
        var inputs = Object.keys(changes);
        var doReInit = false;
        for (var _i = 0, inputs_1 = inputs; _i < inputs_1.length; _i++) {
            var input = inputs_1[_i];
            if (allowedInputChanges.includes(input) && changes[input].isFirstChange() === false) {
                doReInit = true;
            }
            if (input === 'limit') {
                this.loadRowsPerPage();
            }
        }
        if (doReInit) {
            this.reInitializeTable();
        }
    };
    DataTableComponent.prototype.reInitializeTable = function () {
        if (this.transformFields) {
            this.transformFieldsInTable();
        }
        if (this.hasOption('i')) {
            this.addIndexToTable();
        }
        // set showing info in paginator
        this.generateShowingHandler({ total: this._data.length }, 'init');
    };
    /**
     * Transform corresponding fields in data
     * execute custom function on field comming from parent component
     * function must accept field value as argument and transformed value must be returned
     */
    DataTableComponent.prototype.transformFieldsInTable = function () {
        var data = this._data.slice();
        var _keysOfData = Object.keys(data[0]);
        for (var j = 0; j < data.length; j++) {
            for (var i = 0; i < this.transformFields.length; i++) {
                if (!_keysOfData.includes(this.transformFields[i].field)) {
                    throw new Error('Syntax error: field \'' + this.transformFields[i].field + '\' doesn\'t exist in table data. Field name is case sensitive also.');
                }
                data[j][this.transformFields[i].field] = this.transformFields[i].onField(data[j][this.transformFields[i].field]);
            }
        }
    };
    /**
     * show row index at first column in table
     * fieldName is '#'
     */
    DataTableComponent.prototype.addIndexToTable = function () {
        // prevent adding field 'index' to columns if exists
        // eg. table data has changed after initialization and showIndex is set to true
        var _colFields = this.columns.map(function (f) { return f.field; });
        if (_colFields.includes('#') === false) {
            // add index column to table columns
            this.columns = [{ field: '#', header: '#' }].concat(this.columns);
            // add index column to selected columns (visibility multiselect)
            this.selectedColumns = [{ field: '#', header: '#' }].concat(this.selectedColumns);
        }
        // add index values to table data
        this._data = this._data.map(function (v, i) {
            var _a;
            return v = __assign({}, v, (_a = {}, _a['#'] = i + 1, _a));
        });
    };
    /**
     * Check if page's options is saved in local storage (tableStorage)
     * options: limit (for this url and logged in user)
     */
    DataTableComponent.prototype.loadRowsPerPage = function () {
        var _this = this;
        this._storage.get('userData').then(function (userData) {
            _this.userData = userData === null ? {} : userData;
            _this._storage.get('tableStorage').then(function (tableData) {
                _this.tableStorage = tableData === null ? [] : tableData;
                // check if LS is set for this user and current url
                var savedLimit = _this.tableStorage
                    .filter(function (obj) { return obj.userCode === _this.userData.userCode && obj.url === window.location.href; })
                    .map(function (prop) { return prop.limit; });
                // sets showing options to: ls data | input limit | default
                _this.showingOptions.showingPerPage.limit = savedLimit.length === 1 ?
                    savedLimit :
                    _this.limit ? _this.limit : _this.showingOptions.showingPerPage.limit;
            });
        });
    };
    /**
     * Callback to invoke when a row is selected.
     * @param event Browser event
     * @param rowData Selected data
     */
    DataTableComponent.prototype.onRowSelect = function (event, rowData) {
        var idx = this.data.findIndex(function (d) { return d === rowData; });
        var e = { originalEvent: event, data: rowData, index: idx };
        // OUTPUT EVENT EMITTER
        this.rowSelect.emit(e);
    };
    /**
     * Callback to invoke when data is filtered.
     * Updates showing info
     */
    DataTableComponent.prototype.onFilter = function (event) {
        this.generateShowingHandler(event, 'filter');
    };
    /**
     * Callback to invoke when pagination occurs.
     * Sets local storage for limit (rowsPerPage) better UX
     * Updates showing info
     */
    DataTableComponent.prototype.onPage = function (event) {
        var _this = this;
        // check if LS is set for this user and current url
        var idx = this.tableStorage.findIndex(function (item) { return item.userCode === _this.userData.userCode && item.url === window.location.href; });
        if (idx !== -1) { // modify existing
            this.tableStorage[idx].limit = event.rows;
        }
        else { // add new
            this.tableStorage.push({
                userCode: this.userData.userCode,
                url: window.location.href,
                limit: event.rows
            });
        }
        // set the storage
        this._storage.set('tableStorage', this.tableStorage);
        // updates showing info
        this.generateShowingHandler(event, 'page');
    };
    /**
     * function to update showing entries numbers in paginator
     */
    DataTableComponent.prototype.generateShowingHandler = function (event, type) {
        this.showingOptions = this._shared.generateShowing(event, this.showingOptions, type);
    };
    /**
     * Function to check if a option is presented in Input options
     * @param option single char representing option to display
     */
    DataTableComponent.prototype.hasOption = function (option) {
        if (this.options) {
            return this.options.indexOf(option) !== -1;
        }
        return false;
    };
    /**
     * Callback to invoke when a row option is selected ('btn more').
     * this.rowOptionSelectedData is send back to its parent component
     * via Input rowOptions key 'action'
     * @param event Browser event
     * @param rowData Selected data
     */
    DataTableComponent.prototype.rowOptionHandler = function (op, event, rowData) {
        op.show(event);
        var idx = this.data.findIndex(function (d) { return d === rowData; });
        this.rowOptionSelectedData = { originalEvent: event, data: rowData, index: idx };
    };
    /**
     * Extract format of selected field
     * @param field fieldName
     */
    DataTableComponent.prototype.getDateFormat = function (field) {
        var item = this.formatDateFields.filter(function (obj) { return obj.field === field; });
        return item[0].format;
    };
    DataTableComponent.prototype.getReference = function () {
        return this.tt;
    };
    return DataTableComponent;
}());
export { DataTableComponent };
