/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./app-loader.component";
var styles_AppLoaderComponent = [i0.styles];
var RenderType_AppLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppLoaderComponent, data: {} });
export { RenderType_AppLoaderComponent as RenderType_AppLoaderComponent };
export function View_AppLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading-app"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "loading-spinner"], ["height", "65px"], ["viewBox", "0 0 66 66"], ["width", "65px"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:circle", [["class", "path"], ["cx", "33"], ["cy", "33"], ["fill", "none"], ["r", "30"], ["stroke-linecap", "round"], ["stroke-width", "6"]], null, null, null, null, null))], null, null); }
export function View_AppLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_AppLoaderComponent_0, RenderType_AppLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.AppLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppLoaderComponentNgFactory = i1.ɵccf("app-loader", i2.AppLoaderComponent, View_AppLoaderComponent_Host_0, {}, {}, []);
export { AppLoaderComponentNgFactory as AppLoaderComponentNgFactory };
