<div class="register-form">

  <div class="form-header">
    <h1 class="name">{{appName}}</h1>
    <div class="company-logo"></div>
  </div>

  <div class="form-body">
    <div class="nav-links">
      <a routerLinkActive="active" routerLink="/login">Login</a>
      <a routerLinkActive="active" routerLink="/register">Sign Up</a>
    </div>
    <div class="main">

      <div class="ui-control">
        <span class="ui-float-label">
          <input pInputText [formControl]="userFirstName" type="text">
          <label>First name</label>
        </span>
        <span class="ui-error" *ngIf="formSubmited && userFirstName.hasError('required')">
          First name
          <strong>required</strong>
        </span>
      </div>


      <div class="ui-control">
        <span class="ui-float-label">
          <input pInputText [formControl]="userLastName" type="text">
          <label>Last name</label>
        </span>
        <span class="ui-error" *ngIf="formSubmited && userLastName.hasError('required')">
          Last name
          <strong>required</strong>
        </span>
      </div>

      <div class="ui-control">
        <span class="ui-float-label">
          <input pInputText [formControl]="userEmail" type="email">
          <label>Email</label>
        </span>
        <span class="ui-error" *ngIf="formSubmited && userEmail.hasError('pattern') && !userEmail.hasError('required')">
          Please enter a valid email address
        </span>
        <span class="ui-error" *ngIf="formSubmited && userEmail.hasError('required')">
          Email is
          <strong>required</strong>
        </span>
      </div>

      <div class="ui-control">
        <span class="ui-float-label">
          <input pInputText [formControl]="userPassword" type="{{passwordType}}">
          <label>Password</label>
        </span>
        <span class="ui-error" *ngIf="formSubmited && userPassword.hasError('minlength') && !userPassword.hasError('required')">
          Password must be at least 6 characters
        </span>
        <span class="ui-error" *ngIf="formSubmited && userPassword.hasError('required')">
          Password is
          <strong>required</strong>
        </span>
        <a (click)="togglePasswordShow();$event.stopPropagation()" class="password-show" [class.active]="passwordShow"></a>
      </div>

      <div class="ui-control">
        <span class="ui-float-label">
          <input pInputText [formControl]="userConfirmPassword" type="{{confirmPasswordType}}">
          <label>Confirm password</label>
        </span>
        <span class="ui-error" *ngIf="formSubmited && userPassword.hasError('minlength') && !userConfirmPassword.hasError('required')">
          Confirm password does not match
        </span>
        <span class="ui-error" *ngIf="formSubmited && userConfirmPassword.hasError('required')">
          Confirm password is
          <strong>required</strong>
        </span>
        <a (click)="toggleConfirmPasswordShow();$event.stopPropagation()" class="password-show" [class.active]="confirmPasswordShow"></a>
      </div>

      <div class="r-f-block">
        <div>
          <p-checkbox class="checkbox-rounded" name="userRemember" [value]="userRemember" [(ngModel)]="userRemember"></p-checkbox>
          <label> Remember me</label>
        </div>
        <a class="forgot" routerLink="/forgotPassword">Forgot password?</a>
      </div>
    </div>
  </div>

  <div class="form-footer">
    <button class="btn btn-default" (click)="registerHangler()">
      <span [appIcon]="'tt2'"></span> Sign Up
    </button>
  </div>

</div>




<!-- <div class="register-page">
  <h2>Welcome to RiskDynamyx</h2>

  <div class="register-form">
    <input type="text" placeholder="First name" name="userFirstName" ([ngModel])="userFirstName" />
    <input type="text" placeholder="Surname" name="userSurname" ([ngModel])="userSurname" />
    <input type="email" placeholder="Email" name="userEmail" ([ngModel])="userEmail" />
    <input type="text" placeholder="Location (optional)" name="userLocation" ([ngModel])="userLocation" />
    <input type="password" placeholder="Password" name="userPassword" ([ngModel])="userPassword" />
    <input type="password" placeholder="Confirm password" name="userPasswordConfirm" ([ngModel])="userPasswordConfirm" />

    <div class="app-agreement">
      <input type="checkbox" name="userAgreement" ([ngModel])="userAgreement" />
      <label (click)="showAgreement = true;">I Agree</label>
    </div>

    <div class="form-button-group">
      <button class="btn-login" (click)="loginHangler()">Login</button>
      <button class="btn-register" (click)="registerHangler()">Register</button>
    </div>
  </div>
</div>

<div class="agreement-page" *ngIf="showAgreement">
  <p>
    Start up Photoshop and what do you see? Well, besides the menu options along the top of the screen, you’ll see a Toolbar
    that’s chock-a-block with icons. You’ll probably recognise some of these, such as the ‘T’ that designates the Type tool
    and the paintbrush icon that unlocks the various Brush tools. But do you really know what the rest of these icons mean?
    … Honestly? The chances are that – like many Photoshop users across the world – you’re only using a fraction of the program’s
    full potential, but this second issue in the Photoshop Focus Guide series will change all that, helping you make the
    most of all the tools at your disposal. Even professional designers often admit to being hopeless with the Healing Brush
    and dodgy with the Dodge tool, but there’s really no reason for any of the tools to be a mystery. This issue will help
    you get more from the Clone, Gradient, Paths and Effects tools, to name but a few. You’ll find expert tips and advice
    for working more efficiently with each of the tools, as well as tutorials to demonstrate exactly how to carry out useful
    image-editing tasks. In the final section you’ll find a step-by-step guide that brings together all the skills you’ve
    learnt, showing how to create a striking Photoshop image.

    Start up Photoshop and what do you see? Well, besides the menu options along the top of the screen, you’ll see a Toolbar
    that’s chock-a-block with icons. You’ll probably recognise some of these, such as the ‘T’ that designates the Type tool
    and the paintbrush icon that unlocks the various Brush tools. But do you really know what the rest of these icons mean?
    … Honestly? The chances are that – like many Photoshop users across the world – you’re only using a fraction of the program’s
    full potential, but this second issue in the Photoshop Focus Guide series will change all that, helping you make the
    most of all the tools at your disposal. Even professional designers often admit to being hopeless with the Healing Brush
    and dodgy with the Dodge tool, but there’s really no reason for any of the tools to be a mystery. This issue will help
    you get more from the Clone, Gradient, Paths and Effects tools, to name but a few. You’ll find expert tips and advice
    for working more efficiently with each of the tools, as well as tutorials to demonstrate exactly how to carry out useful
    image-editing tasks. In the final section you’ll find a step-by-step guide that brings together all the skills you’ve
    learnt, showing how to create a striking Photoshop image.

    Start up Photoshop and what do you see? Well, besides the menu options along the top of the screen, you’ll see a Toolbar
    that’s chock-a-block with icons. You’ll probably recognise some of these, such as the ‘T’ that designates the Type tool
    and the paintbrush icon that unlocks the various Brush tools. But do you really know what the rest of these icons mean?
    … Honestly? The chances are that – like many Photoshop users across the world – you’re only using a fraction of the program’s
    full potential, but this second issue in the Photoshop Focus Guide series will change all that, helping you make the
    most of all the tools at your disposal. Even professional designers often admit to being hopeless with the Healing Brush
    and dodgy with the Dodge tool, but there’s really no reason for any of the tools to be a mystery. This issue will help
    you get more from the Clone, Gradient, Paths and Effects tools, to name but a few. You’ll find expert tips and advice
    for working more efficiently with each of the tools, as well as tutorials to demonstrate exactly how to carry out useful
    image-editing tasks. In the final section you’ll find a step-by-step guide that brings together all the skills you’ve
    learnt, showing how to create a striking Photoshop image.

    Start up Photoshop and what do you see? Well, besides the menu options along the top of the screen, you’ll see a Toolbar
    that’s chock-a-block with icons. You’ll probably recognise some of these, such as the ‘T’ that designates the Type tool
    and the paintbrush icon that unlocks the various Brush tools. But do you really know what the rest of these icons mean?
    … Honestly? The chances are that – like many Photoshop users across the world – you’re only using a fraction of the program’s
    full potential, but this second issue in the Photoshop Focus Guide series will change all that, helping you make the
    most of all the tools at your disposal. Even professional designers often admit to being hopeless with the Healing Brush
    and dodgy with the Dodge tool, but there’s really no reason for any of the tools to be a mystery. This issue will help
    you get more from the Clone, Gradient, Paths and Effects tools, to name but a few. You’ll find expert tips and advice
    for working more efficiently with each of the tools, as well as tutorials to demonstrate exactly how to carry out useful
    image-editing tasks. In the final section you’ll find a step-by-step guide that brings together all the skills you’ve
    learnt, showing how to create a striking Photoshop image. </p>

  <button (click)="showAgreement = false">Close</button>
</div> -->
