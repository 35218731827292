import { EventEmitter } from '@angular/core';
import { HttpRequest, HttpEventType, HttpResponse, HttpClient } from '@angular/common/http';
import { Database } from './database';
import { APP_API_HOST } from '../../../environments/environment';
var UploadService = /** @class */ (function () {
    function UploadService(_database, httpClient) {
        var _this = this;
        this._database = _database;
        this.httpClient = httpClient;
        this._dCode = null;
        this.config = {
            storage: {
                maxImageSize: '5242880',
                maxFileSize: '10485760',
                maxImageWHSize: 1280,
                thumbWHSize: 250,
                allowedImageMimeTypes: [
                    'image/bmp',
                    'image/gif',
                    'image/x-icon',
                    'image/jpeg',
                    'image/pjpeg',
                    'image/pict',
                    'image/png',
                    'image/tiff'
                ],
                allowedFileMimeTypes: [
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-powerpoint',
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.oasis.opendocument.text',
                    'application/excel',
                    'application/vnd.ms-excel',
                    'application/x-excel',
                    'application/x-msexcel',
                    'application/x-rar-compressed',
                    'application/octet-stream',
                    'application/zip',
                    'application/octet-stream'
                ],
                allowedAudioMimeTypes: ['audio/mpeg3', 'audio/x-mpeg-3'],
                allowedVideoMimeTypes: ['video/mpeg', 'video/x-mpeg', 'video/x-flv', 'video/mp4', 'video/3gpp']
            }
        };
        this.activeUploads = [];
        this.maxImageSize = this.config.storage.maxImageSize;
        this.maxFileSize = this.config.storage.maxFileSize;
        this.allowedImageMimeTypes = this.config.storage.allowedImageMimeTypes;
        this.allowedFileMimeTypes = this.config.storage.allowedFileMimeTypes;
        this.allowedAudioMimeTypes = this.config.storage.allowedAudioMimeTypes;
        this.allowedVideoMimeTypes = this.config.storage.allowedVideoMimeTypes;
        this._database.getDeviceFingerprint(function (fp) {
            _this._dCode = fp;
        });
    }
    // xhr: XMLHttpRequest = new XMLHttpRequest();
    UploadService.prototype.uploadFiles = function ($files, location, options) {
        var _this = this;
        var _files = Object.assign([], $files);
        var isUploading = false;
        var uploadFiles = [];
        var listen = new EventEmitter();
        var onUploadFinish = new EventEmitter();
        var onFileFinish = new EventEmitter();
        var updateLocation = function (_location) {
            location = _location;
        };
        var addFiles = function (files) {
            var _loop_1 = function (file) {
                file['id'] = Math.floor(Math.random() * 10000) + 1;
                file['originalName'] = file.name;
                file['status'] = 'Waiting';
                file['percentage'] = 0;
                file['serverResponse'] = {};
                var validateFile = _this.validateFile(file);
                file['valid'] = validateFile.valid;
                if (validateFile.valid === false) {
                    file.status = 'Fail';
                    file['message'] = validateFile.message;
                }
                if (file.type.indexOf('image') !== -1) {
                    var reader = new FileReader();
                    reader.onload = function (event) {
                        file['src'] = event.target.result;
                    };
                    reader.readAsDataURL(file);
                    uploadFiles.push(file);
                }
                else {
                    uploadFiles.push(file);
                }
            };
            for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
                var file = files_1[_i];
                _loop_1(file);
            }
        };
        var getFiles = function () {
            return uploadFiles;
        };
        var startUpload = function () {
            if (isUploading === false) {
                isUploading = true;
                processNextFile();
            }
        };
        var processNextFile = function () {
            var index = uploadFiles.findIndex(function (value, i) {
                return value.status === 'Waiting';
            });
            if (index !== -1) {
                processFile(index);
            }
            else {
                onUploadFinish.emit(uploadFiles);
            }
        };
        var processFile = function (index) {
            if (uploadFiles[index]) {
                if (uploadFiles[index].valid === false || uploadFiles[index].status === 'Finished') {
                    processNextFile();
                    // cb
                    return;
                }
                uploadFiles[index]['status'] = 'Uploading';
                var formData = new FormData();
                formData.append('file[]', uploadFiles[index]);
                var url = "" + APP_API_HOST + location;
                var req = new HttpRequest('POST', url, formData, {
                    withCredentials: true,
                    reportProgress: true
                });
                var request = _this.httpClient.request(req).subscribe(function (event) {
                    if (event.type === HttpEventType.UploadProgress) {
                        uploadFiles[index]['percentage'] = Math.round((100 * event.loaded) / event.total);
                        listen.emit(uploadFiles);
                    }
                    else if (event instanceof HttpResponse) {
                        uploadFiles[index]['percentage'] = 100;
                        uploadFiles[index]['status'] =
                            (event.body.data[0] && event.body.data[0].serverResponse.databaseCode == null) ||
                                event.body.success === false
                                ? 'Fail'
                                : 'Finished';
                        uploadFiles[index]['success'] =
                            (event.body.data[0] && event.body.data[0].serverResponse.databaseCode == null) ||
                                event.body.success === false
                                ? false
                                : true;
                        uploadFiles[index]['message'] = event.body.message;
                        if (event.body.success === true && event.body.data[0]) {
                            uploadFiles[index]['originalName'] = event.body.data[0].originalName;
                            uploadFiles[index]['newName'] = event.body.data[0].newName;
                            uploadFiles[index]['extension'] = event.body.data[0].extension;
                            uploadFiles[index]['serverResponse'] = event.body.data[0].serverResponse;
                        }
                        listen.emit(uploadFiles);
                        _this.removeFromActiveRequests(uploadFiles[index].id);
                        onFileFinish.emit(uploadFiles[index]);
                        processNextFile();
                    }
                }, function (error) {
                    uploadFiles[index]['status'] = 'Fail';
                    uploadFiles[index]['success'] = false;
                    uploadFiles[index]['message'] = error;
                    uploadFiles[index]['originalName'] = null;
                    uploadFiles[index]['newName'] = null;
                    uploadFiles[index]['extension'] = null;
                    uploadFiles[index]['serverResponse'] = {};
                    listen.emit(uploadFiles);
                    onFileFinish.emit(uploadFiles[index]);
                    _this.removeFromActiveRequests(uploadFiles[index].id);
                    processNextFile();
                });
                _this.activeUploads.push({ id: uploadFiles[index].id, request: request });
            }
            else {
                listen.emit(uploadFiles);
                onUploadFinish.emit(uploadFiles);
            }
        };
        var removeFile = function (fileID) {
            var index = uploadFiles.findIndex(function (item) {
                return item.id === fileID;
            });
            if (index !== -1) {
                if (uploadFiles[index].status === 'Uploading') {
                    _this.removeFromActiveRequests(uploadFiles[index].id);
                    uploadFiles.splice(index, 1);
                    processNextFile();
                    listen.emit(uploadFiles);
                }
                else {
                    uploadFiles.splice(index, 1);
                    listen.emit(uploadFiles);
                }
            }
        };
        addFiles(_files);
        return {
            onUploadFinish: onUploadFinish,
            onFileFinish: onFileFinish,
            listen: listen,
            removeFile: removeFile,
            getFiles: getFiles,
            startUpload: startUpload,
            addFiles: addFiles,
            updateLocation: updateLocation
        };
    };
    UploadService.prototype.removeFromActiveRequests = function (id) {
        var index = this.activeUploads.findIndex(function (item) {
            return item.id === id;
        });
        if (index !== -1) {
            this.activeUploads[index].request.unsubscribe();
            this.activeUploads.splice(index, 1);
        }
    };
    UploadService.prototype.bytesToSize = function (bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) {
            return '0 Byte';
        }
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
        var size = bytes / Math.pow(1024, i);
        return size.toFixed(2) + ' ' + sizes[i];
    };
    UploadService.prototype.checkPicture = function (url) {
        this.httpClient.get(url).subscribe(function (response) { });
    };
    UploadService.prototype.validateFile = function (file) {
        var type = file.type.indexOf('image') !== -1 ? 'image' : 'file';
        var maxSize = type === 'image' ? this.maxImageSize : this.maxFileSize;
        if (file.size > maxSize) {
            return { valid: false, message: 'File exceed max file size' };
        }
        else {
            if ((type === 'image' && this.allowedImageMimeTypes.indexOf(file.type) === -1) ||
                (type === 'file' &&
                    this.allowedFileMimeTypes.indexOf(file.type) === -1 &&
                    this.allowedImageMimeTypes.indexOf(file.type) === -1 &&
                    this.allowedAudioMimeTypes.indexOf(file.type) === -1 &&
                    this.allowedVideoMimeTypes.indexOf(file.type) === -1)
            //  || (type === 'audio' && this.allowedAudioMimeTypes.indexOf(file.type) === -1) ||
            // (type === 'video' && this.allowedVideoMimeTypes.indexOf(file.type) === -1)
            ) {
                // Invalid file type
                return { valid: false, message: 'Invalid file type' };
            }
            else {
                return { valid: true, message: null };
            }
        }
    };
    UploadService.prototype.validateFiles = function (options, files, callback) {
        var maxSize = options.type === 'image' ? this.maxImageSize : this.maxFileSize;
        var fileSizeFailed = [];
        var fileTypeFailed = [];
        var failedFiles = 0;
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            if (file.size > maxSize) {
                // Invalid file size
                files[i].valid = false;
                files[i].status = 'Validation error';
                files[i].done = true;
                files[i].success = false;
                files[i].errorMessage =
                    files[i].errorMessage +
                        'File(' +
                        this.bytesToSize(file.size) +
                        ') exceeds maximum file size of ' +
                        this.bytesToSize(maxSize) +
                        '. ';
            }
            if ((options.type === 'image' && this.allowedImageMimeTypes.indexOf(file.type) === -1) ||
                (options.type === 'file' &&
                    this.allowedFileMimeTypes.indexOf(file.type) === -1 &&
                    this.allowedImageMimeTypes.indexOf(file.type) === -1 &&
                    this.allowedAudioMimeTypes.indexOf(file.type) === -1 &&
                    this.allowedVideoMimeTypes.indexOf(file.type) === -1) ||
                (options.type === 'audio' && this.allowedAudioMimeTypes.indexOf(file.type) === -1) ||
                (options.type === 'video' && this.allowedVideoMimeTypes.indexOf(file.type) === -1)) {
                // Invalid file type
                files[i].valid = false;
                files[i].status = 'Validation error';
                files[i].done = true;
                files[i].success = false;
                files[i].errorMessage = files[i].errorMessage + 'File type is not allowed (' + file.type + '). ';
            }
            if (files[i].valid === false) {
                failedFiles = failedFiles + 1;
            }
        }
        callback(failedFiles, files);
    };
    return UploadService;
}());
export { UploadService };
