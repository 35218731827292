import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { FilterAsideStateService } from './filter-aside-state.service';

@Component({
  selector: 'app-filter-aside',
  templateUrl: './filter-aside.component.html',
  styleUrls: ['./filter-aside.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'scaleX(1)',
      })),
      state('out', style({
        transform: 'scaleX(0)',
      })),
      transition('out <=> in', animate('100ms ease-in-out'))
    ])
  ],
})
export class FilterAsideComponent implements OnInit, OnDestroy {
  filterIsOpened = false;
  animateState = 'out';

  filterSubscription;
  @Output() filterStatus = new EventEmitter<any>();

  constructor(private _filter: FilterAsideStateService) {
    this.filterSubscription = this._filter.getFilterAsideState().subscribe(data => {
      // console.log('filterSubscription', data);
      this.toggleFilter(data);
    });

  }

  ngOnInit() {
  }

  /**
   * if (data.state) => open and animate it
   * else => animate and then close it
   * @param item subscribed data containing component state
   */
  toggleFilter(data) {
    if (data.state === true) {
      this.filterIsOpened = true;
      this.animateState = 'in';

      setTimeout(() => {
        this.filterStatus.emit({ 'filterOpen': this.filterIsOpened });
      }, 100);
    } else {
      this.animateState = 'out';

      setTimeout(() => {
        this.filterIsOpened = false;
        this.filterStatus.emit({ 'filterOpen': this.filterIsOpened });
      }, 100);
    }
  }


  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }
}
