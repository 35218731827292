var AppGlobal = /** @class */ (function () {
    function AppGlobal() {
        this._state = {};
        // this.set('name', value);
    }
    Object.defineProperty(AppGlobal.prototype, "state", {
        get: function () {
            return this._state = this._clone(this._state);
        },
        set: function (value) {
            throw new Error('do not mutate the `.state` directly');
        },
        enumerable: true,
        configurable: true
    });
    AppGlobal.prototype.get = function (prop) {
        var state = this.state;
        return state.hasOwnProperty(prop) ? state[prop] : null;
    };
    AppGlobal.prototype.set = function (prop, value) {
        return this._state[prop] = value;
    };
    AppGlobal.prototype._clone = function (object) {
        return JSON.parse(JSON.stringify(object));
    };
    return AppGlobal;
}());
export { AppGlobal };
