import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NotificationService } from './notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('notificationState', [
      state('inactive', style({
        opacity: '0'
      })),
      state('active', style({
        opacity: '1'
      })),
      transition('inactive => active', animate('100ms ease-in')),
      transition('active => inactive', animate('100ms ease-out'))
    ])
  ]
})
export class NotificationComponent implements OnInit, OnDestroy {
  NOTIFICATIONS: any = [];
  options: any;
  notificationSubsctiption;

  constructor(
    public _notificationService: NotificationService,
  ) {
    // subscibe to get activeNotifications from service
    this.notificationSubsctiption = this._notificationService.getNotifications().subscribe((notification) => {
      // set recieved data to NOTIFICATIONS LIST to display individual notifications
      this.NOTIFICATIONS = notification;
    });
  }


  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this._notificationService.clear();

    // unsubscribe to ensure no memory leaks
    this.notificationSubsctiption.unsubscribe();
  }

  remove(id) {
    // console.log('remove', this);
    this._notificationService.remove(id);
  }

  buttonHandler(notification, button) {
    if (button.role === 'cancel') {
      button.action();
      this.remove(notification.id);
    } else {
      let inputParent: any,
        $inputs: any;
      const $inputData = [];

      if (notification.inputs.length > 0) {
        inputParent = document.querySelector('.notification-inputs');
        $inputs = inputParent.querySelectorAll('input');
        // console.log('inputs', $inputs);

        $inputs.forEach(element => {
          $inputData.push({ name: element.name, value: element.value });
        });
      }

      // console.log($inputData);
      button.action($inputData);
      this.remove(notification.id);
    }
  }

  mouseEnter(notification) {
    if (notification.autoClose === true) {
      this._notificationService.triggerHover();
    }
  }
  mouseLeave(notification) {
    if (notification.autoClose === true) {
      this._notificationService.cancelHover();
    }
  }


}
