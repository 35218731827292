import { Injectable } from '@angular/core';
import { StorageService } from './storage-service';
import { Database } from './database';
import { Router } from '@angular/router';
import { AppCommunication } from './app.communication';

@Injectable()
export class UserStateService {

    user: any;
    isLogged: boolean;

    constructor(
        private _storage: StorageService,
        private _database: Database,
        private router: Router,
        private _communication: AppCommunication
    ) {
        this._communication.logoutUser.subscribe(() => {
            this.logoutUser();
        });
    }

    loadUser(): Promise<boolean> {
        return new Promise((resolve) => {
            this._storage.get('userData').then((userData) => {
                if (userData !== null && userData !== undefined) {
                    this._database.executeRequest('/api/checkToken', 'post', {}).then((res: any) => {
                        if (res.success === true) {
                            resolve(true);
                            this.loginUser(userData, false);
                        } else {
                            resolve(false);
                            this.logoutUser();
                        }
                    }, (err) => {
                        resolve(false);
                        this.logoutUser();
                    });
                } else {
                    resolve(false);
                    this.logoutUser();
                }
            });
        });
    }

    public getUserData() {
        return this.user;
    }

    public getUserDataAsync() {
        return new Promise((resolve) => {
            this._storage.get('userData').then((userData) => {
                this.user = userData;
                if (!userData) {
                    return this.logoutUser();
                }
                resolve(userData);
            });
        });
    }

    public logoutUser() {
        if (this.isLogged === undefined || this.isLogged === true) {
            this.isLogged = false;
            this.router.navigate(['/login']);
            this._storage.remove('institutionLanguage');
            this._storage.remove('userData');
            this._database.executeRequest('/api/logout', 'post', {}).then((data) => {
            }, (err) => {
            });
        }
    }

    public loginUser(userData, rerouteHome: boolean) {
        this.isLogged = true;
        this.user = userData;
        this._storage.set('institutionLanguage', userData.languageInstitution);
        this._storage.set('userData', userData);
        if (rerouteHome) {
            this.router.navigate(['/']);
        }
    }

}
