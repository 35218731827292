import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';

import { AppCommunication } from '../services/app.communication';
import { AsideStateService } from './aside-state.service';
import { StorageService } from '../services/storage-service';
import { UserStateService } from '../services/userstate.service';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'scaleX(1)',
      })),
      state('out', style({
        transform: 'scaleX(0)',
      })),
      transition('out <=> in', animate('100ms ease-in-out'))
    ])
  ],
})
export class AsideComponent implements OnInit, OnDestroy {
  userTypeName: string;
  userEmail: any;
  institutionName: any;
  userFullName: any;

  asideIsOpened = false;
  animateState = 'out';
  asideSubscription;
  @Output() activeItem = new EventEmitter<any>();

  selectedItem: string = null;
  activeSettingsTab = 'tab-general';

  // CHANGE THEME VARs
  themes = [
    { name: 'Standard', value: 1 },
    { name: 'Dark Green', value: 2 },
    { name: 'Dark Pink', value: 3 },
    { name: 'White Green', value: 4 },
  ];
  selectedTheme = {};
  togglePre = false;

  constructor(
    private router: Router,
    private _communication: AppCommunication,
    private _aside: AsideStateService,
    private _storage: StorageService,
    private user: UserStateService
  ) {
    this.userFullName = this.user.user.userFullName;
    this.institutionName = this.user.user.institutionName;
    this.userEmail = this.user.user.userEmail;
    this.userTypeName = this.user.user.userTypeName;

    this.loadTheme();

    this.asideSubscription = this._aside.getAsideState().subscribe(data => {
      // console.log('asideSubscription', data);
      this.toggleAside(data);
    });

    // hide code PRE elements on first init!
    this.togglePreState();
  }

  ngOnInit() {
  }

  loadTheme() {
    const appThemeLS = localStorage.getItem('appTheme');
    let dataLS: number;

    if (appThemeLS === undefined || appThemeLS === null) {
      dataLS = 0;
      this.selectedTheme = this.themes[0];
    } else {
      dataLS = Number(appThemeLS);
      this.selectedTheme = this.themes.filter(theme => theme.value === dataLS)[0];
    }

    this._communication.themeService.next(dataLS);
  }

  onThemeChange(e) {
    console.log(e);
    this.selectedTheme = e.value;
    // console.log(this.selectedTheme);
    this._communication.themeService.next(e.value.value);
  }

  feedback() {
    this._communication.feedbackService.next(true);
  }

  /**
   * if (data.state) => open it, then animate and get item data
   * else => animate and then close it
   * @param item subscribed data containing component state and desired item
   */
  toggleAside(data) {
    if (data.state === true) {
      this.asideIsOpened = true;

      setTimeout(() => {
        this.animateState = 'in';
        this.selectedItem = data.item;

        this.activeItem.emit({ 'activeItem': this.selectedItem });
        this.switchItem(data.item);
      }, 100);
    } else {
      this.animateState = 'out';

      setTimeout(() => {
        this.asideIsOpened = false;
        this.selectedItem = null;

        this.activeItem.emit({ 'activeItem': this.selectedItem });
      }, 100);
    }
  }

  switchItem(item) { // GET DATA FOR EACH ITEM
    if (item === 'user') {
      // Your code here...
      // console.log('switch user');
    }
    if (item === 'notifications') {
      // Your code here...
      // console.log('switch notifications');
    }
    if (item === 'settings') {
      // Your code here...
      // console.log('switch settings');
    }
  }

  switchSettingsTab(tab) {
    this.activeSettingsTab = tab;
    if (tab === 'tab-general') {
      // Your code here...
      console.log('switch tab-general');
    }
    if (tab === 'tab-account') {
      // Your code here...
      console.log('switch tab-account');
    }
    if (tab === 'tab-notification') {
      // Your code here...
      console.log('switch tab-notification');
    }
  }


  logoutHandler() {
    this.user.logoutUser();
  }


  togglePreState() {
    if (this.togglePre === true) {
      document.body.classList.remove('__hide-pre-code');
    } else {
      document.body.classList.add('__hide-pre-code');
    }
  }

  ngOnDestroy(): void {
    this.asideSubscription.unsubscribe();
  }

}
