import { Directive, OnInit, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'button, [ripple]'
})
export class RippleDirective implements OnInit {
  ripple; // ripple element to which effect is added
  rippleContainer; // ripple effect

  constructor(private _el: ElementRef) {
    this.ripple = _el.nativeElement;
  }

  ngOnInit(): void {
    // prevent creating ripple efect for elements that are disabled
    if (this.ripple.attributes.disabled || this.ripple.attributes.disabled === 'true') {
      return;
    }

    // console.log(this.ripple);

    this.ripple.classList.add('ripple');

    // create ripple container element and add effect
    this.rippleContainer = document.createElement('div');
    this.rippleContainer.className = 'ripple--container';

    // add event listeners
    this.ripple.addEventListener('mousedown', this.showRipple);
    this.ripple.addEventListener('mouseup', this.debounce(this.cleanUp, 2000));

    // add element to DOM
    this.ripple.rippleContainer = this.rippleContainer;
    this.ripple.appendChild(this.rippleContainer);

    this.checkLightOrDark();
  }

  /**
   * Ripple effect
   */
  debounce(func, delay) {
    let inDebounce;
    inDebounce = undefined;
    return function () {
      let args, context;
      context = this;
      args = arguments;
      clearTimeout(inDebounce);
      return inDebounce = setTimeout(function () {
        return func.apply(context, args);
      }, delay);
    };
  }

  showRipple(e) {
    let pos, ripple, rippler, size, style, x, y;
    ripple = this;
    rippler = document.createElement('span');
    size = ripple.offsetWidth;
    pos = ripple.getBoundingClientRect();
    x = e.pageX - pos.left - (size / 2);
    y = e.pageY - pos.top - (size / 2);
    style = 'top:' + y + 'px; left: ' + x + 'px; height: ' + size + 'px; width: ' + size + 'px;';
    ripple.rippleContainer.appendChild(rippler);
    return rippler.setAttribute('style', style);
  }

  cleanUp() {
    while (this.rippleContainer.firstChild) {
      this.rippleContainer.removeChild(this.rippleContainer.firstChild);
    }
  }

  getElBgColor(): string {
    return window.getComputedStyle(this.ripple, null).getPropertyValue('background-color');
  }

  /**
   * get element background-color and set coresponding effect
   * background light => add dark effect
   * background dark => remove dark effect (default effect)
   */
  checkLightOrDark() {
    let r, b, g, hsp,
      a: any = this.getElBgColor();

    if (a.match(/^rgb/)) {
      a = a.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      r = a[1];
      g = a[2];
      b = a[3];
    } else {
      a = +('0x' + a.slice(1).replace(
        a.length < 5 && /./g, '$&$&'
      ));
      // tslint:disable-next-line:no-bitwise
      r = a >> 16; g = a >> 8 & 255; b = a & 255;
    }
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );
    // a[0] === 'rgba(0, 0, 0, 0)' check if bg color is transparent or it is not set
    if (hsp > 127.5 || a[0] === 'rgba(0, 0, 0, 0)') {
      // console.log(this.ripple, 'bg is light');
      this.rippleContainer.classList.add('--dark-effect');
    } else {
      // console.log(this.ripple, 'bg is dark');
      this.rippleContainer.classList.remove('--dark-effect');
    }
  }

}
