var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import * as Chart from 'chart.js';
import 'chartjs-plugin-datalabels';
import { Database } from '../../core/services/database';
import { UserStateService } from '../../core/services/userstate.service';
export var RecommendationStatusCode;
(function (RecommendationStatusCode) {
    RecommendationStatusCode[RecommendationStatusCode["new"] = 1] = "new";
    RecommendationStatusCode[RecommendationStatusCode["inProgress"] = 2] = "inProgress";
    RecommendationStatusCode[RecommendationStatusCode["postponed"] = 3] = "postponed";
    RecommendationStatusCode[RecommendationStatusCode["completed"] = 4] = "completed";
})(RecommendationStatusCode || (RecommendationStatusCode = {}));
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(_database, user) {
        this._database = _database;
        this.user = user;
        this.propertiesData = [];
        this.treatmentValues = [
            { code: 1, name: 'Extreme', color: '#F35B5B', from: 0, to: 0.3, count: 0 },
            {
                code: 2,
                name: 'High',
                color: '#f9c841',
                from: 0.300001,
                to: 0.65,
                count: 0
            },
            {
                code: 3,
                name: 'Medium',
                color: '#31ca42',
                from: 0.650001,
                to: 0.85,
                count: 0
            },
            {
                code: 4,
                name: 'Low',
                color: '#3ebae1',
                from: 0.8500001,
                to: 1,
                count: 0
            },
            {
                code: 5,
                name: 'Undefined',
                color: '#cccccc',
                from: null,
                to: null,
                count: 0
            }
        ];
        this.logData = [];
        this.incidentsData = [];
        this.incidentsType = [];
        this.incidentsStatus = [];
        this.totalIncidents = null;
        this.recommendationsData = [];
        this.openRecommendations = [];
        this.recommendationsStatus = {
            labels: [],
            colors: [],
            data: []
        };
        this.loadingProperty = { isLoading: true, text: 'Loading', show: true, class: 'default' };
        this.loadingLog = { isLoading: true, text: 'Loading', show: true, class: 'default' };
        this.loadingIncidents = { isLoading: true, text: 'Loading', show: true, class: 'default' };
        this.loadingRecommendations = {
            isLoading: true,
            text: 'Loading',
            show: true,
            class: 'default'
        };
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var userData = this.user.getUserData();
        if (userData.userTypeName === 'Client') {
            this.businessPartnerCode = userData.userGroupValue;
            this.getData();
        }
        // admin charts
        this.runDashboardPieChart();
        this.runDashboardLineChart();
        this.runDashboardBarHorizontalChart();
        this.runDashboardBarChart();
        this.runDashboardPolarChart();
        this.runDashboardRadarChart();
    };
    DashboardComponent.prototype.getData = function () {
        this.loadPropertiesData();
        this.loadLogData();
        this.loadIncidentsData();
        this.loadRecommendationData();
    };
    DashboardComponent.prototype.loadPropertiesData = function () {
        var _this = this;
        this._database
            .executeRequest("/api/clientdashboard/" + this.businessPartnerCode + "/properties", 'get', {})
            .then(function (response) {
            _this.propertiesData = response.data
                .map(function (d) {
                var riskValue = d.treatmentValueWeighted;
                var riskRow = _this.treatmentValues.filter(function (value) {
                    if (riskValue === null) {
                        return value.code === 5;
                    }
                    else {
                        return riskValue >= value.from && riskValue <= value.to;
                    }
                });
                _this.treatmentValues = _this.treatmentValues.map(function (value) {
                    if (value.code === riskRow[0].code) {
                        return __assign({}, value, { count: (value.count += 1) });
                    }
                    return value;
                });
                d = __assign({}, d, { riskValue: riskValue, riskRow: riskRow[0] });
                return d;
            })
                .sort(function (a, b) { return parseFloat(a.riskValue) - parseFloat(b.riskValue); });
            _this.loadingProperty = { isLoading: false, text: '', show: false, class: 'default' };
            // console.log(this.propertiesData);
        })
            .catch(function (err) { return console.warn(err); });
    };
    DashboardComponent.prototype.loadLogData = function () {
        var _this = this;
        this._database
            .executeRequest("/api/clientdashboard/" + this.businessPartnerCode + "/log", 'get', {})
            .then(function (response) {
            _this.logData = response.data.map(function (log) {
                log.url = '';
                log.hash = null;
                if (log.logData.businessPartnerPropertyCode) {
                    log.url = "/property/" + log.logData.businessPartnerPropertyCode;
                }
                if (log.logData.businessPartnerPropertyIncidentsCode) {
                    // log.url = `${log.url}#incidents`;
                    log.hash = 'incidents';
                }
                if (log.logData.propertyRiskAssessmentQuestionRecommendationCode) {
                    // log.url = `${log.url}#recommendations`;
                    log.hash = 'recommendations';
                }
                return log;
            });
            _this.loadingLog = { isLoading: false, text: '', show: false, class: 'default' };
        })
            .catch(function (err) { return console.warn(err); });
    };
    DashboardComponent.prototype.loadIncidentsData = function () {
        var _this = this;
        Promise.all([this.getIncidentsData(), this.getIncidentsStatus(), this.getIncidentsType()]).then(function (res) {
            // TOO SLOW
            //this.runDashboardClientLineChart();
            _this.loadingIncidents = { isLoading: false, text: '', show: false, class: 'default' };
        });
    };
    DashboardComponent.prototype.getIncidentsData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._database
                .executeRequest("/api/clientdashboard/" + _this.businessPartnerCode + "/incidents", 'get', {})
                .then(function (response) {
                _this.incidentsData = response.data;
                resolve();
            })
                .catch(function (err) { return console.warn(err); });
        });
    };
    DashboardComponent.prototype.getIncidentsStatus = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._database
                .executeRequest("/api/clientdashboard/" + _this.businessPartnerCode + "/incidentsStatus", 'get', {})
                .then(function (response) {
                _this.incidentsStatus = response.data;
                resolve();
            })
                .catch(function (err) { return console.warn(err); });
        });
    };
    DashboardComponent.prototype.getIncidentsType = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._database
                .executeRequest("/api/clientdashboard/" + _this.businessPartnerCode + "/incidentsType", 'get', {})
                .then(function (response) {
                _this.incidentsType = response.data
                    .filter(function (type) { return type.propertyIncidentsCount > 0; })
                    .sort(function (a, b) { return parseFloat(b.propertyIncidentsCount) - parseFloat(a.propertyIncidentsCount); });
                _this.totalIncidents = 0;
                _this.incidentsType.forEach(function (type) { return (_this.totalIncidents += type.propertyIncidentsCount); });
                resolve();
            })
                .catch(function (err) { return console.warn(err); });
        });
    };
    DashboardComponent.prototype.loadRecommendationData = function () {
        var _this = this;
        Promise.all([this.getRecommendationData(), this.getRecommendationStatus()]).then(function (res) {
            _this.runDashboardClientRecommendationsChart();
            _this.loadingRecommendations = { isLoading: false, text: '', show: false, class: 'default' };
        });
    };
    DashboardComponent.prototype.getRecommendationData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._database
                .executeRequest("/api/clientdashboard/" + _this.businessPartnerCode + "/recommendations", 'get', {})
                .then(function (response) {
                _this.recommendationsData = response.data;
                _this.openRecommendations = _this.recommendationsData.filter(function (r) {
                    return (r.recommendationStatusCode === RecommendationStatusCode.new ||
                        r.recommendationStatusCode === RecommendationStatusCode.inProgress);
                });
                resolve();
            })
                .catch(function (err) { return console.warn(err); });
        });
    };
    DashboardComponent.prototype.getRecommendationStatus = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._database
                .executeRequest("/api/clientdashboard/" + _this.businessPartnerCode + "/recommendationsStatus", 'get', {})
                .then(function (response) {
                _this.recommendationsStatus = {
                    labels: [],
                    colors: [],
                    data: []
                };
                response.data.forEach(function (value) {
                    if (value.propertyRecommendationsCount === 0) {
                        return;
                    }
                    _this.recommendationsStatus.labels.push(value.recommendationStatusName);
                    _this.recommendationsStatus.colors.push(_this.getRecommendationStatusColor(value.recommendationStatusCode));
                    _this.recommendationsStatus.data.push(value.propertyRecommendationsCount);
                });
                resolve();
            })
                .catch(function (err) { return console.warn(err); });
        });
    };
    DashboardComponent.prototype.getRecommendationStatusColor = function (code) {
        if (code === 1) {
            // new
            return '#F35B5B';
        }
        else if (code === 2) {
            // in progress
            return '#3ebae1';
        }
        else if (code === 3) {
            // postponed
            return '#F9C841';
        }
        else {
            // completed
            return '#31ca42';
        }
    };
    DashboardComponent.prototype.runDashboardLineChart = function () {
        var data = [
            {
                data: [2300, 2000, 1000, 1200, 1400, 1800, 2300, 1900, 1600, 0],
                label: 'Users',
                borderColor: '#F37329',
                borderWidth: 2,
                backgroundColor: 'rgba(243, 98, 30, .5)',
                fill: true
            }
        ];
        var labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
        var ctx = 'dashboardLineChart';
        var _chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: data
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                elements: {
                    line: {
                        tension: 0.5
                    }
                },
                legend: { display: false },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false
                            }
                        }
                    ]
                }
            }
        });
    };
    DashboardComponent.prototype.runDashboardPieChart = function () {
        var colors = ['#F37329', 'rgba(243, 98, 30, .5)'];
        var labels = ['Active', 'Inactive'];
        var data = [31, 12];
        var ctx = 'dashboardPieChart';
        var _chart = new Chart(ctx, {
            type: 'pie',
            options: {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    position: 'right',
                    labels: {
                        usePointStyle: true,
                        padding: 15,
                        fontColor: '#787878',
                        fontSize: 12
                    }
                },
                tooltips: {
                    enabled: false
                },
                plugins: {
                    datalabels: {
                        color: 'white',
                        font: {
                            weight: 'bold',
                            size: 30
                        },
                        formatter: Math.round
                    }
                }
            },
            data: {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: colors,
                        data: data
                    }
                ]
            }
        });
    };
    DashboardComponent.prototype.runDashboardBarHorizontalChart = function () {
        var labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
        var colors = ['#F37329', '#F37329', '#F37329', '#F37329', '#F37329', '#F37329'];
        var data = [12, 8, 9, 3, 14, 6, 15];
        var ctx = 'dashboardBarHorizontalChart';
        var _chart = new Chart(ctx, {
            type: 'horizontalBar',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Posts',
                        backgroundColor: colors,
                        data: data
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                legend: { display: false },
                title: {
                    display: false
                },
                layout: {
                    padding: {
                        top: 15
                    }
                },
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'end',
                        offset: 5,
                        color: function (context) {
                            return context.dataset.backgroundColor;
                        },
                        font: {
                            weight: '500',
                            size: 20
                        }
                    }
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                                drawBorder: false
                            },
                            ticks: {
                                suggestedMin: 0,
                                beginAtZero: true,
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                                drawBorder: false
                            },
                            ticks: {
                                display: true
                            }
                        }
                    ]
                }
            }
        });
    };
    DashboardComponent.prototype.runDashboardBarChart = function () {
        var labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
        var colors = ['#F37329', '#F37329', '#F37329', '#F37329', '#F37329', '#F37329'];
        var colors1 = [
            'rgba(243, 98, 30, .5)',
            'rgba(243, 98, 30, .5)',
            'rgba(243, 98, 30, .5)',
            'rgba(243, 98, 30, .5)',
            'rgba(243, 98, 30, .5)',
            'rgba(243, 98, 30, .5)'
        ];
        var data = [8, 5, 6, 3, 12, 5, 13];
        var data1 = [5, 2, 11, 1, 1, 5, 13];
        var ctx = 'dashboardBarChart';
        var _chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Views',
                        backgroundColor: colors,
                        data: data
                    },
                    {
                        label: 'Comments',
                        backgroundColor: colors1,
                        data: data1
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                legend: { display: true },
                title: {
                    display: false,
                    text: 'Title'
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                suggestedMin: 0,
                                beginAtZero: true
                            }
                        }
                    ]
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                }
            }
        });
    };
    DashboardComponent.prototype.runDashboardPolarChart = function () {
        var labels = ['News', 'Blog', 'Reviews'];
        var colors = ['#F37329', 'rgba(243, 98, 30, .75)', 'rgba(243, 98, 30, .5)'];
        var data = [36, 21, 13];
        var ctx = 'dashboardPolarChart';
        var _chart = new Chart(ctx, {
            type: 'polarArea',
            options: {
                responsive: true,
                maintainAspectRatio: true,
                title: {
                    display: false
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                legend: {
                    position: 'right',
                    labels: {
                        usePointStyle: true,
                        padding: 15,
                        fontColor: '#787878',
                        fontSize: 12
                    }
                },
                layout: {
                    padding: {
                        top: 15
                    }
                }
            },
            data: {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: colors,
                        data: data
                    }
                ]
            }
        });
    };
    DashboardComponent.prototype.runDashboardRadarChart = function () {
        var labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
        var datasets = [
            {
                label: 'News',
                fill: true,
                backgroundColor: 'rgba(243, 98, 30, .1)',
                borderColor: 'rgba(243, 98, 30, .5)',
                pointBorderColor: '#FFF',
                pointBackgroundColor: '#F37329',
                data: [20, 8, 6, 5, 8, 10]
            },
            {
                label: 'Blog',
                fill: true,
                backgroundColor: 'rgba(64, 83, 100, .1)',
                borderColor: 'rgba(64, 83, 100, .5)',
                pointBorderColor: '#FFF',
                pointBackgroundColor: '#405364',
                data: [14, 15, 9, 7, 3, 8]
            },
            {
                label: 'Reviews',
                fill: true,
                backgroundColor: 'rgba(91, 192, 222, .1)',
                borderColor: 'rgba(91, 192, 222, .5)',
                pointBorderColor: '#FFF',
                pointBackgroundColor: '#5bc0de',
                data: [6, 6, 7, 2, 8, 1]
            }
        ];
        var ctx = 'dashboardRadarChart';
        var _chart = new Chart(ctx, {
            type: 'radar',
            options: {
                responsive: true,
                maintainAspectRatio: true,
                title: {
                    display: false
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                legend: {
                    position: 'right',
                    labels: {
                        usePointStyle: true,
                        padding: 15,
                        fontColor: '#787878',
                        fontSize: 12
                    }
                },
                layout: {
                    padding: {
                        top: 15
                    }
                }
            },
            data: {
                labels: labels,
                datasets: datasets
            }
        });
    };
    // CLIENT CHARTS
    DashboardComponent.prototype.runDashboardClientRecommendationsChart = function () {
        // const labels = ['Extreme', 'High', 'Medium', 'Low', 'Undefined'];
        // const colors = ['#f35b5b', '#f9c841', '#31ca42', '#3ebae1', '#cccccc'];
        // const data = [12, 8, 9, 14, 6];
        var labels = this.recommendationsStatus.labels;
        var colors = this.recommendationsStatus.colors;
        var data = this.recommendationsStatus.data;
        var ctx = 'dashboardClientRecommendationsChart';
        var _chart = new Chart(ctx, {
            type: 'doughnut',
            options: {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    position: 'top',
                    text: 'All Recommendations'
                },
                legend: {
                    // display: false,
                    position: 'bottom',
                    labels: {
                        usePointStyle: true,
                        padding: 15,
                        fontColor: '#787878',
                        fontSize: 12
                    },
                    onClick: function () { } // disable toggle behaviour
                },
                tooltips: {
                    enabled: true
                },
                plugins: {
                    datalabels: {
                        color: 'white',
                        font: {
                            weight: 'bold',
                            size: 24
                        },
                        formatter: Math.round
                    }
                }
            },
            data: {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: colors,
                        data: data
                    }
                ]
            }
        });
    };
    DashboardComponent.prototype.runDashboardClientLineChart = function () {
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var dSet = [
            { month: months.slice(new Date().getMonth() - 5)[0], count: 0 },
            { month: months.slice(new Date().getMonth() - 4)[0], count: 0 },
            { month: months.slice(new Date().getMonth() - 3)[0], count: 0 },
            { month: months.slice(new Date().getMonth() - 2)[0], count: 0 },
            { month: months.slice(new Date().getMonth() - 1)[0], count: 0 },
            { month: months.slice(new Date().getMonth())[0], count: 0 }
        ];
        var labels = [
            months.slice(new Date().getMonth() - 5)[0],
            months.slice(new Date().getMonth() - 4)[0],
            months.slice(new Date().getMonth() - 3)[0],
            months.slice(new Date().getMonth() - 2)[0],
            months.slice(new Date().getMonth() - 1)[0],
            months.slice(new Date().getMonth())[0]
        ];
        // get only if not older than a 6 months
        var beforeHalfYear = new Date();
        beforeHalfYear.setMonth(beforeHalfYear.getMonth() - 5);
        this.incidentsData.forEach(function (inc) {
            var d = inc.businessPartnerPropertyIncidentsDate
                ? new Date(inc.businessPartnerPropertyIncidentsDate)
                : new Date('1900');
            if (+new Date(beforeHalfYear) > +new Date(d)) {
                return;
            }
            dSet.forEach(function (ds) {
                if (ds.month === months[d.getMonth()]) {
                    ds.count += 1;
                }
            });
        });
        var data = [
            {
                data: dSet.map(function (ds) { return ds.count; }),
                label: 'Incidents',
                borderColor: '#F37329',
                borderWidth: 2,
                backgroundColor: 'rgba(243, 98, 30, .5)',
                fill: true
            }
        ];
        // console.log(this.incidentsData, dSet, labels);
        var ctx = 'dashboardClient6MonthsIncidentsChart';
        var _chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: data
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: 0.5
                    }
                },
                legend: { display: false },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                suggestedMin: 0,
                                beginAtZero: true
                            }
                        }
                    ]
                }
            }
        });
    };
    return DashboardComponent;
}());
export { DashboardComponent };
