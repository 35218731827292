<div class="aside-wrapper" *ngIf="asideIsOpened" [@slideInOut]="animateState">

  <!-- user -->
  <div class="user-aside" *ngIf="selectedItem === 'user'">
    <div class="ua-info">
      <div class="ua-avatar">
        <img src="./assets/img/user_placeholder.png" />
      </div>
      <div class="ua-details">
        <h4>{{institutionName}}</h4>
        <p>{{userFullName}}, {{userTypeName}}</p>
        <p>{{userEmail}}</p>
      </div>
    </div>
    <div style="width: 100%;">
      <button class="btn btn-default" style="width:100%;margin-bottom: 1em;" (click)="feedback()">Leave feedback</button>
      <button class="btn btn-default" style="width:100%;margin:0 !important" (click)="logoutHandler()">Sign out</button>
    </div>

    <!-- <button class="btn btn-default btn-big" (click)="logoutHandler()">Sign out</button> -->
  </div>

  <!-- notifications -->
  <app-aside-notification *ngIf="selectedItem === 'notifications'"></app-aside-notification>

  <!-- settings -->
  <div class="settings-aside" *ngIf="selectedItem === 'settings'">
    <div class="sa-header">
      <div (click)="switchSettingsTab('tab-general')" [class.active]="activeSettingsTab == 'tab-general'">General</div>
      <div (click)="switchSettingsTab('tab-account')" [class.active]="activeSettingsTab == 'tab-account'">Account</div>
      <div (click)="switchSettingsTab('tab-notification')" [class.active]="activeSettingsTab == 'tab-notification'">Notifications</div>
    </div>
    <div class="sa-content">
      <div *ngIf="activeSettingsTab == 'tab-general'">
        <!-- general content -->
        <!-- <div class="ui-control">
          <span class="ui-box-label">Show code (PRE elements)</span>
          <span class="ui-box">
            <p-checkbox class="box-success" (onChange)="togglePreState()" [label]="togglePre? 'Visible':'Hidden'" [(ngModel)]="togglePre"
              binary="true"></p-checkbox>
          </span>
        </div>

        <div class="ui-control">
          <span class="ui-float-label">
            <p-dropdown [options]="themes" [autoDisplayFirst]="false" [(ngModel)]="selectedTheme" optionLabel="name" (onChange)="onThemeChange($event)"></p-dropdown>
            <label>Choose theme</label>
          </span>
        </div> -->

      </div>
      <div *ngIf="activeSettingsTab == 'tab-account'">
        <!-- account content -->
      </div>
      <div *ngIf="activeSettingsTab == 'tab-notification'">
        <!-- notification content -->
      </div>

    </div>
  </div>

</div>