import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NotificationLoadingService, Notifications } from './notification-loading.service';

@Component({
  selector: 'app-aside-notification',
  templateUrl: './aside-notification.component.html',
  styleUrls: ['./aside-notification.component.scss']
})
export class AsideNotificationComponent implements OnInit, OnDestroy {
  notifications: Notifications[];
  newNotifSub: Subscription;
  constructor(private _notifications: NotificationLoadingService, private router: Router) {}

  ngOnInit() {
    this.loadNotifications();
    this.newNotifSub = this._notifications.refreshNotifications.subscribe(() => {
      this.loadNotifications();
    });
    this._notifications.updateStatus(null, 2);
  }
  ngOnDestroy() {
    this.newNotifSub.unsubscribe();
  }
  loadNotifications() {
    this.notifications = this._notifications.getNotifications();
  }
  openNotification(notification: Notifications) {
    if (notification.userNotificationTypeCode === 1) {
      // Feedback;
      this._notifications.updateStatus(notification.userNotificationCode, 3);
      this.router.navigate([`/feedback/all`, { userFeedbackCode: notification.userNotificationTypeValue }]);
    } else if (notification.userNotificationTypeCode === 3) {
      this._notifications.updateStatus(notification.userNotificationCode, 3);
      this.router.navigate(
        [
          `/client/${notification.userNotificationData.businessPartnerCode}/property/${notification.userNotificationData.businessPartnerPropertyCode}`
        ],
        { fragment: 'incidents&code=' + notification.userNotificationTypeValue }
      );
    }
  }
  getFormatDate(date: string | null): string {
    if (date == null) {
      return null;
    }
    const _date = new Date(date);
    return (
      (_date.getDate() + 1 < 10 ? '0' + (_date.getDate() + 1) : _date.getDate() + 1).toString() +
      '.' +
      (_date.getMonth() + 1 < 10 ? '0' + (_date.getMonth() + 1) : _date.getMonth() + 1).toString() +
      '.' +
      _date.getFullYear().toString() +
      ' ' +
      (_date.getHours() + 1 < 10 ? '0' + (_date.getHours() + 1) : _date.getHours() + 1).toString() +
      ':' +
      (_date.getMinutes() + 1 < 10 ? '0' + (_date.getMinutes() + 1) : _date.getMinutes() + 1).toString()
    );
  }
}
