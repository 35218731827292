import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CodesParsed } from '../interfaces/codes.types';
import { CodesReactiveParserService } from '../services/codes-reactive-parser.service';
import { NotificationService } from '../../../core/notification/notification.service';
import { Database } from '../../../core/services/database';
import { CodesReactiveService } from '../services/codes-reactive.service';

@Component({
  selector: 'app-codes-reactive-form[codesParsedData][rowData][codesTable][allowedActions]',
  templateUrl: './codes-reactive-form.component.html',
  styleUrls: ['./codes-reactive-form.component.scss']
})
export class CodesReactiveFormComponent implements OnInit {
  @Input() codesParsedData: CodesParsed[];
  @Input() rowData: any;
  @Input() codesTable: string;
  @Input() allowedActions: string[];
  @Output() closed: EventEmitter<{ hasInserted: boolean, hasUpdated: boolean }> = new EventEmitter();

  show = true;
  codesReady: boolean;
  codesForm: FormGroup;
  codesFormSubmited: boolean;

  hasInserted: boolean;
  hasUpdated: boolean;

  constructor(
    private fb: FormBuilder,
    private _notif: NotificationService,
    private _database: Database,
    private codesParser: CodesReactiveParserService,
    private codesService: CodesReactiveService
  ) {
    this.codesFormSubmited = false;
    this.hasInserted = false;
    this.hasUpdated = false;
  }

  ngOnInit(): void {
    this.codesParser
      .generateFormGroupControls(this.codesParsedData)
      .then((fgControls: { [key: string]: [any, any[]] }) => {
        this.codesForm = this.fb.group(fgControls);
        if (this.rowData) {
          this.prepareControlsForUpdate();
          return this.prepareRowData();
        }
        this.prepareControlsForInsert();
        this.codesReady = true;
      });
  }
  emmitClose(): void {
    this.closed.emit({ hasInserted: this.hasInserted, hasUpdated: this.hasUpdated });
  }
  prepareRowData(): void {
    const formData = {};
    for (const codesItem of this.codesParsedData) {
      const _key = codesItem.colName;
      if (codesItem.colLS !== -1) {
        formData[_key] = '';
        if (codesItem.inputType === 'select') {
          formData[_key] = this.codesParser.getGatherRowValue(
            this.rowData,
            codesItem
          );
        } else if (codesItem.inputType === 'autocomplete') {
          formData[_key] = this.codesParser.getGatherRowValue(
            this.rowData,
            codesItem
          );
        } else if (
          codesItem.inputType === 'date' ||
          codesItem.inputType === 'datetime' ||
          codesItem.inputType === 'time'
        ) {
          const date = new Date(this.rowData[_key]);
          if (Object.prototype.toString.call(date) === '[object Date]') {
            formData[_key] = isNaN(date.getTime()) ? '' : date;
          }
        } else {
          formData[_key] = this.rowData[_key];
        }
      }
    }
    this.codesForm.patchValue(formData);
    this.codesReady = true;
  }
  prepareControlsForInsert(): void {
    for (const codesItem of this.codesParsedData) {
      const _key = codesItem.colName;
      if (codesItem.readOnlyOnInsert && this.codesForm.get(_key)) {
        this.codesForm.get(_key).disable();
      }
    }
  }
  prepareControlsForUpdate(): void {
    for (const codesItem of this.codesParsedData) {
      const _key = codesItem.colName;
      if ((codesItem.readOnlyOnUpdate || !this.isCodesActionAllowed('UPDATE')) && this.codesForm.get(_key)) {
        this.codesForm.get(_key).disable();
      }
    }
  }
  saveCodesForm(): void {
    this.codesFormSubmited = true;
    if (!this.codesForm.valid) {
      return this._notif.create({
        title: 'Invalid form data',
        message: 'Please check your inputs',
        type: 'warn',
        autoClose: true,
        duration: 3000,
        position: 'top-right'
      });
    }

    const data = this.codesParser.generatePOSTData(
      this.codesForm,
      this.codesParsedData,
      this.rowData ? 'update' : 'insert'
    );

    if (this.rowData) {
      this.codesService.updateCodes(data, this.codesTable).then((res) => {
        if (res) {
          this.hasUpdated = true; this.emmitClose();
        }
      });
    } else {
      this.codesService.insertCodes(data, this.codesTable).then((res) => {
        if (res) {
          this.hasInserted = true; this.emmitClose();
        }
      });
    }
  }
  checkAllowedSaveState(): boolean {
    if (this.rowData) {
      return this.isCodesActionAllowed('UPDATE');
    } else {
      return this.isCodesActionAllowed('INSERT');
    }
  }
  isCodesActionAllowed(action): boolean {
    return this.allowedActions.includes(action);
  }
}
