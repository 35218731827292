import { Directive, ElementRef, OnInit, Input, OnChanges, Renderer } from '@angular/core';

export interface LoadingDirectiveInterface {
  isLoading: boolean;
  text: string;
  show: boolean;
  class: 'default' | 'success' | 'error' | 'warn' | 'info';
}

type Classes = 'default' | 'success' | 'error' | 'warn' | 'info';

@Directive({
  selector: '[appLoadingDirective]'
})
export class LoadingDirectiveDirective implements OnInit, OnChanges {
  @Input() appLoadingDirective: LoadingDirectiveInterface;

  $element;

  constructor(
    private el: ElementRef,
    private renderer: Renderer) {

  }


  ngOnInit() {
    this.$element = this.el.nativeElement;
    this.prepareData();
    this.prepareElement();
  }

  prepareData() {
    if (this.appLoadingDirective === undefined) {
      this.appLoadingDirective = {
        isLoading: true,
        text: null,
        show: true,
        class: 'default'
      };
    } else {
      this.appLoadingDirective.isLoading = this.appLoadingDirective.isLoading === undefined ? true : this.appLoadingDirective.isLoading;
      this.appLoadingDirective.text = this.appLoadingDirective.isLoading === undefined ? '' : this.appLoadingDirective.text;
      this.appLoadingDirective.show = this.appLoadingDirective.show === undefined ? true : this.appLoadingDirective.show;
      this.appLoadingDirective.class = this.appLoadingDirective.class === undefined ? 'default' : this.appLoadingDirective.class;
    }
  }

  prepareElement() {
    this.$element.classList.add('_loadingDirective');
    const loadingContent = `
      <div class="_loadingDirectiveLoaderDiv ` + this.appLoadingDirective.class + `">
        <div class="_loadingDirectiveLoader"></div>
        <div class="_loadingDirectiveLoaderText">` + this.appLoadingDirective.text + `</div>
      </div>
    `;
    this.renderer.invokeElementMethod(this.$element, 'insertAdjacentHTML', ['beforeend', loadingContent]);
    this.updateLoading();
  }

  ngOnChanges(changes: any) {
    if (changes.appLoadingDirective.currentValue == null || changes.appLoadingDirective.currentValue === undefined) {
      this.removeLoading();
    } else if (changes.appLoadingDirective.currentValue !== changes.appLoadingDirective.previousValue) {
      this.updateLoading();
    }
  }

  updateLoading() {
    if (this.$element) {
      this.$element.classList.remove('default');
      this.$element.classList.remove('success');
      this.$element.classList.remove('error');
      this.$element.classList.remove('warn');
      this.$element.classList.remove('info');
      this.$element.classList.add(this.appLoadingDirective.class);

      // If it is displayed
      if (this.appLoadingDirective.show) {
        this.$element.classList.add('_loadingDirectiveShow');
      } else {
        this.$element.classList.remove('_loadingDirectiveShow');
      }

      // If it is showing loader
      if (this.appLoadingDirective.isLoading) {
        this.$element.classList.add('_loadingDirectiveShowLoader');
      } else {
        this.$element.classList.remove('_loadingDirectiveShowLoader');
      }

      const $block = this.$element.querySelector('._loadingDirectiveLoaderText');
      if ($block && $block !== undefined) {
        $block.innerHTML = this.appLoadingDirective.text;
      }

    }

  }

  removeLoading() {
    if (this.$element) {
      this.$element.classList.remove('default');
      this.$element.classList.remove('success');
      this.$element.classList.remove('error');
      this.$element.classList.remove('warn');
      this.$element.classList.remove('info');
      this.$element.classList.remove('_loadingDirectiveShow');
      this.$element.classList.remove('_loadingDirectiveShowLoader');
      this.$element.classList.remove('_loadingDirective');
      if (this.$element.querySelector('._loadingDirectiveLoaderDiv')) {
        this.$element.querySelector('._loadingDirectiveLoaderDiv').remove();
      }
    }
  }

}
