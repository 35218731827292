<div class="notifications-aside">
  <div class="content-header">
    <h3 class="content-title">
      Notification Center
    </h3>
  </div>
  <div class="na-item-list">
    <!-- notification item -->
    <div class="na-item" *ngFor="let notification of notifications; let i = index" [class.new-notification]="notification.userNotificationStatusCode != 3 && notification.userNotificationStatusCode != 5">
      <div class="na-details" *ngIf="notification.userNotificationTypeCode === 1 || notification.userNotificationTypeCode === 3"
        (click)="openNotification(notification)">
        <p>
          <strong>
            {{notification.userNotificationText}}
            <i class="fa fa-check" style="color:#42CD41" aria-hidden="true" *ngIf="notification.userNotificationStatusCode === 3"></i>
            <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="notification.userNotificationStatusCode != 3 && notification.userNotificationStatusCode != 5"></i>
          </strong>
          <!-- <i>{{'"'+ notification. +'"'}}</i> -->
        </p>
        <small>{{getFormatDate(notification.createDateTime)}}</small>
      </div>
    </div>
  </div>
</div>