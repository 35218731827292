<p-table #tt
  [columns]="columns"
  [value]="data"
  [rows]="showingOptions.showingPerPage.limit"
  [paginator]="!options || hasOption('p')"
  [rowsPerPageOptions]="!options || hasOption('r')?showingOptions.showingPerPageOptions:null"
  [paginatorDropdownAppendTo]="'body'"
  [styleClass]="(styleClass?styleClass:'') + ' ' + (!options || hasOption('p')?'has-paginator':'') + ' ' + (!lines || lines === 'multiple'?'multiple-lines':'')"
  [responsive]="true"
  [selectionMode]="'single'"
  [scrollable]="true"
  [resizableColumns]="true" 
  [columnResizeMode]="'expand'"
  [appLoadingDirective]="loading?loading:loadingStatus"
  (onFilter)="onFilter($event)"
  (onPage)="onPage($event)"
>

  <!-- TABLE CAPTION GLOBAL FILTER -->
  <ng-template pTemplate="caption">

    <c-header [title]="title">
      <ng-container right>
        <ng-content></ng-content>
        <button class="btn btn-default" (click)="tt.exportCSV()" *ngIf="hasOption('e')">
          <span [appIcon]="'tt-excel'"></span>
          Excel
        </button>

        <div class="ui-control" *ngIf="hasOption('v')">
          <p-multiSelect
            [options]="selectedColumns"
            [(ngModel)]="columns"
            [optionLabel]="'header'" 
            [defaultLabel]="'Choose Columns'"
            [selectedItemsLabel]="'{0} columns selected'"
            [style]="{minWidth: '200px', maxWidth: '200px'}"
            [panelStyle]="{minWidth: '200px', maxWidth: '200px', textTransform: 'uppercase'}"
            [resetFilterOnHide]="true"
            [appendTo]="'body'"
          ></p-multiSelect>
        </div>

        <div class="search-box" *ngIf="!options || hasOption('f')">
          <span [appIcon]="'tt-search'"></span>
          <input type="text" placeholder="Search" (input)="tt.filterGlobal($event.target.value, 'contains')" />
        </div>
      </ng-container>
    </c-header>
    
  </ng-template>

  <!-- NEEDED FOR RESIZALE COLUMN AND SCROLLABLE -->
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style.width]="col.width">
    </colgroup>
  </ng-template>

  <!-- TABLE HEAD -->
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns; let colIdx = index" [title]="col.header" [pSortableColumn]="col.field" pResizableColumn>
        <p-sortIcon [field]="col.field"></p-sortIcon>
        {{ col.header | uppercase }}
      </th>
      
      <th *ngIf="rowOptions && rowOptions.length" style="width: 80px"></th>
    </tr>
  </ng-template>

  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns" (click)="onRowSelect($event, rowData)">
        {{ dateFields.includes(col.field) === true ? (rowData[col.field] | date: getDateFormat(col.field)) : (rowData[col.field])  }}
      </td>
      
      <td *ngIf="rowOptions && rowOptions.length" class="text-right" style="width: 80px">
        <button class="btn" (click)="rowOptionHandler(tableOverlayPanel, $event, rowData)">
          <span [appIcon]="'tt-more'"></span>
        </button>
      </td>
    </tr>
  </ng-template>

  <!-- NO DATA MESSAGE  -->
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td style="text-align: center;" [attr.colspan]="columns.length">
        No records found
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft" *ngIf="!options || hasOption('n')">
    <p>Showing {{ showingOptions.showing.start ? showingOptions.showing.start : 0 }} to {{ showingOptions.showing.end ? showingOptions.showing.end : 0 }} of {{ showingOptions.showing.total ? showingOptions.showing.total : 0 }} entries</p>
    <!-- <ng-container *ngIf="!options || hasOption('r')">
      <label class="hidden-md">Show</label>
      <div class="ui-control">
        <p-dropdown
          [options]="showingOptions.showingPerPageOptions"
          [(ngModel)]="showingOptions.showingPerPage"
          [optionLabel]="'limit'"
          [autoDisplayFirst]="false"
          [style]="{minWidth: '60px'}"
          [appendTo]="'body'"
          (onChange)="generateShowingHandler($event, 'limit')">
        </p-dropdown>
      </div>
    </ng-container> -->
  </ng-template>

</p-table>


<p-overlayPanel [appendTo]="'body'" [showCloseIcon]="true" #tableOverlayPanel>
  <div class="panel-option">
    <button *ngFor="let b of rowOptions" class="btn" (click)="b.action(rowOptionSelectedData); tableOverlayPanel.hide()">
      <span *ngIf="b.icon" [appIcon]="b.icon"></span>
      {{b.text}}
    </button>
  </div>
</p-overlayPanel>