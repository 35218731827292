<div class="app-subheader-wrapper">
  <app-subheader [title]="displayTableName" [back]="true"></app-subheader>
</div>

<div class="content" [appLoadingDirective]="loadingStatus"> 
  <div class="content-inner hide-scroll" no-padding>

    <app-datatable
      [title]="displayTableName"
      [columns]="codesTableHeader"
      [data]="codesTableData"
      [limit]="20"
      [options]="'pfrein'"
      [lines]="'single'"
      [rowOptions]="rowOptions"
      (rowSelect)="onRowSelect($event)">
      <button class="btn btn-default" (click)="newCodesRow()" *ngIf="isCodesActionAllowed('INSERT')">New item</button>
      <button class="btn btn-default" (click)="editDisplaySettings()">Display settings</button>
    </app-datatable>

  </div>
</div>

<app-codes-reactive-form *ngIf="addEditCodes" (closed)="addEditCodesClosed($event)" [rowData]="addEditCodesRowData" [codesParsedData]="codesParsedData"
  [codesTable]="codesTable" [allowedActions]="allowedActions"></app-codes-reactive-form>
<app-codes-reactive-display-settings *ngIf="editCodesDisplay" [codesParsedData]="codesParsedData" [codesTable]="codesTable"
  (closed)="editDisplaySettingsClosed($event)"></app-codes-reactive-display-settings>