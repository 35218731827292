/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/directives/icons/icons.directive";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/directives/ripple/ripple.directive";
import * as i5 from "./notification.component";
import * as i6 from "./notification.service";
var styles_NotificationComponent = [i0.styles];
var RenderType_NotificationComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NotificationComponent, data: { "animation": [{ type: 7, name: "notificationState", definitions: [{ type: 0, name: "inactive", styles: { type: 6, styles: { opacity: "0" }, offset: null }, options: undefined }, { type: 0, name: "active", styles: { type: 6, styles: { opacity: "1" }, offset: null }, options: undefined }, { type: 1, expr: "inactive => active", animation: { type: 4, styles: null, timings: "100ms ease-in" }, options: null }, { type: 1, expr: "active => inactive", animation: { type: 4, styles: null, timings: "100ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_NotificationComponent as RenderType_NotificationComponent };
function View_NotificationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.IconsDirective, [i1.ElementRef, i1.Renderer], { appIcon: [0, "appIcon"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NotificationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [], [[8, "name", 0], [8, "type", 0], [8, "value", 0], [8, "placeholder", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; var currVal_1 = (_v.context.$implicit.type ? _v.context.$implicit.type : "text"); var currVal_2 = (_v.context.$implicit.value ? _v.context.$implicit.value : ""); var currVal_3 = (_v.context.$implicit.placeholder ? _v.context.$implicit.placeholder : ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_NotificationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "notification-inputs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_5)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.inputs; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NotificationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buttonHandler(_v.parent.parent.context.$implicit, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i4.RippleDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.text; _ck(_v, 2, 0, currVal_0); }); }
function View_NotificationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "notification-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_7)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.buttons; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], [[24, "@notificationState", 0]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.mouseEnter(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.mouseLeave(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_3)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "notification-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "notification-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "notification-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_4)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_6)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.context.$implicit.type ? ("notification notification-" + _v.context.$implicit.type) : "notification"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.icon; _ck(_v, 3, 0, currVal_2); var currVal_5 = _v.context.$implicit.inputs.length; _ck(_v, 10, 0, currVal_5); var currVal_6 = _v.context.$implicit.buttons.length; _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.title; _ck(_v, 6, 0, currVal_3); var currVal_4 = _v.context.$implicit.message; _ck(_v, 8, 0, currVal_4); }); }
function View_NotificationComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "notification-backdrop"]], null, null, null, null, null))], null, null); }
function View_NotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_8)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.NOTIFICATIONS; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.NOTIFICATIONS[0].backdrop; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_NotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.NOTIFICATIONS.length > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification", [], null, null, null, View_NotificationComponent_0, RenderType_NotificationComponent)), i1.ɵdid(1, 245760, null, 0, i5.NotificationComponent, [i6.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationComponentNgFactory = i1.ɵccf("app-notification", i5.NotificationComponent, View_NotificationComponent_Host_0, {}, {}, []);
export { NotificationComponentNgFactory as NotificationComponentNgFactory };
