import { Injectable } from '@angular/core';
import { StorageService } from './storage-service';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserStateService } from './userstate.service';


@Injectable()
export class PermissionGuard implements CanActivate {

    constructor(private user: UserStateService, private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const roles = next.routeConfig.data.canActivateRole;
        if (roles) {
            return this.user.getUserDataAsync().then((userData: any) => {
                if (roles.includes(userData.userTypeName)) {
                    return true;
                } else {
                    if (state.url === '/dashboard' && (userData.userTypeName === 'Client' || userData.userTypeName === 'User')) {
                        this.router.navigate(['/property']);
                        return false;
                    }
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            });
        } else {
            return true;
        }
    }
}
