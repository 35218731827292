import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { OverlayPanel } from 'primeng/overlaypanel';
import { Database } from '../../../core/services/database';
import { NotificationService } from '../../../core/notification/notification.service';
import { UserStateService } from '../../../core/services/userstate.service';

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss']
})
export class UserAccessComponent implements OnInit {
  myUserData = {};
  userCode = null;
  userFullName = null;
  userEmail = null;

  loadingStatus = { isLoading: true, text: 'Loading', show: true, class: 'default' };
  loadingFirstLevelStatus = { isLoading: true, text: 'Loading', show: true, class: 'default' };
  loadingSecondLevelStatus = { isLoading: true, text: 'Loading', show: true, class: 'default' };
  loadingThirdLevelStatus = { isLoading: true, text: 'Loading', show: true, class: 'default' };

  userStatus: { userStatusCode: string; userStatusName: string }[];
  userModules: { userModuleCode: number; userModuleName: string }[];
  userModuleModel = null;
  addNewModuleView = false;

  postType: { postTypeCode: number; postTypeName: string }[];
  postTypeModel = null;
  addNewPostTypeView = false;
  addNewPostTypeForIndex = null;

  postTypeCategory: { postTypeCategoryCode: number; postTypeCategoryName: string }[];
  postTypeCategoryModel = null;
  addNewPostTypeCategoryView = false;
  addNewPostTypeCategoryData: { module: any; moduleIndex: any; postType: any; postTypeIndex: any };

  userData = [];
  activeUserAccess = { data: null, index: null, moduleData: [] };

  activeDropdownOptionsData = {};

  modulesWithDetails = [
    {
      userModuleCode: 1
    }
  ];

  userAccess = [];
  userAccessModel = null;
  activeAccessDropdownOptionsData = { userAccess: null, index: null };

  // adding/Inviting new user permision group
  institutionUserGroupValues = [];
  userTypes: { userTypeCode: number; userTypeName: string }[];
  allowedUserTypes: { userTypeCode: number; userTypeName: string }[];
  addNewUserGroupView = false;
  inviteUserDataForUser = {
    userType: null,
    userGroup: null,
    userGroupValue: null
  };

  institutionUserGroup: {
    userGroupCode: string;
    userGroupName: string;
    userGroupTableName: string;
    userGroupCodeColumnName: string;
    userGroupNameColumnName: string;
    userGroupGatherName: string;
  }[];

  availableProperties = [];
  selectedPropertyCodes: number[] = [];

  constructor(
    private _database: Database,
    private _notif: NotificationService,
    private user: UserStateService,
    private activatedRoute: ActivatedRoute
  ) {
    this.userStatus = [];
    this.userModules = [];
    this.institutionUserGroup = [];
  }

  ngOnInit() {
    this.myUserData = this.user.getUserData();
    this.loadGathers();
    this.activatedRoute.params.subscribe(param => {
      this.userCode = param['userCode'];
      this.userFullName = param['userFullName'] === undefined ? null : param['userFullName'];
      this.userEmail = param['userEmail'] === undefined ? null : param['userEmail'];
      this.loadUserData();
    });
  }

  loadGathers() {
    this._database.executeRequest('/api/userStatusGather', 'get', {}).then((userStatusResponse: any) => {
      this.userStatus = userStatusResponse.data;
    });
    this._database.executeRequest('/api/userTypeGather', 'get', {}).then((userTypesResponse: any) => {
      this.userTypes = userTypesResponse.data;
    });
    this._database.executeRequest('/api/userAccessGather', 'get', {}).then((accessData: any) => {
      this.userAccess = accessData.data;
    });
    this._database
      .executeRequest('/api/institutionUserGroupGather', 'get', {})
      .then((institutionUserGroupResponse: any) => {
        this.institutionUserGroup = institutionUserGroupResponse.data;
      });
  }

  loadProperties() {
    const businessPartnerCode = this.activeUserAccess.data.userGroupValue;
    this._database
      .executeRequest(`/api/businessPartner/${businessPartnerCode}/property`, 'get', {})
      .then((response: any) => {
        this.availableProperties = response.data;
      });

    this._database.executeRequest(`/api/userSingle/${this.userCode}/properties`, 'get', {}).then((response: any) => {
      console.log('DATA', response.data);
      this.selectedPropertyCodes = response.data.map(i => i.businessPartnerPropertyCode);
    });
  }

  loadUserData() {
    this.loadingStatus = { isLoading: true, text: 'Loading', show: true, class: 'default' };
    this._database.executeRequest('/api/userSingle/' + this.userCode + '/institutionAccess', 'get', {}).then(
      (userAccess: any) => {
        if (userAccess.success === true) {
          this.userData = userAccess.data;
          if (this.activeUserAccess.index === null && this.userData.length > 0) {
            this.updateActiveLevel(this.userData[0], 0);
            this.userFullName = this.userData[0].userFullName === '' ? null : this.userData[0].userFullName;
            this.userEmail = this.userData[0].userEmail === '' ? null : this.userData[0].userEmail;

            // NOT Admin, load properties
            if (this.activeUserAccess.data.userTypeCode !== 1) {
              this.loadProperties();
            }
          }
          this.loadingStatus = { isLoading: false, text: '', show: false, class: 'default' };
        } else {
          this.loadingStatus = {
            isLoading: false,
            text: 'Server error, please try again later',
            show: true,
            class: 'error'
          };
        }
      },
      err => {
        this.loadingStatus = {
          isLoading: false,
          text: 'Server error, please try again later',
          show: true,
          class: 'error'
        };
      }
    );
  }

  loadModules(forIndex) {
    this.activeUserAccess = { data: this.userData[forIndex], index: forIndex, moduleData: [] };
    this.loadingFirstLevelStatus = { isLoading: true, text: 'Loading', show: true, class: 'default' };
    const data = {
      userTypeCode: this.activeUserAccess.data.userTypeCode,
      userGroupCode: this.activeUserAccess.data.userGroupCode === '' ? null : this.activeUserAccess.data.userGroupCode,
      userGroupValue:
        this.activeUserAccess.data.userGroupValue === '' ? null : this.activeUserAccess.data.userGroupValue
    };

    this._database.executeRequest('/api/userSingle/' + this.userCode + '/institutionAccess/modules', 'post', data).then(
      (userAccessModules: any) => {
        if (userAccessModules.success === true) {
          this.activeUserAccess.moduleData = [];
          for (const _row of userAccessModules.data) {
            if (this.moduleHasDetails(_row)) {
              _row['hasDetails'] = true;
              _row['postTypes'] = [];
              _row['openDetails'] = false;
              this.activeUserAccess.moduleData.push(_row);
            } else {
              _row['hasDetails'] = false;
              _row['openDetails'] = false;
              this.activeUserAccess.moduleData.push(_row);
            }
          }
          this.loadingFirstLevelStatus = { isLoading: false, text: '', show: false, class: 'default' };
        } else {
          this.loadingFirstLevelStatus = {
            isLoading: false,
            text: 'Server error, please try again later',
            show: true,
            class: 'error'
          };
        }
      },
      err => {
        this.loadingFirstLevelStatus = {
          isLoading: false,
          text: 'Server error, please try again later',
          show: true,
          class: 'error'
        };
      }
    );
  }

  updateActiveLevel(access, index) {
    if (this.activeUserAccess.index !== index) {
      this.loadModules(index);
    }
  }

  moduleHasDetails(_module) {
    const filtered = this.modulesWithDetails.filter(data => {
      return data.userModuleCode === _module.userModuleCode;
    });
    return filtered.length > 0 ? true : false;
  }

  openModulePostTypes(_module, index) {
    // Post
    if (_module.userModuleCode === 1) {
      if (this.activeUserAccess.moduleData[index].openDetails === true) {
        this.activeUserAccess.moduleData[index].openDetails = false;
      } else {
        this.loadPostTypes(_module, index);
      }
    }
  }

  loadPostTypes(_module, index) {
    this.activeUserAccess.moduleData[index].openDetails = true;
    this.loadingSecondLevelStatus = { isLoading: true, text: 'Loading', show: true, class: 'default' };
    const data = {
      userTypeCode: this.activeUserAccess.data.userTypeCode,
      userGroupCode: this.activeUserAccess.data.userGroupCode === '' ? null : this.activeUserAccess.data.userGroupCode,
      userGroupValue:
        this.activeUserAccess.data.userGroupValue === '' ? null : this.activeUserAccess.data.userGroupValue
    };
    this._database
      .executeRequest('/api/userSingle/' + this.userCode + '/institutionAccess/modules/postTypes', 'post', data)
      .then(
        (types: any) => {
          if (types.success === true) {
            this.activeUserAccess.moduleData[index].postTypes = [];
            for (const type of types.data) {
              type['hasDetails'] = true;
              type['openDetails'] = false;
              type['postTypeCategories'] = [];
              this.activeUserAccess.moduleData[index].postTypes.push(type);
            }
            this.loadingSecondLevelStatus = { isLoading: false, text: '', show: false, class: 'default' };
          } else {
            this.loadingSecondLevelStatus = {
              isLoading: false,
              text: 'Server error, please try again later',
              show: true,
              class: 'error'
            };
          }
        },
        err => {
          this.loadingSecondLevelStatus = {
            isLoading: false,
            text: 'Server error, please try again later',
            show: true,
            class: 'error'
          };
        }
      );
  }

  openModuleTypeDetails(_module, index, _modulePostType, detailIndex) {
    if (_module.userModuleCode === 1) {
      if (this.activeUserAccess.moduleData[index].postTypes[detailIndex].openDetails === true) {
        this.activeUserAccess.moduleData[index].postTypes[detailIndex].openDetails = false;
      } else {
        this.loadPostTypeCategories(_module, index, _modulePostType, detailIndex);
      }
    }
  }

  loadPostTypeCategories(_module, index, _modulePostType, detailIndex) {
    this.activeUserAccess.moduleData[index].postTypes[detailIndex].openDetails = true;
    this.loadingThirdLevelStatus = { isLoading: true, text: 'Loading', show: true, class: 'default' };
    const data = {
      userTypeCode: this.activeUserAccess.data.userTypeCode,
      userGroupCode: this.activeUserAccess.data.userGroupCode === '' ? null : this.activeUserAccess.data.userGroupCode,
      userGroupValue:
        this.activeUserAccess.data.userGroupValue === '' ? null : this.activeUserAccess.data.userGroupValue
    };
    this._database
      .executeRequest(
        '/api/userSingle/' +
          this.userCode +
          '/institutionAccess/modules/postTypes/' +
          _modulePostType.postTypeCode +
          '/category',
        'post',
        data
      )
      .then(
        (categories: any) => {
          if (categories.success === true) {
            this.activeUserAccess.moduleData[index].postTypes[detailIndex].postTypeCategories = categories.data;
            this.loadingThirdLevelStatus = { isLoading: false, text: '', show: false, class: 'default' };
          } else {
            this.loadingThirdLevelStatus = {
              isLoading: false,
              text: 'Server error, please try again later',
              show: true,
              class: 'error'
            };
          }
        },
        err => {
          this.loadingThirdLevelStatus = {
            isLoading: false,
            text: 'Server error, please try again later',
            show: true,
            class: 'error'
          };
        }
      );
  }

  showOptions($ev, data, overlay: OverlayPanel) {
    this.activeDropdownOptionsData = data;
    overlay.toggle($ev);
  }

  deleteOption(data) {
    if (data.modulePostTypeCategory) {
      this.deleteModulePostTypeCategory(data);
    } else if (data.modulePostType) {
      this.deleteModulePostType(data);
    } else {
      this.deleteModule(data);
    }
  }

  serverError() {
    this._notif.create({
      title: 'There was an error',
      message: 'Please try again later.',
      type: 'error',
      autoClose: true,
      duration: 3000,
      position: 'top-right'
    });
  }

  deleteModulePostTypeCategory(data) {
    this._notif.create({
      title: 'Delete post type category?',
      message: 'This will permanently delete the record.',
      type: 'info',
      autoClose: false,
      backdrop: true,
      position: 'center-center',
      buttons: [
        {
          text: 'Delete',
          action: _data => {
            const postData = {
              userTypeCode: this.activeUserAccess.data.userTypeCode,
              userGroupCode:
                this.activeUserAccess.data.userGroupCode === '' ? null : this.activeUserAccess.data.userGroupCode,
              userGroupValue:
                this.activeUserAccess.data.userGroupValue === '' ? null : this.activeUserAccess.data.userGroupValue
            };
            this._database
              .executeRequest(
                '/api/userSingle/' +
                  this.userCode +
                  '/institutionAccess/modules/postTypes/' +
                  data.modulePostType.postTypeCode +
                  '/category/' +
                  data.modulePostTypeCategory.postTypeCategoryInstitutionUserAccessCode +
                  '/delete',
                'post',
                postData
              )
              .then(
                (response: any) => {
                  if (response.success === true) {
                    this._notif.create({
                      title: 'Success',
                      message: 'Post type category deleted',
                      type: 'success',
                      autoClose: true,
                      duration: 3000,
                      position: 'top-right'
                    });
                    this.activeUserAccess.moduleData[data.moduleIndex].postTypes[
                      data.modulePostTypeIndex
                    ].postTypeCategories.splice(data.modulePostTypeCategoryIndex, 1);
                  } else {
                    this.serverError();
                  }
                },
                err => {
                  this.serverError();
                }
              );
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          action: () => {}
        }
      ]
    });
  }

  deleteModulePostType(data) {
    this._notif.create({
      title: 'Delete post type?',
      message: 'This will permanently delete the record.',
      type: 'info',
      autoClose: false,
      backdrop: true,
      position: 'center-center',
      buttons: [
        {
          text: 'Delete',
          action: _data => {
            const postData = {
              userTypeCode: this.activeUserAccess.data.userTypeCode,
              userGroupCode:
                this.activeUserAccess.data.userGroupCode === '' ? null : this.activeUserAccess.data.userGroupCode,
              userGroupValue:
                this.activeUserAccess.data.userGroupValue === '' ? null : this.activeUserAccess.data.userGroupValue,
              postTypeInstitutionUserAccessCode: data.modulePostType.postTypeInstitutionUserAccessCode
            };
            this._database
              .executeRequest(
                '/api/userSingle/' +
                  this.userCode +
                  '/institutionAccess/modules/postTypes/' +
                  data.modulePostType.postTypeCode +
                  '/delete',
                'post',
                postData
              )
              .then(
                (response: any) => {
                  if (response.success === true) {
                    this._notif.create({
                      title: 'Success',
                      message: 'Post type deleted',
                      type: 'success',
                      autoClose: true,
                      duration: 3000,
                      position: 'top-right'
                    });
                    this.activeUserAccess.moduleData[data.moduleIndex].postTypes.splice(
                      data.modulePostTypeCategoryIndex,
                      1
                    );
                  } else {
                    this.serverError();
                  }
                },
                err => {
                  this.serverError();
                }
              );
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          action: () => {}
        }
      ]
    });
  }

  deleteModule(data) {
    this._notif.create({
      title: 'Delete module?',
      message: 'This will permanently delete the record.',
      type: 'info',
      autoClose: false,
      backdrop: true,
      position: 'center-center',
      buttons: [
        {
          text: 'Delete',
          action: _data => {
            const postData = {
              userTypeCode: this.activeUserAccess.data.userTypeCode,
              userGroupCode:
                this.activeUserAccess.data.userGroupCode === '' ? null : this.activeUserAccess.data.userGroupCode,
              userGroupValue:
                this.activeUserAccess.data.userGroupValue === '' ? null : this.activeUserAccess.data.userGroupValue,
              userSingleInstitutionModuleCode: data.module.userSingleInstitutionModuleCode
            };
            this._database
              .executeRequest(
                '/api/userSingle/' +
                  this.userCode +
                  '/institutionAccess/modules/' +
                  data.module.userModuleCode +
                  '/delete',
                'post',
                postData
              )
              .then(
                (response: any) => {
                  if (response.success === true) {
                    this._notif.create({
                      title: 'Success',
                      message: 'Module deleted',
                      type: 'success',
                      autoClose: true,
                      duration: 3000,
                      position: 'top-right'
                    });
                    this.activeUserAccess.moduleData.splice(data.moduleIndex, 1);
                  } else {
                    this.serverError();
                  }
                },
                err => {
                  this.serverError();
                }
              );
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          action: () => {}
        }
      ]
    });
  }

  addNewModule() {
    const postData = {
      userTypeCode: this.activeUserAccess.data.userTypeCode,
      userGroupCode: this.activeUserAccess.data.userGroupCode === '' ? null : this.activeUserAccess.data.userGroupCode,
      userGroupValue:
        this.activeUserAccess.data.userGroupValue === '' ? null : this.activeUserAccess.data.userGroupValue
    };
    this._database
      .executeRequest('/api/userSingle/' + this.userCode + '/institutionAccess/modules/gather/all', 'post', postData)
      .then(
        (response: any) => {
          if (response.success === true) {
            if (response.data.length === 0) {
              this._notif.create({
                title: 'Notice',
                message: 'User already has all modules assigned.',
                type: 'success',
                autoClose: true,
                duration: 3000,
                position: 'top-right'
              });
            } else {
              this.userModules = response.data;
              this.userModuleModel = null;
              this.userAccessModel = null;
              this.addNewModuleView = true;
            }
          } else {
            this.serverError();
          }
        },
        err => {
          this.serverError();
        }
      );
  }

  saveNewUserModule() {
    const data = {
      userTypeCode: this.activeUserAccess.data.userTypeCode,
      userGroupCode: this.activeUserAccess.data.userGroupCode === '' ? null : this.activeUserAccess.data.userGroupCode,
      userGroupValue:
        this.activeUserAccess.data.userGroupValue === '' ? null : this.activeUserAccess.data.userGroupValue,
      userGroupValueName:
        this.activeUserAccess.data.userGroupValueName === '' ? null : this.activeUserAccess.data.userGroupValueName,
      userAccessCode: this.userAccessModel.userAccessCode,
      userModuleCode: this.userModuleModel.userModuleCode
    };

    this._database.executeRequest('/api/userSingle/' + this.userCode + '/institutionAccess/modules', 'put', data).then(
      (response: any) => {
        if (response.success === true) {
          this.loadModules(this.activeUserAccess.index);
          this.userModuleModel = null;
          this.userAccessModel = null;
          this.addNewModuleView = false;
        } else {
          this.serverError();
        }
      },
      err => {
        this.serverError();
      }
    );
  }

  addNewModuleType(index) {
    const postData = {
      userTypeCode: this.activeUserAccess.data.userTypeCode,
      userGroupCode: this.activeUserAccess.data.userGroupCode === '' ? null : this.activeUserAccess.data.userGroupCode,
      userGroupValue:
        this.activeUserAccess.data.userGroupValue === '' ? null : this.activeUserAccess.data.userGroupValue
    };
    this._database
      .executeRequest(
        '/api/userSingle/' + this.userCode + '/institutionAccess/modules/postTypes/gather/all',
        'post',
        postData
      )
      .then(
        (response: any) => {
          if (response.success === true) {
            if (response.data.length === 0) {
              this._notif.create({
                title: 'Notice',
                message: 'All types already assigned.',
                type: 'success',
                autoClose: true,
                duration: 3000,
                position: 'top-right'
              });
            } else {
              this.postType = response.data;
              this.postTypeModel = null;
              this.userAccessModel = null;
              this.addNewPostTypeView = true;
              this.addNewPostTypeForIndex = index;
            }
          } else {
            this.serverError();
          }
        },
        err => {
          this.serverError();
        }
      );
  }

  saveNewUserModuleType() {
    const data = {
      userTypeCode: this.activeUserAccess.data.userTypeCode,
      userGroupCode: this.activeUserAccess.data.userGroupCode === '' ? null : this.activeUserAccess.data.userGroupCode,
      userGroupValue:
        this.activeUserAccess.data.userGroupValue === '' ? null : this.activeUserAccess.data.userGroupValue,
      userGroupValueName:
        this.activeUserAccess.data.userGroupValueName === '' ? null : this.activeUserAccess.data.userGroupValueName,
      userAccessCode: this.userAccessModel.userAccessCode,
      postTypeCode: this.postTypeModel.postTypeCode
    };

    this._database
      .executeRequest('/api/userSingle/' + this.userCode + '/institutionAccess/modules/postTypes', 'put', data)
      .then(
        (response: any) => {
          if (response.success === true) {
            this.loadPostTypes(null, this.addNewPostTypeForIndex);
            this.postTypeModel = null;
            this.userAccessModel = null;
            this.addNewPostTypeView = false;
            this.addNewPostTypeForIndex = null;
          } else {
            this.serverError();
          }
        },
        err => {
          this.serverError();
        }
      );
  }

  addNewModuleTypeCategory(_module, moduleIndex, postType, postTypeIndex) {
    const postData = {
      userTypeCode: this.activeUserAccess.data.userTypeCode,
      userGroupCode: this.activeUserAccess.data.userGroupCode === '' ? null : this.activeUserAccess.data.userGroupCode,
      userGroupValue:
        this.activeUserAccess.data.userGroupValue === '' ? null : this.activeUserAccess.data.userGroupValue
    };
    this._database
      .executeRequest(
        '/api/userSingle/' +
          this.userCode +
          '/institutionAccess/modules/postTypes/' +
          postType.postTypeCode +
          '/category/gather/all',
        'post',
        postData
      )
      .then(
        (response: any) => {
          if (response.success === true) {
            if (response.data.length === 0) {
              this._notif.create({
                title: 'Notice',
                message: 'All type categories already assigned.',
                type: 'success',
                autoClose: true,
                duration: 3000,
                position: 'top-right'
              });
            } else {
              this.postTypeCategory = response.data;
              this.postTypeCategoryModel = null;
              this.userAccessModel = null;
              this.addNewPostTypeCategoryView = true;
              this.addNewPostTypeCategoryData = {
                module: _module,
                moduleIndex: moduleIndex,
                postType: postType,
                postTypeIndex: postTypeIndex
              };
            }
          } else {
            this.serverError();
          }
        },
        err => {
          this.serverError();
        }
      );
  }

  saveNewUserModuleTypeCategory() {
    const data = {
      userTypeCode: this.activeUserAccess.data.userTypeCode,
      userGroupCode: this.activeUserAccess.data.userGroupCode === '' ? null : this.activeUserAccess.data.userGroupCode,
      userGroupValue:
        this.activeUserAccess.data.userGroupValue === '' ? null : this.activeUserAccess.data.userGroupValue,
      userGroupValueName:
        this.activeUserAccess.data.userGroupValueName === '' ? null : this.activeUserAccess.data.userGroupValueName,
      userAccessCode: this.userAccessModel.userAccessCode,
      postTypeCategoryCode: this.postTypeCategoryModel.postTypeCategoryCode
    };
    this._database
      .executeRequest(
        '/api/userSingle/' +
          this.userCode +
          '/institutionAccess/modules/postTypes/' +
          this.addNewPostTypeCategoryData.postType.postTypeCode +
          '/category',
        'put',
        data
      )
      .then(
        (response: any) => {
          if (response.success === true) {
            this.loadPostTypeCategories(
              this.addNewPostTypeCategoryData.module,
              this.addNewPostTypeCategoryData.moduleIndex,
              this.addNewPostTypeCategoryData.postType,
              this.addNewPostTypeCategoryData.postTypeIndex
            );
            this.postTypeCategory = [];
            this.postTypeCategoryModel = null;
            this.userAccessModel = null;
            this.addNewPostTypeCategoryView = false;
            this.addNewPostTypeCategoryData = {
              module: {},
              moduleIndex: null,
              postType: {},
              postTypeIndex: null
            };
          } else {
            this.serverError();
          }
        },
        err => {
          this.serverError();
        }
      );
  }

  addNewUserGroup() {
    const _index = this.userData.findIndex(value => {
      return value.userTypeCode === 1 || value.userTypeCode === '1';
    });

    if (_index === -1) {
      this.allowedUserTypes = this.userTypes;
    } else {
      this.allowedUserTypes = this.userTypes.filter(value => {
        return value.userTypeCode !== 1;
      });
    }

    this.inviteUserDataForUser = {
      userType: null,
      userGroup: null,
      userGroupValue: null
    };
    this.addNewUserGroupView = true;
  }

  userTypeChangedForUser() {
    this.inviteUserDataForUser.userGroup = null;
    this.inviteUserDataForUser.userGroupValue = null;
    if (
      this.institutionUserGroup.length === 1 &&
      this.inviteUserDataForUser.userType !== null &&
      this.inviteUserDataForUser.userType.userTypeCode !== 1
    ) {
      this.inviteUserDataForUser.userGroup = this.institutionUserGroup[0];
      this.userGroupChangedForUser();
    }
  }
  userGroupChangedForUser() {
    this._database
      .executeRequest(
        '/api/' +
          this.inviteUserDataForUser.userGroup.userGroupGatherName +
          '/' +
          this.userCode +
          '/' +
          this.inviteUserDataForUser.userType.userTypeCode,
        'get',
        {}
      )
      .then((response: any) => {
        this.institutionUserGroupValues = response.data;
      });
  }

  inviteNewUserGroupConfirm() {
    // /api/userSingle/:userCode/invite
    const data = {
      userEmail: null,
      userTypeCode:
        this.inviteUserDataForUser.userType == null ? null : this.inviteUserDataForUser.userType.userTypeCode,
      userGroupCode:
        this.inviteUserDataForUser.userGroup == null ? null : this.inviteUserDataForUser.userGroup.userGroupCode,
      userGroupValue:
        this.inviteUserDataForUser.userGroupValue == null
          ? null
          : this.inviteUserDataForUser.userGroupValue[this.inviteUserDataForUser.userGroup.userGroupCodeColumnName],
      userGroupValueName:
        this.inviteUserDataForUser.userGroupValue == null
          ? null
          : this.inviteUserDataForUser.userGroupValue[this.inviteUserDataForUser.userGroup.userGroupNameColumnName]
    };

    this._database.executeRequest('/api/userSingle/' + this.userCode + '/invite', 'post', data).then(
      (userInviteResponse: any) => {
        if (userInviteResponse.success === true) {
          this._notif.create({
            title: 'Success',
            message: 'Invitation sent.',
            type: 'success',
            autoClose: true,
            duration: 3000,
            position: 'top-right'
          });
          this.addNewUserGroupView = false;
          this.loadUserData();
        } else {
          this.serverError();
        }
      },
      err => {
        this.serverError();
      }
    );
  }

  userGroupStatusChange(userAccess, index) {
    setTimeout(() => {
      const data = {
        userGroupCode: userAccess.userGroupCode === '' ? null : userAccess.userGroupCode,
        userGroupValue: userAccess.userGroupValue === '' ? null : parseInt(userAccess.userGroupValue),
        userTypeCode: userAccess.userTypeCode,
        userStatusCode: userAccess.userStatusCode
      };

      this._database.executeRequest('/api/userSingle/' + this.userCode + '/updateStatus', 'put', data).then(res => {
        let row = this.getGatherRow(this.userStatus, 'userStatusCode', userAccess.userStatusCode);
        row = row === undefined || row === null ? [{}] : row;
        this.userData[index].userStatusName = row[0].userStatusName;
        if (this.activeUserAccess.index === index) {
          this.activeUserAccess.data.userStatusName = row[0].userStatusName;
          this.activeUserAccess.data.userStatusCode = row[0].userStatusCode;
        }
      });
    }, 100);
  }

  getGatherRow(gather, searchKey, searchValue) {
    return gather.filter(value => {
      return value[searchKey] === searchValue;
    });
  }

  showAccessOptions($ev, data, overlay: OverlayPanel) {
    this.activeAccessDropdownOptionsData = data;
    overlay.toggle($ev);
  }

  deleteAccessOption(data) {
    this._notif.create({
      title: 'Delete user group?',
      message: 'This will permanently delete the record.',
      type: 'info',
      autoClose: false,
      backdrop: true,
      position: 'center-center',
      buttons: [
        {
          text: 'Delete',
          action: _data => {
            const location =
              '/api/userSingle/' +
              this.userCode +
              '/userType/' +
              data.userAccess.userTypeCode +
              '/userGroup/' +
              (data.userAccess.userGroupCode === '' ? 'null' : data.userAccess.userGroupCode) +
              '/userGroupValue/' +
              (data.userAccess.userGroupValue === '' ? 'null' : data.userAccess.userGroupValue);
            this._database.executeRequest(location, 'delete', {}).then(
              (response: any) => {
                if (response.success === true) {
                  // Remove
                  this.userData.splice(data.index, 1);
                  if (this.activeUserAccess.index === data.index) {
                    if (this.userData[0]) {
                      this.updateActiveLevel(this.userData[0], 0);
                    } else {
                      this.activeUserAccess = {
                        data: {},
                        index: null,
                        moduleData: []
                      };
                    }
                  } else {
                    this.activeUserAccess = {
                      data: {},
                      index: null,
                      moduleData: []
                    };
                  }
                } else {
                  this.serverError();
                }
              },
              err => {
                this.serverError();
              }
            );
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          action: () => {}
        }
      ]
    });

    // deleteUserGroup(group, index) {

    // }
  }

  updateUserProperties() {
    const payload = {
      userCode: this.userCode,
      businessPartnerCode: this.activeUserAccess.data.userGroupValue,
      businessPartnerPropertyCodes: this.selectedPropertyCodes
    };
    this._database.executeRequest(`/api/userSingle/${this.userCode}/properties`, 'put', payload).then(
      (response: any) => {
        if (response.success) {
          this._notif.create({
            title: 'Success',
            message: 'User updated.',
            type: 'success',
            autoClose: true,
            duration: 3000,
            position: 'top-right'
          });
        } else {
          this.serverError();
        }
      },
      err => {
        this.serverError();
      }
    );
  }

  // showOptions($ev, risk, i, overlay: OverlayPanel) {
  //   this.activeDropdown = { risk: risk, i: i };
  //   overlay.toggle($ev);
  // }

  // userGroupStatusChange(userGroup, index) {
  //   setTimeout(() => {
  //     const data = {
  //       userGroupCode: userGroup.userGroupCode === '' ? null : userGroup.userGroupCode,
  //       // tslint:disable-next-line:radix
  //       userGroupValue: userGroup.userGroupValue === '' ? null : parseInt(userGroup.userGroupValue),
  //       userStatusCode: userGroup.userStatusCode
  //     };

  //     this._database.executeRequest('/api/userSingle/' + this.activeEditUser.userCode + '/updateStatus', 'put', data).then((res) => {
  //       let row = this.getGatherRow(this.userStatus, 'userStatusCode', userGroup.userStatusCode);
  //       row = row === undefined || row === null ? [{}] : row;
  //       this.users[this.activeEditUserIndex].userGroups[index].userStatusName = row[0].userStatusName;
  //       this.activeEditUser.userGroups[index].userStatusName = row[0].userStatusName;
  //     });
  //   }, 100);
  // }

  // showUserGroupOptions($ev, userGroup, i, overlay: OverlayPanel) {
  //   this.activeUserGroupDropdown = { userGroup: userGroup, i: i };
  //   overlay.show($ev);
  // }

  // deleteUserGroup(group, index) {
  //   const location = '/api/userSingle/' + this.activeEditUser.userCode +
  //     '/userType/' + group.userTypeCode +
  //     '/userGroup/' + (group.userGroupCode === '' ? 'null' : group.userGroupCode) +
  //     '/userGroupValue/' + (group.userGroupValue === '' ? 'null' : group.userGroupValue);
  //   this._database.executeRequest(location, 'delete', {}).then((response: any) => {
  //     if (response.success === true) {
  //       // Remove
  //       this.activeEditUser.userGroups.splice(index, 1);
  //       this.users[this.activeEditUserIndex].userGroups.splice(index, 1);
  //     } else {
  //       this.serverError();
  //     }
  //   }, (err) => {
  //     this.serverError();
  //   });
  // }

  // resendUserGroupInvitation(group, index) {

  //   const data = {
  //     userEmail: null,
  //     userTypeCode: parseInt(group.userTypeCode),
  //     userGroupCode: group.userGroupCode,
  //     userGroupValue: group.userGroupValue == null ? null : parseInt(group.userGroupValue),
  //     userGroupValueName: group.userGroupValueName
  //   };
  //   this._database.executeRequest('/api/userSingle/' + this.activeEditUser.userCode + '/invite', 'post', data).then((userInviteResponse: any) => {
  //     if (userInviteResponse.success === true) {
  //       this._notif.create({
  //         title: 'Success',
  //         message: 'Invitation resent.',
  //         type: 'success',
  //         autoClose: true,
  //         duration: 3000,
  //         position: 'top-right'
  //       });
  //     } else {
  //       this.serverError();
  //     }
  //   }, (err) => {
  //     this.serverError();
  //   });
  // }

  // getGatherRow(gather, searchKey, searchValue) {
  //   return gather.filter((value) => {
  //     return value[searchKey] === searchValue;
  //   });
  // }

  // userTypeChangedForUser() {
  //   this.inviteUserDataForUser.userGroup = null;
  //   this.inviteUserDataForUser.userGroupValue = null;
  //   if (this.institutionUserGroup.length === 1 && this.inviteUserDataForUser.userType !== null && this.inviteUserDataForUser.userType.userTypeCode !== 1) {
  //     this.inviteUserDataForUser.userGroup = this.institutionUserGroup[0];
  //     this.userGroupChangedForUser();
  //   }
  // }

  // userGroupChangedForUser() {
  //   this._database.executeRequest('/api/' + this.inviteUserDataForUser.userGroup.userGroupGatherName + '/' + this.activeEditUser.userCode + '/' + this.inviteUserDataForUser.userType.userTypeCode, 'get', {}).then((response: any) => {
  //     this.institutionUserGroupValues = response.data;
  //   });
  // }

  // addNewUserGroup() {
  //   this.addNewUserGroupView = true;
  //   const _index = this.activeEditUser.userGroups.findIndex((value) => {
  //     return value.userTypeCode === 1 || value.userTypeCode === '1';
  //   });

  //   if (_index === -1) {
  //     this.allowedUserTypes = this.userTypes;
  //   } else {
  //     this.allowedUserTypes = this.userTypes.filter((value) => {
  //       return value.userTypeCode !== 1;
  //     });
  //   }
  //   this.inviteUserDataForUser = {
  //     userEmail: null,
  //     userType: null,
  //     userGroup: null,
  //     userGroupValue: null
  //   };
  // }

  // closeAddNewUserGroupView() {
  //   this.addNewUserGroupView = false;
  //   this.inviteUserDataForUser = {
  //     userEmail: null,
  //     userType: null,
  //     userGroup: null,
  //     userGroupValue: null
  //   };
  // }

  // inviteNewUserGroup() {
  //   // /api/userSingle/:userCode/invite
  //   const data = {
  //     userEmail: null,
  //     userTypeCode: this.inviteUserDataForUser.userType == null ? null : this.inviteUserDataForUser.userType.userTypeCode,
  //     userGroupCode: this.inviteUserDataForUser.userGroup == null ? null : this.inviteUserDataForUser.userGroup.userGroupCode,
  //     userGroupValue: this.inviteUserDataForUser.userGroupValue == null ? null : this.inviteUserDataForUser.userGroupValue[this.inviteUserDataForUser.userGroup.userGroupCodeColumnName],
  //     userGroupValueName: this.inviteUserDataForUser.userGroupValue == null ? null : this.inviteUserDataForUser.userGroupValue[this.inviteUserDataForUser.userGroup.userGroupNameColumnName]
  //   };

  //   this._database.executeRequest('/api/userSingle/' + this.activeEditUser.userCode + '/invite', 'post', data).then((userInviteResponse: any) => {
  //     if (userInviteResponse.success === true) {
  //       this._notif.create({
  //         title: 'Success',
  //         message: 'Invitation sent.',
  //         type: 'success',
  //         autoClose: true,
  //         duration: 3000,
  //         position: 'top-right'
  //       });
  //       this.editUserView = false;
  //       this.loadUsers();
  //     } else {
  //       this.serverError();
  //     }
  //   }, (err) => {
  //     this.serverError();
  //   });
  // }
}
