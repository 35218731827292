import { Component, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';

import { AppGlobal } from './core/services/app.global';
import { AppCommunication } from './core/services/app.communication';
import { StorageService } from './core/services/storage-service';
import { Database } from './core/services/database';
import { SocketService } from './core/services/socket-service';
import { NotificationLoadingService } from './core/aside/aside-notification/notification-loading.service';
import { UserStateService } from './core/services/userstate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loadingApp = true;
  addFeedbackMode: boolean;
  constructor(
    private route: ActivatedRoute,
    private _global: AppGlobal,
    protected zone: NgZone,
    private router: Router,
    private titleService: Title,
    private _storage: StorageService,
    private _communication: AppCommunication,
    private _database: Database,
    private _notificationLoading: NotificationLoadingService,
    private _socket: SocketService,
    private user: UserStateService
  ) {
    this.user.loadUser().then(res => {
      this.loadingApp = false;
    });
    this._socket.runSocket();

    this.trackConsoleLog();
    this.addFeedbackMode = false;
    this._communication.feedbackService.subscribe((state: boolean) => (this.addFeedbackMode = state));
    this._communication.themeService.subscribe(data => this.changeTheme(data));

    // MAIN APP NAME
    this._global.set('appName', 'RiskDynamyx');

    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .pipe(
        map(() => {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((title: any) => {
        title = title ? title : 'RiskDynamyx';
        this.titleService.setTitle(title);
      });
  }

  changeTheme(data) {
    // console.log('changeTheme', data);
    if (data === 0) {
      return;
    }

    const setTheme = new Promise((resolve, reject) => {
      // this.loadingTheme = true;

      let linkHref: string = null;
      localStorage.setItem('appTheme', data);

      switch (data) {
        case 1:
          linkHref = null;
          break;
        case 2:
          linkHref = 'theme-dark-green.bundle.css';
          break;
        case 3:
          linkHref = 'theme-dark-pink.bundle.css';
          break;
        case 4:
          linkHref = 'theme-white.bundle.css';
          break;
        default:
          linkHref = null;
      }

      const linkTheme = document.getElementById('appTheme');
      if (linkTheme) {
        if (linkHref) {
          linkTheme.setAttribute('href', linkHref);
        } else {
          document.head.removeChild(linkTheme);
        }
      } else {
        if (linkHref) {
          const link = document.createElement('link');
          link.id = 'appTheme';
          link.rel = 'stylesheet';
          link.href = linkHref;
          document.head.appendChild(link);
        }
      }
      resolve();
    });

    setTheme.then(() => {
      setTimeout(() => {
        document.body.classList.remove('__switching-theme');
      }, 300);
    });
  }

  trackConsoleLog() {
    this._storage.set('consoleLogReport', []);
    this.zone.onError.subscribe(err => {
      this._storage.get('consoleLogReport').then((currentData: any[]) => {
        currentData.push({
          url: window.location.href,
          stack: err.stack,
          message: err.message,
          dateTime: new Date(),
          userData: this.user.getUserData()
        });
        this._storage.set('consoleLogReport', currentData);
      });
    });
  }
}
