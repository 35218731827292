import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import { SharedService } from '../../core/services/shared-service';
import { StorageService } from '../../core/services/storage-service';
import { OverlayPanel } from '../../../../node_modules/primeng/overlaypanel';
import { LoadingDirectiveInterface } from '../directives/loading-directive/loading-directive.directive';
import { Table } from 'primeng/table';

export interface ITableColumns {
  field: string;
  header: string;
}
export interface ITableTransformFields {
  field: string;
  onField: Function;
}
export interface ITableFormatDateFields {
  field: string;
  format: string;
}
export interface ITableRowOptions {
  text?: string;
  icon?: string;
  action: Function;
}
interface IShowing { start: number; end: number; total: number; }
interface IShowingPerPage { limit: number; }
interface IShowingOptions {
  showing: IShowing;
  showingPerPage: IShowingPerPage;
  showingPerPageOptions: Array<IShowingPerPage> | Array<number>;
}

@Component({
  selector: 'app-datatable[columns][data]',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnChanges {
  @ViewChild('tt') private tt: Table;
  // stored user data
  private userData: any = [];
  // stored table options(eg.limit)
  private tableStorage: any = [];

  // Table COLUMNS
  private _columns: Array<ITableColumns> = [];
  private selectedColumns: Array<ITableColumns> = [];
  @Input('columns')
  get columns(): Array<ITableColumns> {
    return this._columns;
  }
  set columns(data: Array<ITableColumns>) {
    this._columns = data;
    this.selectedColumns = (this.selectedColumns.length > 0 ? this.selectedColumns : data);
  }

  // Table DATA
  private _data: Array<any> = [];
  @Input('data')
  get data(): Array<any> {
    return this._data;
  }
  set data(data: Array<any>) {
    this._data = data;
  }

  // TITLE
  @Input() title: string;

  // LOADING
  public loadingStatus: LoadingDirectiveInterface = { isLoading: false, text: '', show: false, class: 'default' };
  @Input() loading: LoadingDirectiveInterface;

  // LIMIT
  public showingOptions: IShowingOptions = {
    showing: { start: null, end: null, total: null },
    showingPerPage: { limit: 10 },
    showingPerPageOptions: [10, 20, 50, 100]
  };
  @Input() limit: number;

  // STYLE CLASS of component
  @Input() styleClass: string;

  // LINES wrap text in table row
  @Input() lines: string;

  // OPTIONS
  @Input() options: string;

  // ROW OPTIONS buttons
  private rowOptionSelectedData: any = null;
  @Input() rowOptions: Array<ITableRowOptions>;

  // TRANSFORM FIELDS
  @Input() transformFields: Array<ITableTransformFields>;

  // FORMAT DATE FIELDS
  private dateFields: Array<string> = [];
  private _formatDateFields: Array<ITableFormatDateFields> = [];
  @Input('formatDateFields')
  set formatDateFields(data: Array<ITableFormatDateFields>) {
    this._formatDateFields = data;
    this.dateFields = data.map(obj => obj.field);
  }
  get formatDateFields(): Array<ITableFormatDateFields> {
    return this._formatDateFields;
  }

  // EVENTS
  @Output() rowSelect: EventEmitter<any> = new EventEmitter();



  constructor(private _shared: SharedService, private _storage: StorageService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const allowedInputChanges = ['columns', 'data', 'options', 'rowOptions', 'formatDateFields', 'transformFields'];
    const inputs = Object.keys(changes);

    let doReInit = false;
    for (const input of inputs) {
      if (allowedInputChanges.includes(input) && changes[input].isFirstChange() === false) {
        doReInit = true;
      }
      if (input === 'limit') {
        this.loadRowsPerPage();
      }
    }

    if (doReInit) { this.reInitializeTable(); }
  }

  reInitializeTable() {
    if (this.transformFields) {
      this.transformFieldsInTable();
    }
    if (this.hasOption('i')) {
      this.addIndexToTable();
    }

    // set showing info in paginator
    this.generateShowingHandler({ total: this._data.length }, 'init');
  }

  /**
   * Transform corresponding fields in data
   * execute custom function on field comming from parent component
   * function must accept field value as argument and transformed value must be returned
   */
  transformFieldsInTable(): void {
    const data = [...this._data];
    const _keysOfData = Object.keys(data[0]);

    for (let j = 0; j < data.length; j++) {
      for (let i = 0; i < this.transformFields.length; i++) {
        if (!_keysOfData.includes(this.transformFields[i].field)) {
          throw new Error('Syntax error: field \'' + this.transformFields[i].field + '\' doesn\'t exist in table data. Field name is case sensitive also.');
        }

        data[j][this.transformFields[i].field] = this.transformFields[i].onField(data[j][this.transformFields[i].field]);
      }
    }
  }

  /**
   * show row index at first column in table
   * fieldName is '#'
   */
  addIndexToTable(): void {
    // prevent adding field 'index' to columns if exists
    // eg. table data has changed after initialization and showIndex is set to true
    const _colFields = this.columns.map(f => f.field);
    if (_colFields.includes('#') === false) {
      // add index column to table columns
      this.columns = [{ field: '#', header: '#' }, ...this.columns];

      // add index column to selected columns (visibility multiselect)
      this.selectedColumns = [{ field: '#', header: '#' }, ...this.selectedColumns];
    }
    // add index values to table data
    this._data = this._data.map((v, i) => v = { ...v, ['#']: i + 1 });
  }

  /**
   * Check if page's options is saved in local storage (tableStorage)
   * options: limit (for this url and logged in user)
   */
  loadRowsPerPage(): void {
    this._storage.get('userData').then((userData) => {
      this.userData = userData === null ? {} : userData;

      this._storage.get('tableStorage').then((tableData: any) => {
        this.tableStorage = tableData === null ? [] : tableData;

        // check if LS is set for this user and current url
        const savedLimit = this.tableStorage
          .filter(obj => obj.userCode === this.userData.userCode && obj.url === window.location.href)
          .map(prop => prop.limit);
        // sets showing options to: ls data | input limit | default
        this.showingOptions.showingPerPage.limit = savedLimit.length === 1 ?
          savedLimit :
          this.limit ? this.limit : this.showingOptions.showingPerPage.limit;
      });
    });
  }

  /**
   * Callback to invoke when a row is selected.
   * @param event Browser event
   * @param rowData Selected data
   */
  onRowSelect(event, rowData): void {
    const idx = this.data.findIndex(d => d === rowData);
    const e = { originalEvent: event, data: rowData, index: idx };

    // OUTPUT EVENT EMITTER
    this.rowSelect.emit(e);
  }

  /**
   * Callback to invoke when data is filtered.
   * Updates showing info
   */
  onFilter(event): void {
    this.generateShowingHandler(event, 'filter');
  }
  /**
   * Callback to invoke when pagination occurs.
   * Sets local storage for limit (rowsPerPage) better UX
   * Updates showing info
   */
  onPage(event): void {
    // check if LS is set for this user and current url
    const idx = this.tableStorage.findIndex(item => item.userCode === this.userData.userCode && item.url === window.location.href);

    if (idx !== -1) { // modify existing
      this.tableStorage[idx].limit = event.rows;
    } else { // add new
      this.tableStorage.push({
        userCode: this.userData.userCode,
        url: window.location.href,
        limit: event.rows
      });
    }

    // set the storage
    this._storage.set('tableStorage', this.tableStorage);
    // updates showing info
    this.generateShowingHandler(event, 'page');
  }
  /**
   * function to update showing entries numbers in paginator
   */
  generateShowingHandler(event, type): void {
    this.showingOptions = this._shared.generateShowing(event, this.showingOptions, type);
  }

  /**
   * Function to check if a option is presented in Input options
   * @param option single char representing option to display
   */
  hasOption(option: string): boolean {
    if (this.options) {
      return this.options.indexOf(option) !== -1;
    }
    return false;
  }

  /**
   * Callback to invoke when a row option is selected ('btn more').
   * this.rowOptionSelectedData is send back to its parent component
   * via Input rowOptions key 'action'
   * @param event Browser event
   * @param rowData Selected data
   */
  rowOptionHandler(op: OverlayPanel, event, rowData: any): void {
    op.show(event);

    const idx = this.data.findIndex(d => d === rowData);
    this.rowOptionSelectedData = { originalEvent: event, data: rowData, index: idx };
  }

  /**
   * Extract format of selected field
   * @param field fieldName
   */
  getDateFormat(field: string): string {
    const item = this.formatDateFields.filter(obj => obj.field === field);
    return item[0].format;
  }

  getReference() {
    return this.tt;
  }
}
