<!-- title, message, icon, type, autoClose, backdrop, duration, position, inputs, buttons -->
<ng-container *ngIf="NOTIFICATIONS.length > 0">
  <div *ngFor="let item of NOTIFICATIONS; let i = index" [ngClass]="item.type? 'notification notification-' + item.type : 'notification'"
    (mouseenter)="mouseEnter(item)" (mouseleave)="mouseLeave(item)" [@notificationState]="item.id">
    <span *ngIf="item.icon" [appIcon]="item.icon"></span>
    <div class="notification-body">
      <h2 class="notification-title">
        {{item.title}}
      </h2>
      <p class="notification-message">
        {{item.message}}
      </p>
      <div *ngIf="item.inputs.length" class="notification-inputs">
        <input *ngFor="let input of item.inputs; let j = index" [name]="input.name" [type]="input.type?input.type:'text'" [value]="input.value?input.value:''"
          [placeholder]="input.placeholder?input.placeholder:''" />
      </div>
      <div *ngIf="item.buttons.length" class="notification-buttons">
        <button *ngFor="let button of item.buttons; let k = index" (click)="buttonHandler(item, button)">{{button.text}}</button>
      </div>
    </div>
    <!-- <span *ngIf="item.showClose" class="notification-close" (click)="remove(item.id)">x</span> -->
  </div>
  <!-- only one notification will be displayed if backdrop is true -->
  <div class="notification-backdrop" *ngIf="NOTIFICATIONS[0].backdrop"></div>
</ng-container>