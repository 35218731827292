<p-dialog header="Feedback" [draggable]="false" [(visible)]="open" modal="modal" width="750" [responsive]="true" [appendTo]="'body'"
  (onHide)="emmitClose()">
  <div [appLoadingDirective]="savingStatus">
    <div class="inline-form-info">
      <h3>Feedback information</h3>
      <div class="ui-control ui-inline-control">
        <span class="input-label">Title*</span>
        <input type="text" class="input-control" maxlength="250" [(ngModel)]="userFeedback.userFeedbackTitle" pInputText />
      </div>
      <div class="ui-control ui-inline-control">
        <span class="input-label">Description*</span>
        <textarea class="input-control" maxlength="250" [(ngModel)]="userFeedback.userFeedbackDescription" pInputTextarea></textarea>
      </div>
      <div class="ui-control ui-inline-control">
        <span class="input-label">Contact me</span>
        <p-inputSwitch class="input-control" [(ngModel)]="userFeedback.userFeedbackContactMe"></p-inputSwitch>
      </div>
      <h3>Images</h3>
    </div>
    <div class="file-uploader-input">
      <div class="ui-control" style="margin-top:0 !important">
        <input type="file" multiple pInputText (change)="prepareImagesForUpload($event)" accept="image/*" [disabled]="savingStatus.isLoading === true">
        <i class=" fa fa-folder-open "></i>
      </div>
    </div>

    <div class="images-to-upload" *ngIf="imagesToUpload.length> 0">
      <div class="file-preview-box property-images-upload">
        <div class="file-preview" *ngFor="let picture of imagesToUpload; let i = index">
          <!-- image -->
          <div class="file-container">
            <div class="file-image" [style.background-image]="'url(' + picture.src + ')'"></div>
            <!-- waiting for upload   -->
            <div class="progress-bar" *ngIf="picture.status === 'Waiting'">
              <div class="move"></div>
            </div>
            <!-- uploading -->
            <div class="progress-bar" *ngIf="picture.status === 'Uploading'">
              <div class="determinate" [style.width]="picture.percentage + '%'"></div>
            </div>
            <!-- invalid -->
            <div class="progress-bar" *ngIf="picture.status == 'Fail'">
              <div class="determinate" style="width:100%;background-color:#F35B5B;"></div>
            </div>
          </div>
          <!-- name one or more is allowed -->
          <span class="file-name" [title]="'Error'" *ngIf="picture.status == 'Fail'">
            <marquee direction="left" scrollamount="4"> {{picture.message}}</marquee>
          </span>
          <!-- hover options -->
          <div class="file-options" *ngIf="savingStatus.isLoading !== true">
            <button class="btn btn-error" (click)="removePictureFromUpload(picture, i)">
            <span [appIcon]="'tt-error'"></span>
          </button>
          </div>
        </div>
      </div>
    </div>

    <div class="inline-form-info">
      <h3>Attachments</h3>
    </div>

    <div class="file-uploader-input">
      <div class="ui-control" style="margin-top:0 !important">
        <input type="file" multiple pInputText (change)="prepareFilesForUpload($event)" [disabled]="savingStatus.isLoading === true">
        <i class="fa fa-folder-open"></i>
      </div>
    </div>

    <div class="images-to-upload" *ngIf="filesToUpload.length > 0">
      <div class="file-preview-box property-images-upload">
        <div class="file-preview" *ngFor="let file of filesToUpload; let i = index">
          <!-- image -->
          <div class="file-container">
            <span [appIcon]="'tt-file-zip'"></span>
            <!-- waiting for upload   -->
            <div class="progress-bar" *ngIf="file.status === 'Waiting'">
              <div class="move"></div>
            </div>
            <!-- uploading -->
            <div class="progress-bar" *ngIf="file.status === 'Uploading'">
              <div class="determinate" [style.width]="file.percentage + '%'"></div>
            </div>
            <!-- invalid -->
            <div class="progress-bar" *ngIf="file.status == 'Fail'">
              <div class="determinate" style="width:100%;background-color:#F35B5B;"></div>
            </div>
          </div>
          <!-- name one or more is allowed -->
          <span class="file-name" [title]="file.status == 'Fail' ? file.message : file.originalName">
          {{file.originalName}}
        </span>
          <!-- hover options -->
          <div class="file-options" *ngIf="savingStatus.isLoading !== true">
            <button class="btn btn-error" (click)="removeFileFromUpload(file, i)">
              <span [appIcon]="'tt-error'"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <p-footer>
    <button class="btn btn-primary" (click)="saveFeedback();" [disabled]="userFeedback.userFeedbackTitle == null || userFeedback.userFeedbackTitle.trim() == '' || userFeedback.userFeedbackDescription == null || userFeedback.userFeedbackDescription.trim() == '' || savingStatus.isLoading">
      Save feedback
    </button>
    <button class="btn btn-primary" (click)="emmitClose();">
      Close
    </button>
  </p-footer>
</p-dialog>