import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CodesParsed } from '../../interfaces/codes.types';

@Component({
  selector: 'app-form-control-textarea',
  templateUrl: './form-control-textarea.component.html',
  styleUrls: ['./form-control-textarea.component.scss']
})
export class FormControlTextareaComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() inputData: CodesParsed;

  constructor() { }

  ngOnInit() {
  }

}
