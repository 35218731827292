import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CodesParsed, ColumnDisplaySettings } from '../interfaces/codes.types';
import { CodesReactiveService } from '../services/codes-reactive.service';


@Component({
  selector: 'app-codes-reactive-display-settings[codesParsedData][codesTable]',
  templateUrl: './codes-reactive-display-settings.component.html',
  styleUrls: ['./codes-reactive-display-settings.component.scss']
})
export class CodesReactiveDisplaySettingsComponent implements OnInit {
  @Input() codesParsedData: CodesParsed[];
  @Input() codesTable: string;
  @Output() closed: EventEmitter<{ hasChanged: boolean }> = new EventEmitter();

  columnDisplaySettingsData: ColumnDisplaySettings[];

  show: boolean;
  hasChanged: boolean;

  constructor(
    private codesService: CodesReactiveService
  ) {
    this.hasChanged = false;
    this.show = true;
  }

  ngOnInit(): void {
    this.prepareDataForDisplay();
  }
  prepareDataForDisplay(): void {
    this.columnDisplaySettingsData = [];
    for (const _row of this.codesParsedData) {
      if (_row.colLS !== -1 && _row.colName !== 'institutionCode') {
        this.columnDisplaySettingsData.splice(_row.columnDisplayOrderNumber - 1, 0, {
          colName: _row.colName,
          columnDisplayName: _row.columnDisplayName,
          columnShow: _row.columnShow
        });
      }
    }
  }
  reorderDown(oldIndex): void {
    const newIndex = oldIndex - 1;
    const _row = this.columnDisplaySettingsData[oldIndex];
    this.columnDisplaySettingsData.splice(oldIndex, 1);
    this.columnDisplaySettingsData.splice(newIndex, 0, _row);
  }
  reorderUp(oldIndex): void {
    const newIndex = oldIndex + 1;
    const _row = this.columnDisplaySettingsData[oldIndex];
    this.columnDisplaySettingsData.splice(oldIndex, 1);
    this.columnDisplaySettingsData.splice(newIndex, 0, _row);
  }
  saveDisplaySettings(): void {
    this.codesService.saveCodesDisplay(this.columnDisplaySettingsData, this.codesTable).then((res) => {
      if (res) {
        this.syncDisplaySettings();
        this.hasChanged = true;
      }
    });
  }
  syncDisplaySettings(): void {
    this.columnDisplaySettingsData.forEach((item, index) => {
      const codesItemIndex = this.codesParsedData.findIndex((codesItem) => {
        return codesItem.colName === item.colName;
      });
      if (codesItemIndex !== -1) {
        this.codesParsedData[codesItemIndex].columnShow = item.columnShow;
        this.codesParsedData[codesItemIndex].columnDisplayOrderNumber = index + 1;
        this.codesParsedData[codesItemIndex].columnDisplayName = item.columnDisplayName;
        this.codesParsedData[codesItemIndex].label = (item.columnDisplayName || item.colName) + (this.codesParsedData[codesItemIndex].required ? '*' : '');
      }
    });
  }
  emmitClose(): void {
    this.closed.emit({ hasChanged: this.hasChanged });
  }

}
