<div class="app-subheader-wrapper">
  <app-subheader [title]="'Messages'" [back]="true">
    <!-- <button subheader-right class="btn btn-icon btn-filter" [class.active]="filterOpen" (click)="openFilter($event)">
      <span [appIcon]="'tt-filter-filled-tool-symbol'"></span>
    </button> -->
  </app-subheader>
</div>

<!-- <div class="app-subheader-wrapper">
  <app-subheader>
    <div subheader-left>
      <button class="btn btn-default">
        <span [appIcon]="'tt-excel'"></span>
        Settings
      </button>
      <button class="btn btn-default">
        <span [appIcon]="'tt-excel'"></span>
        Delete
      </button>
    </div>
    <div subheader-right>
      <button class="btn btn-default">
        <span [appIcon]="'tt-icon'"></span>
        New
      </button>
    </div>
  </app-subheader>
</div> -->

<div class="content">
  <div class="content-inner messages-content _p10">
    Currently unavailable
    <!-- <div class="messages-user-list">
      <div class="user-block" *ngFor="let user of users; let index = index" [attr.data-messageSeen]="user.userMessageSeen" [class.active]="user.userMessageActive"
        (click)="readMessages(user.userMessageCode)">
        <div class="u-avatar">
          <img class="u-image" [src]="user.userImage" />
        </div>
        <div class="u-details">
          <h4 class="u-head">{{user.userFullName}}</h4>
          <p class="u-body">{{user.userMessage}}</p>
          <p class="u-foot">{{user.userMessageDate}}</p>
        </div>
      </div>
    </div>

    <div class="messages-preview">
      <div class="message-container">
        <ng-container *ngFor="let msg of [1,2,3,4,5]">
          <div class="message _incoming">
            <div class="message-from">
              <div class="u-avatar">
                <img class="u-image" src="./assets/img/user_placeholder.png" />
              </div>
              <span class="divider-line"></span>
            </div>
            <div class="message-timestamp text-primary">Monday 32, September at 23:33</div>
            <div class="message-body text-primary">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et venenatis ante. Praesent iaculis auctor mi. Duis laoreet
              tincidunt neque, sodales vehicula nulla fermentum vel.
            </div>
            <a href="./assets/img/user_placeholder.png" download class="message-attachment text-primary">
              <span [appIcon]="'tt-link-symbol'"></span>
              <span class="file-name">attachment-name.png</span>
            </a>
          </div>
          <div class="message _outgoing">
            <div class="message-from">
              <div class="u-avatar">
                <img class="u-image" src="./assets/img/user_placeholder.png" />
              </div>
              <span class="divider-line"></span>
            </div>
            <div class="message-timestamp text-primary">Monday 32, September at 23:33</div>
            <div class="message-body text-primary">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et venenatis ante. Praesent iaculis auctor mi. Duis laoreet
              tincidunt neque, sodales vehicula nulla fermentum vel.
            </div>
            <a href="./assets/img/user_placeholder.png" download class="message-attachment text-primary">
              <span [appIcon]="'tt-link-symbol'"></span>
              <span class="file-name">attachment-name.png</span>
            </a>
          </div>
        </ng-container>
      </div>
      <div class="messages-input">
        <div class="ui-control">
          <span class="ui-box" no-padding>
            <quill-editor [modules]="quillModules" [style]="{height: '100px'}" placeholder="Write something ..."></quill-editor>
          </span>
        </div>
      </div>
    </div>

    <div class="messages-options">
      <div class="btn-groups">
        <button class="btn btn-default _mb10">
          <span class="_mr5" [appIcon]="'tt-download'"></span>Edit</button>
        <button class="btn btn-primary">
          <span class="_mr5" [appIcon]="'tt2'"></span>Submit</button>
      </div>
    </div> -->

  </div>
</div>

<app-filter-aside (filterStatus)="getFilterStatus($event)">
  <div>
    Filter
  </div>
</app-filter-aside>