/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-aside.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./filter-aside.component";
import * as i4 from "./filter-aside-state.service";
var styles_FilterAsideComponent = [i0.styles];
var RenderType_FilterAsideComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterAsideComponent, data: { "animation": [{ type: 7, name: "slideInOut", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { transform: "scaleX(1)" }, offset: null }, options: undefined }, { type: 0, name: "out", styles: { type: 6, styles: { transform: "scaleX(0)" }, offset: null }, options: undefined }, { type: 1, expr: "out <=> in", animation: { type: 4, styles: null, timings: "100ms ease-in-out" }, options: null }], options: {} }] } });
export { RenderType_FilterAsideComponent as RenderType_FilterAsideComponent };
function View_FilterAsideComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "aside-wrapper filter-wrapper"]], [[24, "@slideInOut", 0]], null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animateState; _ck(_v, 0, 0, currVal_0); }); }
export function View_FilterAsideComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterAsideComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filterIsOpened; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FilterAsideComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-aside", [], null, null, null, View_FilterAsideComponent_0, RenderType_FilterAsideComponent)), i1.ɵdid(1, 245760, null, 0, i3.FilterAsideComponent, [i4.FilterAsideStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilterAsideComponentNgFactory = i1.ɵccf("app-filter-aside", i3.FilterAsideComponent, View_FilterAsideComponent_Host_0, {}, { filterStatus: "filterStatus" }, ["*"]);
export { FilterAsideComponentNgFactory as FilterAsideComponentNgFactory };
