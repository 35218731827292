<div class="content">
  <div class="ui-g" [appPermission]="['Admin']">
    <div class="ui-g-12">
      <div class="card">
        <div class="card-header _justify-center">
          <h3 class="ch-title">A lot of new activities on boards!</h3>
          <!-- <div class="ch-options">
            <button class="btn btn-icon" (click)="op.toggle($event)">
              <span [appIcon]="'tt-more'"></span>
            </button>
          </div> -->
        </div>

        <div class="card-body">
          <div class="card-activity">
            <div class="activity-welcome">
              <h2>Get started...</h2>
              <div class="activity-info">
                <span [appIcon]="'tt-icon'" class="text-secondary"></span>
                <span>Write new review</span>
              </div>
              <button class="btn btn-primary">Customize your plans</button>
            </div>

            <div class="activity-suggestions" style="border-right: 1px solid rgba(rgb(238, 240, 241), 0.5)">
              <h4>Next steps</h4>
              <div class="activity-info">
                <span [appIcon]="'tt-security-info'" class="text-secondary"></span>
                <span>Change informations on site</span>
              </div>
              <div class="activity-info">
                <span [appIcon]="'tt-numbered-list'" class="text-secondary"></span>
                <span>Manage widgets on menus</span>
              </div>
              <div class="activity-info">
                <span [appIcon]="'tt-radio-button'" class="text-secondary"></span>
                <span>Turn comments on or off</span>
              </div>
            </div>

            <div class="activity-records">
              <h6>You have:</h6>
              <div class="activity-info">
                <span [appIcon]="'tt-chat2-01'"></span>
                <span class="text-error">23</span>
                <span>New Comments</span>
              </div>
              <div class="activity-info">
                <span [appIcon]="'tt-envelope'"></span>
                <span class="text-error">3</span>
                <span>Questions to Answer</span>
              </div>
              <div class="activity-info">
                <span [appIcon]="'tt-download-button'"></span>
                <span class="text-error">5</span>
                <span>Downloads</span>
              </div>
            </div>

            <div class="activity-records">
              <div class="activity-info">
                <span [appIcon]="'tt-share-01'"></span>
                <span class="text-error">120</span>
                <span>News shared</span>
              </div>
              <div class="activity-info">
                <span [appIcon]="'tt-user'"></span>
                <span class="text-error">56</span>
                <span>New subscriptions</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-g-12 ui-md-6">
      <div class="card card-size__1_5x">
        <div class="card-header">
          <h3 class="ch-title">Activity on site</h3>
          <div class="ch-options">
            <button class="btn btn-icon" (click)="op.toggle($event)">
              <span [appIcon]="'tt-more'"></span>
            </button>
          </div>
        </div>

        <div class="card-body">
          <div class="chart-container">
            <canvas id="dashboardLineChart"></canvas>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-g-12 ui-md-6">
      <div class="card card-size__1_5x">
        <div class="card-header">
          <h3 class="ch-title">Activity on site</h3>
          <div class="ch-options">
            <button class="btn btn-icon" (click)="op.toggle($event)">
              <span [appIcon]="'tt-more'"></span>
            </button>
          </div>
        </div>

        <div class="card-body">
          <div class="chart-container">
            <canvas id="dashboardPieChart"></canvas>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-g-12 ui-md-6">
      <div class="card card-size__1_5x">
        <div class="card-header">
          <h3 class="ch-title">Posts published</h3>
          <div class="ch-options">
            <button class="btn btn-icon" (click)="op.toggle($event)">
              <span [appIcon]="'tt-more'"></span>
            </button>
          </div>
        </div>

        <div class="card-body">
          <div class="chart-container">
            <canvas id="dashboardBarHorizontalChart"></canvas>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-g-12 ui-md-6">
      <div class="card card-size__1_5x">
        <div class="card-header">
          <h3 class="ch-title">Posts activity</h3>
          <div class="ch-options">
            <button class="btn btn-icon" (click)="op.toggle($event)">
              <span [appIcon]="'tt-more'"></span>
            </button>
          </div>
        </div>

        <div class="card-body">
          <div class="chart-container">
            <canvas id="dashboardBarChart"></canvas>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-g-12 ui-md-6">
      <div class="card card-size__1_5x">
        <div class="card-header">
          <h3 class="ch-title">Posts types</h3>
          <div class="ch-options">
            <button class="btn btn-icon" (click)="op.toggle($event)">
              <span [appIcon]="'tt-more'"></span>
            </button>
          </div>
        </div>

        <div class="card-body">
          <div class="chart-container">
            <canvas id="dashboardPolarChart"></canvas>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-g-12 ui-md-6">
      <div class="card card-size__1_5x">
        <div class="card-header">
          <h3 class="ch-title">Posts types</h3>
          <div class="ch-options">
            <button class="btn btn-icon" (click)="op.toggle($event)">
              <span [appIcon]="'tt-more'"></span>
            </button>
          </div>
        </div>

        <div class="card-body">
          <div class="chart-container">
            <canvas id="dashboardRadarChart"></canvas>
          </div>
        </div>
      </div>
    </div>

    <!-- END GRID -->
  </div>
  <!-- END CONTENT -->

  <!-- CLIENT CHARTS -->

  <div class="ui-g" [appPermission]="['Client']">
    <div class="ui-g-12 ui-md-9">
      <div class="card card-size__1_5x">
        <div class="card-header">
          <h3 class="ch-title">Properties</h3>
        </div>

        <div [appLoadingDirective]="loadingProperty">
          <div class="card-body fx-bw-row">
            <div class="card-inner">
              <div class="property-category" ripple [style.backgroundImage]="'url(../../../assets/img/property.png)'">
                <div class="prop-icon">
                  <span [appIcon]="'tt-document2'" style="color: inherit"></span>
                </div>
                <div class="prop-type">
                  <span>Total</span>
                  <strong>Properties</strong>
                </div>
                <div class="prop-total">
                  <!-- <span>Total</span> -->
                  <strong>{{ propertiesData.length }}</strong>
                </div>
              </div>

              <div class="property-types">
                <div class="property-type" *ngFor="let type of treatmentValues">
                  <span [style.background-color]="type.color"></span>
                  <label>{{ type.name }}</label>
                  <strong [style.color]="type.color">{{ type.count }}</strong>
                </div>
              </div>
            </div>

            <div class="card-inner card-inner-list">
              <table class="incard-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Rating</th>
                    <th class="text-right">Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="add-cursor"
                    *ngFor="let prop of propertiesData | slice: 0:20"
                    [routerLink]="['/property/' + prop.businessPartnerPropertyCode]"
                    fragment="info">
                    <td>{{ prop.businessPartnerPropertyName }}</td>
                    <td>
                      <span class="circle-type" [style.background-color]="prop.riskRow.color"></span
                      >{{ prop.riskValue | appPercentage }}
                    </td>
                    <td class="text-right">
                      <small>{{
                        prop.businessPartnerPropertyStreetName +
                          ' ' +
                          prop.businessPartnerPropertyStreetNumber +
                          ', ' +
                          prop.businessPartnerPropertyTownName
                      }}</small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-g-12 ui-md-3">
      <div class="card card-size__1_5x">
        <div class="card-header">
          <h3 class="ch-title">Log</h3>
        </div>

        <div [appLoadingDirective]="loadingLog">
          <div class="card-body" style="padding-left: 10px">
            <div
              class="log-item add-cursor"
              ripple
              *ngFor="let log of logData"
              [routerLink]="log.url"
              [fragment]="log.hash">
              <span>
                <small>{{ log.logDateTime | date: 'mediumDate' }} {{ log.logDateTime | date: 'shortTime' }}</small>
              </span>
              <p>{{ log.logText }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-g-12 ui-md-6">
      <div class="card card-size__2_5x">
        <div class="card-header">
          <h3 class="ch-title">Incidents</h3>
        </div>

        <div [appLoadingDirective]="loadingIncidents">
          <div class="card-body">
            <div
              class="property-category"
              style="direction: rtl"
              ripple
              [style.backgroundImage]="'url(../../../assets/img/property.png)'">
              <div class="prop-icon">
                <span [appIcon]="'tt-document2'" style="color: inherit"></span>
              </div>
              <div class="prop-type" style="text-align: right">
                <span>Total</span>
                <strong>Incidents</strong>
              </div>
              <div class="prop-total">
                <!-- <span>Total</span> -->
                <strong>{{ totalIncidents }}</strong>
              </div>
            </div>

            <div class="fx-bw-row card-dash">
              <div class="card-inner-list">
                <div
                  class="card-inner-list list-item add-cursor"
                  ripple
                  *ngFor="let inc of incidentsData | slice: 0:20"
                  [routerLink]="['/property/' + inc.businessPartnerPropertyCode]"
                  fragment="incidents">
                  <div class="list-item-info fx-bw-row">
                    <p>{{ inc.propertyIncidentsTypeName }}</p>
                    <span class="text-right" style="text-transform: uppercase">
                      <small>{{ inc.propertyIncidentsStatusName }}</small></span
                    >
                    <span class="text-right">
                      <small>{{ inc.businessPartnerPropertyIncidentsDate | date: 'mediumDate' }}</small>
                    </span>
                  </div>
                  <div class="list-item-details">
                    <small>{{ inc.businessPartnerPropertyIncidentsName }}</small>
                  </div>
                </div>
              </div>

              <div class="card-inner-list card-inner-list__small">
                <table class="incard-table no-hover">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th class="text-right">Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let type of incidentsType">
                      <td>{{ type.propertyIncidentsTypeName }}</td>
                      <td class="text-right">
                        <small>{{ type.propertyIncidentsCount }}</small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-g-12 ui-md-6">
      <div class="card card-size__2_5x">
        <div class="card-header">
          <h3 class="ch-title">Recommendations</h3>
        </div>

        <div [appLoadingDirective]="loadingRecommendations">
          <div class="card-body">
            <div class="property-category" ripple [style.backgroundImage]="'url(../../../assets/img/property.png)'">
              <div class="prop-icon">
                <span [appIcon]="'tt-document2'" style="color: inherit"></span>
              </div>
              <div class="prop-type">
                <span>Current</span>
                <strong>Recommendations</strong>
              </div>
              <div class="prop-total">
                <!-- <span>Total</span> -->
                <strong>{{ openRecommendations.length }}</strong>
              </div>
            </div>

            <div class="fx-bw-row card-dash">
              <div class="card-inner-list card-inner-list__small chart-flex-inner" style="box-shadow: none">
                <div class="chart-container compact">
                  <canvas id="dashboardClientRecommendationsChart"></canvas>
                </div>
              </div>
              <div class="card-inner-list" style="box-shadow: none">
                <div
                  class="card-inner-list list-item add-cursor"
                  ripple
                  *ngFor="let rec of openRecommendations | slice: 0:20"
                  [routerLink]="['/property/' + rec.businessPartnerPropertyCode]"
                  fragment="recommendations">
                  <div class="list-item-info fx-bw-row">
                    <p>{{ rec.propertyRiskAssessmentQuestionRecommendationTittle }}</p>
                    <span
                      class="text-right"
                      style="text-transform: uppercase"
                      [style.color]="getRecommendationStatusColor(rec.recommendationStatusCode)">
                      <small>{{ rec.recommendationStatusName }}</small></span
                    >
                    <span class="text-right">
                      <small>{{ rec.lastModifyDateTime | date: 'mediumDate' }}</small
                      ><br />
                      <small>{{ rec.lastModifyDateTime | date: 'shortTime' }}</small>
                    </span>
                  </div>
                  <div class="list-item-details">
                    <small>{{ rec.propertyRiskAssessmentQuestionRecommendationDescription }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TEMPLATES -->
    <!-- disabled for now, too slow
    <div class="ui-g-12 ui-md-12">
      <div class="card card-size__1_5x">
        <div class="card-header">
          <h3 class="ch-title">Incidents in last 6 months</h3>
        </div>

        <div [appLoadingDirective]="loadingIncidents">
          <div class="card-body">
            <div class="chart-container">
              <canvas id="dashboardClient6MonthsIncidentsChart"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->

    <!-- END GRID -->
  </div>
  <!-- END CONTENT -->
</div>

<p-overlayPanel appendTo="body" #op [appPermission]="['Admin']">
  <div class="panel-option">
    <button class="btn">
      <span [appIcon]="'tt-icon'"></span>
      Option 1
    </button>
    <button class="btn">
      <span [appIcon]="'tt-icon'"></span>
      Option 2
    </button>
  </div>
</p-overlayPanel>
