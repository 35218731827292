/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/app-loader/app-loader.component.ngfactory";
import * as i3 from "./shared/app-loader/app-loader.component";
import * as i4 from "./features/feedback/feedback-add/feedback-add.component.ngfactory";
import * as i5 from "./features/feedback/feedback-add/feedback-add.component";
import * as i6 from "./core/services/database";
import * as i7 from "./core/services/upload-service";
import * as i8 from "./core/notification/notification.service";
import * as i9 from "./core/services/app.communication";
import * as i10 from "./core/services/storage-service";
import * as i11 from "./core/services/userstate.service";
import * as i12 from "@angular/router";
import * as i13 from "@angular/common";
import * as i14 from "./core/notification/notification.component.ngfactory";
import * as i15 from "./core/notification/notification.component";
import * as i16 from "./app.component";
import * as i17 from "./core/services/app.global";
import * as i18 from "@angular/platform-browser";
import * as i19 from "./core/aside/aside-notification/notification-loading.service";
import * as i20 from "./core/services/socket-service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "main-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loader", [], null, null, null, i2.View_AppLoaderComponent_0, i2.RenderType_AppLoaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.AppLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feedback-add", [], null, null, null, i4.View_FeedbackAddComponent_0, i4.RenderType_FeedbackAddComponent)), i1.ɵdid(1, 114688, null, 0, i5.FeedbackAddComponent, [i6.Database, i7.UploadService, i8.NotificationService, i9.AppCommunication, i10.StorageService, i11.UserStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i12.RouterOutlet, [i12.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.addFeedbackMode; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loading_done", 2]], null, 0, null, View_AppComponent_2)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-notification", [], null, null, null, i14.View_NotificationComponent_0, i14.RenderType_NotificationComponent)), i1.ɵdid(4, 245760, null, 0, i15.NotificationComponent, [i8.NotificationService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadingApp; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i16.AppComponent, [i12.ActivatedRoute, i17.AppGlobal, i1.NgZone, i12.Router, i18.Title, i10.StorageService, i9.AppCommunication, i6.Database, i19.NotificationLoadingService, i20.SocketService, i11.UserStateService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i16.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
