/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./messages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/subheader/subheader.component.ngfactory";
import * as i3 from "../../shared/subheader/subheader.component";
import * as i4 from "@angular/common";
import * as i5 from "../../shared/directives/content-height/content-height.directive";
import * as i6 from "../../shared/filter-aside/filter-aside.component.ngfactory";
import * as i7 from "../../shared/filter-aside/filter-aside.component";
import * as i8 from "../../shared/filter-aside/filter-aside-state.service";
import * as i9 from "./messages.component";
var styles_MessagesComponent = [i0.styles];
var RenderType_MessagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessagesComponent, data: {} });
export { RenderType_MessagesComponent as RenderType_MessagesComponent };
export function View_MessagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "app-subheader-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-subheader", [], null, null, null, i2.View_SubheaderComponent_0, i2.RenderType_SubheaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.SubheaderComponent, [i4.Location], { title: [0, "title"], back: [1, "back"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "content"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 212992, null, 0, i5.ContentHeightDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "content-inner messages-content _p10"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Currently unavailable "])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "app-filter-aside", [], null, [[null, "filterStatus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterStatus" === en)) {
        var pd_0 = (_co.getFilterStatus($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FilterAsideComponent_0, i6.RenderType_FilterAsideComponent)), i1.ɵdid(8, 245760, null, 0, i7.FilterAsideComponent, [i8.FilterAsideStateService], null, { filterStatus: "filterStatus" }), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Filter "]))], function (_ck, _v) { var currVal_0 = "Messages"; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 4, 0); _ck(_v, 8, 0); }, null); }
export function View_MessagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-messages", [], null, null, null, View_MessagesComponent_0, RenderType_MessagesComponent)), i1.ɵdid(1, 114688, null, 0, i9.MessagesComponent, [i8.FilterAsideStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessagesComponentNgFactory = i1.ɵccf("app-messages", i9.MessagesComponent, View_MessagesComponent_Host_0, {}, {}, []);
export { MessagesComponentNgFactory as MessagesComponentNgFactory };
