import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { AppGlobal } from '../../../core/services/app.global';
import { Database } from '../../../core/services/database';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  msgs = [];
  appName: string;
  emailPattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  formSubmited = false;
  userEmail = new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]);
  unknownEmail = false;

  constructor(_global: AppGlobal, private _database: Database) {
    this.appName = _global.get('appName');
  }

  ngOnInit() {}

  loginHangler() {
    this.unknownEmail = false;
    this.formSubmited = true;
    if (this.userEmail.valid) {
      this._database.forgotPassword(this.userEmail.value.trim()).then(
        (loginResponse: any) => {
          if (loginResponse.success === false && loginResponse.code === 550) {
            this.unknownEmail = true;
          } else if (loginResponse.success && loginResponse.code === 201) {
            this.msgs.push({ summary: 'Password Reset Success', detail: 'Please check your email for instructions' });
            this.formSubmited = false;
            this.userEmail.reset();
          }
        },
        error => {
          if (error.code === 550) {
            this.unknownEmail = true;
            return;
          }
          // Server error;
          this.msgs = [];
          this.msgs.push({ severity: 'error', summary: 'Server error', detail: 'Please try again later' });
        }
      );
    }
  }
}
