import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Database, DatabaseResponse } from '../../services/database';
import { StorageService } from '../../services/storage-service';

export interface Notifications {
  userNotificationCode: number;
  userTypeCode: number;
  userTypeName: string | null;
  userNotificationTypeCode: number | null;
  userNotificationTypeName: string | null;
  userNotificationTypeValue: string | null;
  userNotificationText: string | null;
  userNotificationStatusCode: number | null;
  userNotificationStatusName: string | null;
  userNotificationData: any;
  createUserCode: number | null;
  createUserFullName: string | null;
  createDateTime: string | null;
}

@Injectable()
export class NotificationLoadingService {
  notifications: Notifications[];
  newNotificationCount: number;
  refreshNotifications = new Subject<any>();

  notificationStatus = {
    1: 'New',
    2: 'Seen',
    3: 'Open',
    4: 'Deleted',
    5: 'Resolved'
  };

  constructor(private _storage: StorageService, private _database: Database) {
    this.notifications = [];
    this.newNotificationCount = 0;
    this.loadNotifications();
  }

  loadNotifications(): void {
    this._storage.get('notifications').then((notifications: Notifications[]) => {
      if (notifications !== null) {
        this.notifications = notifications;
      } else {
        this.notifications = [];
      }
      this.updateStorageNotifications();
    });
    this.loadDbNotifications();
  }
  newNotification(notification: Notifications): void {
    this.notifications.unshift(notification);
    this.updateStorageNotifications();
  }
  loadDbNotifications(): void {
    this._database.executeRequest('/api/notification', 'get', {}).then(
      (notificationRes: DatabaseResponse & { data: Notifications[] }) => {
        this.notifications = notificationRes.data;
        this.updateStorageNotifications();
      },
      err => {
        this.notifications = [];
        this.updateStorageNotifications();
      }
    );
  }
  loadDbNewNotifications(): void {
    this._database.executeRequest('/api/notification/new', 'get', {}).then(
      (notificationRes: DatabaseResponse & { data: Notifications[] }) => {
        this.notifications = this.notifications.filter((notification: Notifications) => {
          return notification.userNotificationStatusCode !== 1;
        });
        this.notifications = this.notifications.concat(notificationRes.data);
        this.updateStorageNotifications();
      },
      err => {
        this.loadDbNewNotifications();
      }
    );
  }
  runNewNotificationCount(): void {
    this.newNotificationCount = 0;
    this.notifications.map((notification, index) => {
      if (notification.userNotificationStatusCode === 1) {
        this.newNotificationCount = this.newNotificationCount + 1;
      }
    });
  }
  updateStatus(userNotificationCode: number, userNotificationStatusCode: number) {
    this.notifications.map((notification: Notifications) => {
      if (notification.userNotificationCode === userNotificationCode) {
        notification.userNotificationStatusCode = userNotificationStatusCode;
        notification.userNotificationStatusName = this.notificationStatus[userNotificationStatusCode];
      }
    });
    this.updateStorageNotifications();
    this.updateDbNotificationStatus(userNotificationCode, userNotificationStatusCode);
    // emmit for this one only
  }
  updateDbNotificationStatus(userNotificationCode, userNotificationStatusCode) {
    const _data = {
      userNotificationStatusCode: userNotificationStatusCode
    };

    this._database.executeRequest(`/api/notification/${userNotificationCode}/status`, 'put', _data).then(
      (response: DatabaseResponse) => {},
      (err: DatabaseResponse) => {}
    );
  }
  updateStorageNotifications(): void {
    this._storage.set('notifications', this.notifications);
    this.refreshNotifications.next();
    this.runNewNotificationCount();
  }
  getNotifications(): Notifications[] {
    return this.notifications;
  }
}
