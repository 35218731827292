import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Database, DatabaseResponse } from '../../../core/services/database';
import { LoadingDirectiveInterface } from '../../../shared/directives/loading-directive/loading-directive.directive';
import { Lightbox } from 'ngx-lightbox';

export interface UserFeedback {
  businessPartnerCode: number;
  businessPartnerName: string | null;
  businessPartnerNameShort: string | null;
  userFeedbackCode: number | null;
  userFeedbackTitle: string | null;
  userFeedbackDescription: string | null;
  userFeedbackName: string | null;
  userFeedbackEmail: string | null;
  userFeedbackPhoneNumber: string | null;
  userFeedbackUrl: string | null;
  userFeedbackLogData: string | null;
  userFeedbackContactMe: string | null;
  userFeedbackStatusCode: number | null;
  userFeedbackStatusName: string | null;
  createDateTime: string | null;
  createUserCode: number | null;
}
export interface UserFeedbackAttachment {
  documentsCode: number;
  documentsOriginalName: string | null;
  documentsName: string | null;
  documentsPath: string | null;
}
export interface UserFeedbackImage {
  mediaCode: number;
  mediaName: string | null;
  mediaOriginalName: string | null;
  mediaThumbPath: string | null;
  mediaResizePath: string | null;
  mediaPath: string | null;
}

@Component({
  selector: 'app-feedback-view',
  templateUrl: './feedback-view.component.html',
  styleUrls: ['./feedback-view.component.scss']
})
export class FeedbackViewComponent implements OnInit {
  @Input() feedbackCode: number;
  @Output() closed: EventEmitter<any> = new EventEmitter();

  loadingStatus: LoadingDirectiveInterface;

  open = true;
  userFeedback: UserFeedback;
  userFeedbackAttachments: UserFeedbackAttachment[];
  userFeedbackImages: UserFeedbackImage[];
  showLog: boolean;
  showAttachments: boolean;
  showImages: boolean;

  constructor(private _lightbox: Lightbox, private _database: Database) {
    this.showLog = false;
    this.showAttachments = false;
    this.showImages = false;
    this.userFeedback = {
      businessPartnerCode: null,
      businessPartnerName: null,
      businessPartnerNameShort: null,
      userFeedbackCode: null,
      userFeedbackTitle: null,
      userFeedbackDescription: null,
      userFeedbackName: null,
      userFeedbackEmail: null,
      userFeedbackPhoneNumber: null,
      userFeedbackUrl: null,
      userFeedbackLogData: null,
      userFeedbackContactMe: null,
      userFeedbackStatusCode: null,
      userFeedbackStatusName: null,
      createDateTime: null,
      createUserCode: null
    };
    this.userFeedbackAttachments = [];
    this.userFeedbackImages = [];
  }

  ngOnInit() {
    this.loadFeedback();
  }

  loadFeedback(): void {
    this._database.executeRequest(`/api/feedback/${this.feedbackCode}`, 'get', {}).then(
      (response: DatabaseResponse & { data: UserFeedback[] }) => {
        if (response.success && response.data[0]) {
          this.userFeedback = response.data[0];
          this.loadingStatus = { isLoading: false, text: '', show: false, class: 'default' };
          this.loadFeedbackImages();
          this.loadFeedbackAttachments();
        } else {
          this.loadingStatus = {
            isLoading: false,
            text: 'Error loading data, please try again later...',
            show: true,
            class: 'error'
          };
        }
      },
      err => {
        this.loadingStatus = {
          isLoading: false,
          text: 'Error loading data, please try again later...',
          show: true,
          class: 'error'
        };
      }
    );
  }
  loadFeedbackImages(): void {
    this._database.executeRequest(`/api/feedback/${this.feedbackCode}/image`, 'get', {}).then(
      (imageResponse: DatabaseResponse & { data: UserFeedbackImage[] }) => {
        this.userFeedbackImages = imageResponse.data;
      },
      err => {
        this.userFeedbackImages = [];
      }
    );
  }
  loadFeedbackAttachments(): void {
    this._database.executeRequest(`/api/feedback/${this.feedbackCode}/attachment`, 'get', {}).then(
      (attachmentResponse: DatabaseResponse & { data: UserFeedbackAttachment[] }) => {
        this.userFeedbackAttachments = attachmentResponse.data;
      },
      err => {
        this.userFeedbackAttachments = [];
      }
    );
  }
  getFormatDate(date: string | null): string {
    if (date == null) {
      return null;
    }
    const _date = new Date(date);
    return (
      (_date.getDate() + 1 < 10 ? '0' + (_date.getDate() + 1) : _date.getDate() + 1).toString() +
      '.' +
      (_date.getMonth() + 1 < 10 ? '0' + (_date.getMonth() + 1) : _date.getMonth() + 1).toString() +
      '.' +
      _date.getFullYear().toString() +
      ' ' +
      (_date.getHours() + 1 < 10 ? '0' + (_date.getHours() + 1) : _date.getHours() + 1).toString() +
      ':' +
      (_date.getMinutes() + 1 < 10 ? '0' + (_date.getMinutes() + 1) : _date.getMinutes() + 1).toString()
    );
  }
  lightboxInit(index: number): void {
    const album = [];
    this.userFeedbackImages.map((image: UserFeedbackImage) => {
      album.push({
        src: image.mediaResizePath,
        caption: image.mediaOriginalName,
        thumb: image.mediaThumbPath
      });
    });
    this._lightbox.open(album, index);
  }
  emmitClose(): void {
    this.closed.emit();
  }
}
