import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CodesParsed } from '../../interfaces/codes.types';

@Component({
  selector: 'app-form-control-textbox',
  templateUrl: './form-control-textbox.component.html',
  styleUrls: ['./form-control-textbox.component.scss']
})
export class FormControlTextboxComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() inputData: CodesParsed;

  constructor() {
  }

  ngOnInit() {
  }

}
