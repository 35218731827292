import { Injectable } from '@angular/core';
import { StorageService } from './core/services/storage-service';
import { UserStateService } from './core/services/userstate.service';

declare interface MenuItemsInfo {
  code: number;
  name: string;
  route: string | null;
  queryParams?: any;
  icon: string | null;
  collapse: boolean;
  children: Array<MenuItemsInfo>;
}


@Injectable()
export class MenuItemsService {

  userData: any;
  // EXAMPLES at the end of page !

  AdminMenu: MenuItemsInfo[] = [
    { 'code': 1, 'name': 'Dashboard', 'route': '/dashboard', 'icon': 'tt-Group-30', 'collapse': false, 'children': [] },
    { 'code': 2, 'name': 'User management', 'route': '/userManagement', 'icon': 'tt-Group-30', 'collapse': false, 'children': [] },
    { 'code': 3, 'name': 'Client', 'route': '/client', 'icon': 'tt-Group-30', 'collapse': false, 'children': [] },
    {
      'code': 4, 'name': 'Data management', 'route': null, 'icon': 'tt-newspaper', 'collapse': false, 'children': [
        { 'code': 4.1, 'name': 'Points of interests', 'route': '/pointsOfInterest/view', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 4.1, 'name': 'Points of interests categories', 'route': '/pointsOfInterest/categories', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] }
      ]
    },
    {
      'code': 5, 'name': 'Risk assessment settings', 'route': null, 'icon': 'tt-newspaper', 'collapse': false, 'children': [
        { 'code': 5.1, 'name': 'Likelihood', 'route': '/codes/riskAnalysisLikelihood', 'queryParams': { title: 'Likelihood' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 5.2, 'name': 'Consequence', 'route': '/codes/riskAnalysisImpact', 'queryParams': { title: 'Impact/Consequence' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 5.3, 'name': 'Overall rating', 'route': '/codes/riskAnalysisOverallRiskRating', 'queryParams': { title: 'Overall rating' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 5.4, 'name': 'Group', 'route': '/codes/riskAssessmentGroup', 'queryParams': { title: 'Group' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 5.5, 'name': 'Question', 'route': '/codes/riskAssessmentQuestion', 'queryParams': { title: 'Question' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
      ]
    },
    // Disabled for now, because not using the report feature
    //
    // {
    //   'code': 6, 'name': 'Report settings', 'route': null, 'icon': 'tt-newspaper', 'collapse': false, 'children': [
    //     { 'code': 6.1, 'name': 'Report templates', 'route': '/reportTemplate', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
    //     { 'code': 6.1, 'name': 'Report detail templates', 'route': '/reportDetailTemplate', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] }
    //   ]
    // },
    {
      'code': 7, 'name': 'General settings', 'route': null, 'icon': 'tt-newspaper', 'collapse': false, 'children': [
        { 'code': 7.1, 'name': 'Client type', 'route': '/codes/businessPartnerType', 'queryParams': { title: 'Client type' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 7.2, 'name': 'Client purpose', 'route': '/codes/businessPartnerPurpose', 'queryParams': { title: 'Client purpose' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 7.3, 'name': 'Position title', 'route': '/codes/positionTitle', 'queryParams': { title: 'Position title' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 7.4, 'name': 'Property facilities', 'route': '/codes/propertyFacility', 'queryParams': { title: 'Property facilities' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 7.5, 'name': 'Property image types', 'route': '/codes/hierarchySystemImages', 'queryParams': { title: 'Property image types' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 7.6, 'name': 'Remark types', 'route': '/codes/businessPartnerPropertyRemarkType', 'queryParams': { title: 'Remark types' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 7.6, 'name': 'Incident types', 'route': '/codes/propertyIncidentsType', 'queryParams': { title: 'Incident types' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 7.7, 'name': 'POI radius', 'route': '/codes/pointsOfInterestsRadius', 'queryParams': { title: 'POI Radius' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 7.8, 'name': 'Recommendation action type', 'route': '/codes/recommendationActionType', 'queryParams': { title: 'Recommendation action type' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 7.9, 'name': 'Crime data groups', 'route': '/codes/crimeDataGroup', 'queryParams': { title: 'Crime data groups' }, 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
      ]
    },
    {
      'code': 8, 'name': 'Feedback', 'route': null, 'icon': 'tt-newspaper', 'collapse': false, 'children': [
        { 'code': 8.1, 'name': 'All feedback', 'route': '/feedback/all', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
        { 'code': 8.2, 'name': 'My feedback', 'route': '/feedback', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
      ]
    }
  ];

  ClientMenu: MenuItemsInfo[] = [
    { 'code': 1, 'name': 'Dashboard', 'route': '/dashboard', 'icon': 'tt-Group-30', 'collapse': false, 'children': [] },
    { 'code': 2, 'name': 'Properties', 'route': '/property', 'icon': 'tt-Group-30', 'collapse': false, 'children': [] },
    { 'code': 3, 'name': 'Client information', 'route': '/client/info', 'icon': 'tt-Group-30', 'collapse': false, 'children': [] },
    { 'code': 4.2, 'name': 'Feedback', 'route': '/feedback', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] }
  ];




  constructor(
    private user: UserStateService
  ) { }

  getMenuItems(): Promise<MenuItemsInfo[]> {
    return new Promise((resolve) => {
      this.user.getUserDataAsync().then((userData: any) => {
        this.userData = userData;
        if ([1, '1'].includes(this.userData.userTypeCode)) {
          resolve(this.AdminMenu);
        } else if ([2, 3, '2', '3'].includes(this.userData.userTypeCode)) {
          resolve(this.ClientMenu);
        }
      });
    });
  }
}





// // EXAMPLES
// // MENU ITEM - LEVEL 1
// { 'code': 0, 'name': 'Item name', 'route': '/routeName', 'icon': 'iconName', 'collapse': false, 'children': [] },

// // Dropdown MENU - MENU ITEM with LEVEL 2
// {
//   'code': 1, 'name': 'Item name', 'route': null, 'icon': 'iconName', 'collapse': false, 'children': [
//     { 'code': 2, 'name': 'Item name', 'route': '/routeName', 'icon': 'iconName', 'collapse': false, 'children': [] },
//     { 'code': 3, 'name': 'Item name', 'route': '/routeName', 'icon': 'iconName', 'collapse': false, 'children': [] },
//   ]
// },

// // Dropdown MENU - MENU ITEM with LEVEL 3
// {
//   'code': 4, 'name': 'Item name', 'route': null, 'icon': 'iconName', 'collapse': false, 'children': [
//     { 'code': 5, 'name': 'Item name', 'route': '/routeName', 'icon': 'iconName', 'collapse': false, 'children': [] },
//     {
//       'code': 6, 'name': 'Item name', 'route': null, 'icon': 'iconName', 'collapse': false, 'children': [
//         { 'code': 7, 'name': 'Item name', 'route': '/routeName', 'icon': 'iconName', 'collapse': false, 'children': [] },
//       ]
//     },
//   ]
// },






// MenuItems: MenuItemsInfo[] = [
//   { 'code': 1, 'name': 'Dashboard', 'route': '/', 'icon': 'tt-warehouse-with-boxes', 'collapse': false, 'children': [] },
//   {
//     'code': 2, 'name': 'Layout', 'route': null, 'icon': 'tt-Group-30', 'collapse': false, 'children': [
//       {
//         'code': 3, 'name': 'Page', 'route': null, 'icon': 'tt-newspaper', 'collapse': false, 'children': [
//           { 'code': 4, 'name': 'Single', 'route': '/dev', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 5, 'name': 'Post', 'route': '/post', 'icon': null, 'collapse': false, 'children': [] },
//         ]
//       },
//       { 'code': 6, 'name': 'Cards', 'route': '/recommendation', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
//       { 'code': 7, 'name': 'Tabs page', 'route': '/dev/tabs', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
//     ]
//   },
//   { 'code': 8, 'name': 'Forms', 'route': '/dev/controls', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
//   {
//     'code': 9, 'name': 'Data', 'route': null, 'icon': 'tt-Group-30', 'collapse': false, 'children': [
//       { 'code': 10, 'name': 'Data Table', 'route': '/dev/table', 'icon': 'tt-table-grid', 'collapse': false, 'children': [] },
//       { 'code': 12, 'name': 'Data View', 'route': '/client', 'icon': 'tt-table-grid', 'collapse': false, 'children': [] },
//     ]
//   },

//   { 'code': 13, 'name': 'Charts', 'route': '/dev/charts', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
//   { 'code': 14, 'name': 'Gallery', 'route': '/dev/fileUpload', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
//   { 'code': 15, 'name': 'Modal', 'route': '/dev/modal', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
//   { 'code': 16, 'name': 'Notifications', 'route': '/dev/notifications', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
//   { 'code': 100, 'name': 'User management', 'route': '/userManagement', 'icon': 'tt-user', 'collapse': false, 'children': [] },

//   // END PRESENTATION
//   {
//     'code': 800, 'name': 'Settings', 'route': null, 'icon': 'tt-settings-work-tool', 'collapse': false, 'children': [
//       { 'code': 801, 'name': 'RACI Stakeholder', 'route': '/codes/RACIStakeholder', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
//       {
//         'code': 850, 'name': 'Risk Regist.', 'route': null, 'icon': 'tt-newspaper', 'collapse': false, 'children': [
//           { 'code': 851, 'name': 'Type', 'route': '/codes/riskType', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 852, 'name': 'Classification', 'route': '/codes/riskClassification', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 853, 'name': 'Level', 'route': '/codes/riskLevel', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 854, 'name': 'Status', 'route': '/codes/riskStatus', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 855, 'name': 'Consequence', 'route': '/codes/riskConsequence', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 856, 'name': 'Likelihood', 'route': '/codes/riskLikelihood', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 857, 'name': 'Rating', 'route': '/codes/riskRating', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 858, 'name': 'Monitoring', 'route': '/codes/riskMonitoring', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 859, 'name': 'Treatment', 'route': '/codes/riskTreatment', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 860, 'name': 'Treatment Type', 'route': '/codes/riskTreatmentType', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 861, 'name': 'Treatment Impact', 'route': '/codes/riskTreatmentImpact', 'icon': null, 'collapse': false, 'children': [] },
//         ]
//       },
//       {
//         'code': 870, 'name': 'Risk Recomm.', 'route': null, 'icon': 'tt-newspaper', 'collapse': false, 'children': [
//           { 'code': 871, 'name': 'Type', 'route': '/codes/recommendationType', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 872, 'name': 'Category', 'route': '/codes/recommendationCategory', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 873, 'name': 'Report', 'route': '/codes/recommendationReport', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 874, 'name': 'Report Type', 'route': '/codes/recommendationReportType', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 875, 'name': 'Risk Rating', 'route': '/codes/recommendationRiskRating', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 876, 'name': 'Risk Rating Audit', 'route': '/codes/recommendationRiskRatingAudit', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 877, 'name': 'Status', 'route': '/codes/recommendationStatus', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 878, 'name': 'Issue', 'route': '/codes/recommendationIssue', 'icon': null, 'collapse': false, 'children': [] },
//           { 'code': 879, 'name': 'Management Response', 'route': '/codes/recommendationManagementResponse', 'icon': null, 'collapse': false, 'children': [] },
//         ]
//       },
//     ]
//   },

//   {
//     'code': 900, 'name': 'System', 'route': null, 'icon': 'tt-information-icon', 'collapse': true, 'children': [
//       {
//         'code': 901, 'name': 'Risk', 'route': null, 'icon': 'tt-Group-30', 'collapse': false, 'children': [
//           { 'code': 902, 'name': 'Register', 'route': '/risk', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
//           { 'code': 903, 'name': 'Recommendation', 'route': '/recommendation', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
//         ]
//       },
//       { 'code': 904, 'name': 'Client', 'route': '/client', 'icon': 'tt-table-grid', 'collapse': false, 'children': [] },
//       { 'code': 907, 'name': 'Codes', 'route': '/codes/tesla', 'icon': 'tt-table-grid', 'collapse': false, 'children': [] },
//       { 'code': 908, 'name': 'Route generator', 'route': '/dev/routegenerator', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
//     ]
//   },
//   {
//     'code': 8, 'name': 'Feedkback', 'route': null, 'icon': 'tt-newspaper', 'collapse': false, 'children': [
//       { 'code': 8.1, 'name': 'All feedback', 'route': '/feedback/all', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
//       { 'code': 8.2, 'name': 'My feedback', 'route': '/feedback/my', 'icon': 'tt-newspaper', 'collapse': false, 'children': [] },
//     ]
//   }
// ];





