import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {
  APP_API_HOST,
  NODE_ENV,
  ENV_CONTEXT
} from './environments/environment'

console.log('Production mode?', NODE_ENV)
console.log('ENV_CONTEXT', ENV_CONTEXT)
console.log('APP_API_HOST', APP_API_HOST)

if (NODE_ENV.toString() === 'production') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
