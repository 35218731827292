// PRIMENG MODULES
// https://www.primefaces.org/primeng/#/setup
import { NgModule } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { SpinnerModule } from 'primeng/spinner';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChipsModule } from 'primeng/chips';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { CardModule } from 'primeng/card';
import { InputSwitchModule } from 'primeng/inputswitch';
import { GrowlModule } from 'primeng/growl';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { OrderListModule } from 'primeng/orderlist';
import { DataViewModule } from 'primeng/dataview';
import { DragDropModule } from 'primeng/dragdrop';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ColorPickerModule } from 'primeng/colorpicker';
import { TreeModule } from 'primeng/tree';

@NgModule({
  imports: [
    InputTextModule,
    PasswordModule,
    SpinnerModule,
    InputTextareaModule,
    RadioButtonModule,
    CheckboxModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    DialogModule,
    TableModule,
    PanelModule,
    AutoCompleteModule,
    ChipsModule,
    OverlayPanelModule,
    TooltipModule,
    CardModule,
    InputSwitchModule,
    GrowlModule,
    CodeHighlighterModule,
    OrderListModule,
    DataViewModule,
    DragDropModule,
    KeyFilterModule,
    ColorPickerModule,
    TreeModule
  ],
  exports: [
    InputTextModule,
    PasswordModule,
    SpinnerModule,
    InputTextareaModule,
    RadioButtonModule,
    CheckboxModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    DialogModule,
    TableModule,
    PanelModule,
    AutoCompleteModule,
    ChipsModule,
    OverlayPanelModule,
    TooltipModule,
    CardModule,
    InputSwitchModule,
    GrowlModule,
    CodeHighlighterModule,
    OrderListModule,
    DataViewModule,
    DragDropModule,
    KeyFilterModule,
    ColorPickerModule,
    TreeModule
  ]
})

export class PrimeNgModule { }
