import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubheaderComponent implements OnInit {
  @Input() title: string;
  @Input() back: boolean;

  constructor(private _location: Location) { }

  ngOnInit() {
    // console.log(`Subheader title is ${this.title}`);
  }

  backHandler() {
    this._location.back();
  }

}
