import { Injectable } from '@angular/core';

import * as io from 'socket.io-client';
import { NotificationLoadingService } from '../aside/aside-notification/notification-loading.service';
import { UserStateService } from './userstate.service';
import { APP_API_HOST } from '../../../environments/environment';

@Injectable()
export class SocketService {
  private socket: SocketIOClient.Socket;

  constructor(
    private user: UserStateService,
    private _notificationLoading: NotificationLoadingService,
  ) {

  }

  runSocket() {
    this.socket = io.connect(APP_API_HOST);
    this.socket.on('connect', (res) => {
      const userData = this.user.getUserData();
      this.socket.emit('loginUser', userData);
    });
    this.socket.on('newNotification', (notification) => {
      this._notificationLoading.newNotification(notification);
    });
    this.socket.on('loadNewNotifications', () => {
      this._notificationLoading.loadDbNewNotifications();
    });
    this.socket.on('reloadNotifications', () => {
      this._notificationLoading.loadDbNotifications();
    });
  }

  emitUserNotificationStatusUpdate(userNotificationCode: number, userNotificationStatusCode: number) {
    this.socket.emit('userNotificationStatusUpdate', { userNotificationCode: userNotificationCode, userNotificationStatusCode: userNotificationStatusCode });
  }
}
