import { Component, OnInit } from '@angular/core';
import { Database, DatabaseResponse } from '../../../core/services/database';
import { AppCommunication } from '../../../core/services/app.communication';
import { UploadService } from '../../../core/services/upload-service';
import { NotificationService } from '../../../core/notification/notification.service';
import { LoadingDirectiveInterface } from '../../../shared/directives/loading-directive/loading-directive.directive';
import { UserStateService } from '../../../core/services/userstate.service';
import { StorageService } from '../../../core/services/storage-service';

export interface UserFeedback {
  userFeedbackCode: number;
  userFeedbackTitle: string;
  userFeedbackDescription: string;
  userFeedbackContactMe: boolean;
}

@Component({
  selector: 'app-feedback-add',
  templateUrl: './feedback-add.component.html',
  styleUrls: ['./feedback-add.component.scss']
})
export class FeedbackAddComponent implements OnInit {

  open = true;
  userFeedback: UserFeedback;
  uploadImagesInstance: any;
  imagesToUpload = [];
  uploadFilesInstance: any;
  filesToUpload = [];
  userData: any;

  savingStatus: LoadingDirectiveInterface;
  uploadsToFinish: string[];


  constructor(
    private _database: Database,
    private _uploadService: UploadService,
    private _notif: NotificationService,
    private _communication: AppCommunication,
    private _storage: StorageService,
    private user: UserStateService
  ) {
    this.savingStatus = { isLoading: false, text: '', show: false, class: 'default' };
    this.uploadImagesInstance = null;
    this.uploadFilesInstance = null;
    this.uploadsToFinish = [];
    this.userFeedback = {
      userFeedbackCode: null,
      userFeedbackTitle: null,
      userFeedbackDescription: null,
      userFeedbackContactMe: false
    };
  }

  ngOnInit() {
    this.userData = this.user.getUserData();
  }
  prepareImagesForUpload(_event): void {
    if (_event.target.files && _event.target.files[0]) {
      const files = _event.target.files;
      if (this.uploadImagesInstance == null) {
        if (files.length > 3) {
          this._notif.create({
            title: 'Notice',
            message: 'You can add max up to 3 images',
            type: 'warn',
            autoClose: true,
            duration: 3000,
            position: 'top-right'
          });
          return;
        }
        this.uploadImagesInstance = this._uploadService.uploadFiles(files, `/api/s/upload/feedback/${this.userFeedback.userFeedbackCode}/images`, {});
        this.imagesToUpload = this.uploadImagesInstance.getFiles();

        this.uploadImagesInstance.onFileFinish.subscribe((image) => {
          // this.processUploadedImage(image);
        });

        this.uploadImagesInstance.onUploadFinish.subscribe((images) => {
          this.imagesToUpload = images;
          this.finishSaving('images');
          this.uploadImagesInstance = null;
        });

        this.uploadImagesInstance.listen.subscribe((images: any) => {
          this.imagesToUpload = images;
        });
      } else {
        if (this.imagesToUpload.length < 3) {
          this.uploadImagesInstance.addFiles(files);
        } else {
          this._notif.create({
            title: 'Notice',
            message: 'You can add max up to 3 images',
            type: 'warn',
            autoClose: true,
            duration: 3000,
            position: 'top-right'
          });
        }
      }

      _event.target.value = '';
    }
  }
  removePictureFromUpload(picture, index): void {
    this.uploadImagesInstance.removeFile(picture.id);
  }
  prepareFilesForUpload(_event): void {
    if (_event.target.files && _event.target.files[0]) {
      const files = _event.target.files;
      if (this.uploadFilesInstance == null) {
        if (files.length > 3) {
          this._notif.create({
            title: 'Notice',
            message: 'You can add max up to 3 files',
            type: 'warn',
            autoClose: true,
            duration: 3000,
            position: 'top-right'
          });
          return;
        }
        this.uploadFilesInstance = this._uploadService.uploadFiles(files, `/api/s/upload/feedback/${this.userFeedback.userFeedbackCode}/attachment`, {});
        this.filesToUpload = this.uploadFilesInstance.getFiles();
        this.uploadFilesInstance.onFileFinish.subscribe((image) => {

        });

        this.uploadFilesInstance.onUploadFinish.subscribe((_files) => {
          this.filesToUpload = _files;
          this.finishSaving('files');
          this.uploadFilesInstance = null;
        });

        this.uploadFilesInstance.listen.subscribe((_files: any) => {
          this.filesToUpload = _files;
        });
      } else {
        if (this.filesToUpload.length < 3) {
          this.uploadFilesInstance.addFiles(files);
        } else {
          this._notif.create({
            title: 'Notice',
            message: 'You can add max up to 3 files',
            type: 'warn',
            autoClose: true,
            duration: 3000,
            position: 'top-right'
          });
        }
      }

      _event.target.value = '';
    }
  }
  removeFileFromUpload(file, index): void {
    this.uploadFilesInstance.removeFile(file.id);
  }
  saveFeedback() {
    if (this.userFeedback.userFeedbackTitle !== null || this.userFeedback.userFeedbackTitle.trim() !== '' || this.userFeedback.userFeedbackDescription !== null || this.userFeedback.userFeedbackDescription.trim() !== '') {
      this.savingStatus = { isLoading: true, text: 'Saving, please wait...', show: true, class: 'default' };
      this.uploadsToFinish = [];
      const _tempUD: any = Object.assign({}, this.userData);
      delete _tempUD.userCode;

      this._storage.get('consoleLogReport').then((logReport) => {
        const data = {
          businessPartnerCode: this.userData.businessPartnerCode === undefined ? '' : this.userData.businessPartnerCode,
          userFeedbackTitle: this.userFeedback.userFeedbackTitle,
          userFeedbackDescription: this.userFeedback.userFeedbackDescription,
          userFeedbackName: this.userData.userFullName,
          userFeedbackEmail: this.userData.userEmail,
          userFeedbackPhoneNumber: null,
          userFeedbackUrl: window.location.href,
          userFeedbackLogData: JSON.stringify(logReport),
          userFeedbackContactMe: this.userFeedback.userFeedbackContactMe,
          otherData: JSON.stringify(this.userData)
        };


        this._database.executeRequest('/api/feedback', 'post', data)
          .then((response: DatabaseResponse & { data: { userFeedbackCode: number }[] }) => {
            if (response.success && response.data[0] && response.data[0].userFeedbackCode) {
              this.userFeedback.userFeedbackCode = response.data[0].userFeedbackCode;
              if (this.uploadImagesInstance !== null) {
                this.uploadsToFinish.push('images');
                this.uploadImagesInstance.updateLocation(`/api/s/upload/feedback/${this.userFeedback.userFeedbackCode}/images`);
                this.uploadImagesInstance.startUpload();
                this.savingStatus = { isLoading: true, text: `Uploading ${this.uploadsToFinish.join(' & ')}, please wait...`, show: true, class: 'default' };
              }
              if (this.uploadFilesInstance !== null) {
                this.uploadsToFinish.push('files');
                this.uploadFilesInstance.updateLocation(`/api/s/upload/feedback/${this.userFeedback.userFeedbackCode}/attachment`);
                this.uploadFilesInstance.startUpload();
                this.savingStatus = { isLoading: true, text: `Uploading ${this.uploadsToFinish.join(' & ')}, please wait...`, show: true, class: 'default' };
              }
              this.finishSaving('regular');
            } else {
              this.savingStatus = { isLoading: false, text: '', show: false, class: 'default' };
              this.serverError();
            }
          }, (err) => {
            this.savingStatus = { isLoading: false, text: '', show: false, class: 'default' };
            this.serverError();
          });
      });
    }
  }
  finishSaving(type) {
    if (type === 'images' || type === 'files') {
      const typeIndex = this.uploadsToFinish.indexOf(type);
      if (typeIndex !== -1) {
        this.uploadsToFinish.splice(typeIndex, 1);
      }
      this.savingStatus = { isLoading: true, text: `Uploading ${this.uploadsToFinish.join(' & ')}, please wait...`, show: true, class: 'default' };
    }

    if (this.uploadsToFinish.length === 0) {
      // Finished
      this.emmitClose();
      this._notif.create({
        title: 'Success',
        message: 'Feedback saved',
        type: 'success',
        autoClose: true,
        duration: 3000,
        position: 'top-right'
      });
    }
  }
  serverError(): void {
    this._notif.create({
      title: 'Server Error',
      message: 'Please try again later.',
      type: 'error',
      autoClose: true,
      duration: 3000,
      position: 'top-right'
    });
  }
  emmitClose() {
    this._communication.feedbackService.next(false);
  }

}
