import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { AppGlobal } from '../services/app.global';
import { AsideStateService } from '../aside/aside-state.service';
import { NavbarStateService } from '../navbar/navbar-state.service';
import { NotificationLoadingService } from '../aside/aside-notification/notification-loading.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('searchInputGrow', [
      state('in', style({
        width: '150px'
      })),
      state('out', style({
        width: '50px'
      })),
      transition('in <=> out', animate('150ms ease-in-out')),
    ])
  ]
})
export class HeaderComponent implements OnInit {
  messages: number = null;
  activeItem = ''; // header item (buttons, avatar)

  searchInputValue: string = null;
  searchInputGrowState = 'out';

  constructor(
    private router: Router,
    public _global: AppGlobal,
    public notificationService: NotificationLoadingService,
    private _navbar: NavbarStateService,
    private _aside: AsideStateService,
  ) {
    this.messages = 0;
  }

  ngOnInit() {
  }

  toRoot() {
    this.router.navigate(['/']);
  }

  /** toggle navbar state => send state to subscribers via observable subject*/
  menuToggleHandler() {
    this._navbar.toggleNavbarState();
  }

  /** @param item SENT item to ASIDE service on user interaction */
  openAside(item: string) {
    this._aside.openAside(item);
  }
  /** @param ev RECIEVED item from EventEmitter after ASIDE FUNCTIONALITY */
  activateItem(ev) {
    this.activeItem = ev.activeItem;
  }

  /** ANIMATION functions */
  searchInputOnFocus() {
    this.searchInputGrowState = 'in';
  }
  searchInputOnBlur() {
    if (!this.searchInputValue || this.searchInputValue.trim() === '') {
      this.searchInputGrowState = 'out';
      this.searchInputValue = '';
    }
  }

}
