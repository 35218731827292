/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./aside-notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./aside-notification.component";
import * as i4 from "./notification-loading.service";
import * as i5 from "@angular/router";
var styles_AsideNotificationComponent = [i0.styles];
var RenderType_AsideNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AsideNotificationComponent, data: {} });
export { RenderType_AsideNotificationComponent as RenderType_AsideNotificationComponent };
function View_AsideNotificationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check"], ["style", "color:#42CD41"]], null, null, null, null, null))], null, null); }
function View_AsideNotificationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-eye-slash"]], null, null, null, null, null))], null, null); }
function View_AsideNotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "na-details"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNotification(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsideNotificationComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsideNotificationComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { var currVal_1 = (_v.parent.context.$implicit.userNotificationStatusCode === 3); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_v.parent.context.$implicit.userNotificationStatusCode != 3) && (_v.parent.context.$implicit.userNotificationStatusCode != 5)); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.userNotificationText; _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.getFormatDate(_v.parent.context.$implicit.createDateTime); _ck(_v, 9, 0, currVal_3); }); }
function View_AsideNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "na-item"]], [[2, "new-notification", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsideNotificationComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = ((_v.context.$implicit.userNotificationTypeCode === 1) || (_v.context.$implicit.userNotificationTypeCode === 3)); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.userNotificationStatusCode != 3) && (_v.context.$implicit.userNotificationStatusCode != 5)); _ck(_v, 0, 0, currVal_0); }); }
export function View_AsideNotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "notifications-aside"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "content-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "content-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Notification Center "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "na-item-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AsideNotificationComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notifications; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_AsideNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-aside-notification", [], null, null, null, View_AsideNotificationComponent_0, RenderType_AsideNotificationComponent)), i1.ɵdid(1, 245760, null, 0, i3.AsideNotificationComponent, [i4.NotificationLoadingService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AsideNotificationComponentNgFactory = i1.ɵccf("app-aside-notification", i3.AsideNotificationComponent, View_AsideNotificationComponent_Host_0, {}, {}, []);
export { AsideNotificationComponentNgFactory as AsideNotificationComponentNgFactory };
