import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class FilterAsideStateService {
  private filterAsideStateService = new Subject<any>();

  private filterAsideState: boolean; // state of filterAside component

  private renderer: Renderer2;
  private listenerFn: () => void;

  constructor(private rendererFactory: RendererFactory2, private router: Router) {
    router.events.pipe(filter(e => e instanceof NavigationEnd)).forEach(e => this.closeFilter());

    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.filterAsideState = false;
  }

  /**
   * subscibe to recieve state and selected item as observable
   */
  getFilterAsideState(): Observable<any> {
    return this.filterAsideStateService.asObservable();
  }

  /**
   * functionality to opens filter aside component
   */
  openFilter() {
    if (this.filterAsideState === true) {
      this.setFilterAsideState(false);
    } else {
      this.setFilterAsideState(true);
    }
  }

  closeFilter() {
    this.setFilterAsideState(false);
  }

  private setFilterAsideState(state: boolean): void {
    if (state === true) {
      // request to open
      this.filterAsideState = true;

      // add state class for custom styles
      document.body.classList.add('filter-open');
      // add reference to eventListener
      setTimeout(() => {
        this.listenerFn = this.renderer.listen(document, 'click', this.onClick.bind(this));
      }, 10);
    } else {
      // request to close
      this.filterAsideState = false;

      // remove state class
      document.body.classList.remove('filter-open');
      // remove reference to eventListener
      if (this.listenerFn) {
        this.listenerFn();
      }
    }

    this.filterAsideStateService.next({ state: this.filterAsideState });
  }

  /**
   * event listener to close filterAside when user clicks away from this component
   * @param event click event from user
   */
  private onClick(event): void {
    const ignoredElements = 'app-header .menu-icon, .filter-wrapper, .filter-wrapper *';

    if (!event.target.matches(ignoredElements)) {
      this.closeFilter();
    }
  }
}
