import { Directive, OnInit, Input, ElementRef, Renderer } from '@angular/core';
import { Icons } from './icons';

@Directive({
  selector: '[appIcon]'
})
export class IconsDirective implements OnInit {
  @Input() appIcon: string;

  constructor(
    public el: ElementRef, public renderer: Renderer
  ) { }

  ngOnInit() {
    // console.log(this.appIcon);
    const $el = this.el.nativeElement;

    // support for font awesome icon set!
    if (this.appIcon.indexOf('fa-') !== -1) {
      this.renderer.setElementClass($el, 'fa', true);
      this.renderer.setElementClass($el, this.appIcon, true);
      return;
    }

    // custom tesla tools icon set!
    const icon: any = Icons.filter(singleIcon => {
      return singleIcon.name === this.appIcon;
    });

    // console.log(icon, icon[0].name, icon[0].path);
    this.renderer.setElementClass($el, 'icon', true);
    this.renderer.setElementClass($el, this.appIcon, true);

    if (icon[0].path !== '') {
      $el.innerHTML = icon[0].path;
    }
  }


}
