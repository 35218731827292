import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NavbarStateService } from '../navbar/navbar-state.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss'],
  animations: [
    trigger('navState', [
      state(
        'opened, opened-on-device',
        style({
          width: '200px'
        })
      ),
      state(
        'closed',
        style({
          width: '64px'
        })
      ),
      state(
        'closed-on-device',
        style({
          width: '0px'
        })
      ),
      transition('* => *', animate('100ms linear'))
    ]),
    trigger('mainState', [
      state(
        'opened',
        style({
          paddingLeft: '200px'
        })
      ),
      state(
        'closed',
        style({
          paddingLeft: '64px'
        })
      ),
      state(
        'closed-on-device, opened-on-device',
        style({
          paddingLeft: '0px'
        })
      ),
      transition('* => *', animate('100ms linear'))
    ])
  ]
})
export class MasterComponent implements OnInit, OnDestroy {
  animationState: string;
  navbarStateSubscription: Subscription;

  constructor(private _navbar: NavbarStateService) {
    this.navbarStateSubscription = this._navbar.getNavbarState().subscribe(data => {
      // console.log('navbarStateSubscription', data);
      this.toggleMenu(data);
    });
  }

  ngOnInit() {
    // console.log('Master Init');
    // first app init
    // we call this function here because navbar is part of this component
    this._navbar.initNavbarState();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this._navbar.initNavbarState();
  }

  toggleMenu(data) {
    if (data.state === true) {
      this.animationState = window.innerWidth <= 992 ? 'opened-on-device' : 'opened';
    } else {
      this.animationState = window.innerWidth <= 992 ? 'closed-on-device' : 'closed';
    }
  }

  ngOnDestroy() {
    this.navbarStateSubscription.unsubscribe();
  }
}
