/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgot-password.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/components/growl/growl.ngfactory";
import * as i3 from "primeng/components/growl/growl";
import * as i4 from "primeng/components/common/messageservice";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "primeng/components/inputtext/inputtext";
import * as i9 from "../../../shared/directives/ripple/ripple.directive";
import * as i10 from "./forgot-password.component";
import * as i11 from "../../../core/services/app.global";
import * as i12 from "../../../core/services/database";
var styles_ForgotPasswordComponent = [i0.styles];
var RenderType_ForgotPasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotPasswordComponent, data: {} });
export { RenderType_ForgotPasswordComponent as RenderType_ForgotPasswordComponent };
function View_ForgotPasswordComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "ui-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please enter a valid email address "]))], null, null); }
function View_ForgotPasswordComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "ui-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Unknown email address. Please try again "]))], null, null); }
function View_ForgotPasswordComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "ui-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Email is "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["required"]))], null, null); }
export function View_ForgotPasswordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p-growl", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.msgs = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Growl_0, i2.RenderType_Growl)), i1.ɵdid(1, 4636672, null, 0, i3.Growl, [i1.ElementRef, i1.IterableDiffers, [2, i4.MessageService], i1.NgZone], { life: [0, "life"], value: [1, "value"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(2, 0, null, null, 32, "div", [["class", "forgot-password-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "form-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "company-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 25, "div", [["class", "form-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "nav-links"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "a", [["routerLink", "/forgotPassword"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, [[2, 4]], 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(9, 1720320, null, 2, i5.RouterLinkActive, [i5.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Forgot Password"])), (_l()(), i1.ɵeld(13, 0, null, null, 17, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 16, "div", [["class", "ui-control"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 9, "span", [["class", "ui-float-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 6, "input", [["pInputText", ""], ["type", "email"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "keyup.enter"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 17).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 17)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 17)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i1.ɵnov(_v, 22).onInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup.enter" === en)) {
        var pd_5 = (_co.loginHangler() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(19, 540672, null, 0, i7.FormControlDirective, [[8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR], [2, i7.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i7.NgControl, null, [i7.FormControlDirective]), i1.ɵdid(21, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), i1.ɵdid(22, 278528, null, 0, i8.InputText, [i1.ElementRef, [2, i7.NgModel]], null, null), (_l()(), i1.ɵeld(23, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_1)), i1.ɵdid(26, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_2)), i1.ɵdid(28, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_3)), i1.ɵdid(30, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(31, 0, null, null, 3, "div", [["class", "form-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 2, "button", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loginHangler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(33, 81920, null, 0, i9.RippleDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, ["Reset password"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = 3000; var currVal_1 = _co.msgs; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = "/forgotPassword"; _ck(_v, 8, 0, currVal_4); var currVal_5 = "active"; _ck(_v, 9, 0, currVal_5); var currVal_18 = _co.userEmail; _ck(_v, 19, 0, currVal_18); _ck(_v, 22, 0); var currVal_19 = ((_co.formSubmited && _co.userEmail.hasError("pattern")) && !_co.userEmail.hasError("required")); _ck(_v, 26, 0, currVal_19); var currVal_20 = (((_co.formSubmited && !_co.userEmail.hasError("pattern")) && !_co.userEmail.hasError("required")) && _co.unknownEmail); _ck(_v, 28, 0, currVal_20); var currVal_21 = (_co.formSubmited && _co.userEmail.hasError("required")); _ck(_v, 30, 0, currVal_21); _ck(_v, 33, 0); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 8).target; var currVal_3 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_6 = i1.ɵnov(_v, 21).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 21).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 21).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 21).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 21).ngClassValid; var currVal_11 = i1.ɵnov(_v, 21).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 21).ngClassPending; var currVal_13 = true; var currVal_14 = true; var currVal_15 = true; var currVal_16 = true; var currVal_17 = i1.ɵnov(_v, 22).filled; _ck(_v, 16, 1, [currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17]); }); }
export function View_ForgotPasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-password", [], null, null, null, View_ForgotPasswordComponent_0, RenderType_ForgotPasswordComponent)), i1.ɵdid(1, 114688, null, 0, i10.ForgotPasswordComponent, [i11.AppGlobal, i12.Database], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotPasswordComponentNgFactory = i1.ɵccf("app-forgot-password", i10.ForgotPasswordComponent, View_ForgotPasswordComponent_Host_0, {}, {}, []);
export { ForgotPasswordComponentNgFactory as ForgotPasswordComponentNgFactory };
