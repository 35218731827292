<p-growl [(value)]="msgs" [life]="3000"></p-growl>

<div class="forgot-password-form">
  <div class="form-header">
    <!-- <h1 class="name">{{appName}}</h1> -->
    <div class="company-logo"></div>
  </div>

  <div class="form-body">
    <div class="nav-links">
      <a routerLinkActive="active" routerLink="/forgotPassword">Forgot Password</a>
    </div>

    <div class="main">
      <div class="ui-control">
        <span class="ui-float-label">
          <input pInputText [formControl]="userEmail" type="email" (keyup.enter)="loginHangler()" />
          <label>Email</label>
        </span>

        <span class="ui-error" *ngIf="formSubmited && userEmail.hasError('pattern') && !userEmail.hasError('required')">
          Please enter a valid email address
        </span>

        <span
          class="ui-error"
          *ngIf="formSubmited && !userEmail.hasError('pattern') && !userEmail.hasError('required') && unknownEmail">
          Unknown email address. Please try again
        </span>

        <span class="ui-error" *ngIf="formSubmited && userEmail.hasError('required')">
          Email is
          <strong>required</strong>
        </span>
      </div>
    </div>
  </div>
  <div class="form-footer">
    <button class="btn btn-default" (click)="loginHangler()">Reset password</button>
  </div>
</div>
