import { Injectable } from '@angular/core';
import { UserStateService } from './userstate.service';

@Injectable()
export class PermissionService {

    constructor(private user: UserStateService) {
    }

    canAcess(roles) {
        if (roles) {
            return roles.includes(this.user.getUserData().userTypeName);
        } else {
            return true;
        }
    }
}
