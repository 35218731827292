import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserStateService } from '../../../core/services/userstate.service';


@Directive({
    selector: '[appPermission]'
})
export class PermissionDirective implements OnInit {
    @Input('appPermission') appPermission: ('Admin' | 'Client' | 'User')[];
    @Input('permissionType') permissionType: ('Remove' | 'Disable');
    allRoles = ['Admin', 'Client', 'User'];
    userData: any;
    constructor(
        private el: ElementRef,
        private user: UserStateService,
        private _router: Router) {
        this.permissionType = this.permissionType || 'Remove';
    }

    ngOnInit() {
        this.user.getUserDataAsync().then((usData) => {
            this.userData = usData;
            if (this.allRoles.includes(this.userData.userTypeName)) {
                if (!this.appPermission.includes(this.userData.userTypeName)) {
                    if (this.permissionType === 'Remove') {
                        this.el.nativeElement.remove();
                    } else if (this.permissionType === 'Disable') {
                        this.el.nativeElement.style.pointerEvents = 'none';
                        this.el.nativeElement.style.userSelect = 'none';
                        this.el.nativeElement.disabled = true;
                    }
                }
            } else {
                this._router.navigate(['/login'], { replaceUrl: true });
            }
        });
    }
}
