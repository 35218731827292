<div class="app-subheader-wrapper">
  <app-subheader [title]="'Users'" [back]="true">
    <button subheader-right class="btn btn-default" (click)="inviteUser()">
      Invite user
    </button>
  </app-subheader>
</div>


<div class="content">
  <div class="ui-g">
    <div class="ui-g-12 ui-lg-3 ui-md-6" *ngFor="let user of users; let i = index" [appLoadingDirective]="loadingStatus">
      <div class="card card-size__1_5x">
        <div class="card-header">
          <h3 class="ch-title">
            {{user.userFullName == '' ? user.userEmail : user.userFullName}}
          </h3>
          <div class="ch-options">
            <button class="btn btn-icon" (click)="showOptions($event, user, i, userOptions)">
              <span [appIcon]="'tt-more'"></span>
            </button>
          </div>
        </div>

        <div class="card-body">

          <div class="userCard">
            <img class="userCard-avatar" src="./assets/img/user_placeholder.png" />

            <div class="userCard-body">
              <div class="stats">
                <label>Email:
                  <i>{{user.userEmail}}</i>
                </label>
                <label>Joined:
                  <i>{{formatDate(user.createDateTime)}}</i>
                </label>
              </div>
              <div class="access">
                <h4>Access:</h4>
                <label *ngFor="let userGroup of user.userGroups;let isLast = last">
                  {{ (userGroup.userGroupValueName != '' ? (userGroup.userGroupValueName + ' as ') : (user.institutionName + ' as ')) }} {{userGroup.userTypeName}},
                  {{userGroup.userStatusName}}
                </label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="content" [appLoadingDirective]="loadingStatus">
  <div class="content-inner">
    <div class="card-body">
      <div class="ui-g">
        <div class="ui-xl-3 ui-lg-3 ui-md-6 ui-sm-12 " *ngFor="let user of users; let i = index">

          <div class="userCard">
            <div class="userCard-header">
              <img class="userCard-avatar" src="./assets/img/user_placeholder.png" />
              <div class="userCard-heading">
                {{user.userFullName == '' ? user.userEmail : user.userFullName}}
              </div>
            </div>
            <div class="userCard-body">

              <div class="userCard-details">
                <div class="stats">
                  <label>Email:
                    <i>{{user.userEmail}}</i>
                  </label>
                  <label>Joined:
                    <i>{{formatDate(user.createDateTime)}}</i>
                  </label>
                </div>
                <div class="access">
                  <h4>Access:</h4>
                  <label *ngFor="let userGroup of user.userGroups;let isLast = last">
                    {{ (userGroup.userGroupValueName != '' ? (userGroup.userGroupValueName + ' as ') : (user.institutionName + ' as ')) }} {{userGroup.userTypeName}},
                    {{userGroup.userStatusName}}
                  </label>
                </div>
              </div>
            </div>
            <button class="btn btn-icon toggle _fx5" (click)="showOptions($event, user, i, userOptions);">
              <span [appIcon]="'tt-more'"></span>
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div> -->


<p-overlayPanel appendTo="body" #userOptions>
  <div class="panel-option">
    <button class="btn" (click)="userOptions.hide();editUser(activeDropdown.user, activeDropdown.i)">
      <i class="fa fa-edit" aria-hidden="true"></i>
      Edit
    </button>
    <button class="btn" (click)="userOptions.hide();deleteUser(activeDropdown.user, activeDropdown.i)">
      <i class="fa fa-trash-o" aria-hidden="true"></i>
      Delete
    </button>
  </div>
</p-overlayPanel>



<p-dialog header="Invite users" [draggable]="false" [(visible)]="inviteUserView" modal="modal" width="450" [responsive]="true"
  [appendTo]="'body'">

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-control">
        <span class="ui-float-label">
          <input type="text" required pInputText [(ngModel)]="inviteUserData.userEmail">
          <label>Email</label>
        </span>
      </div>
    </div>

    <div class="ui-g-12">
      <div class="ui-control">
        <span class="ui-float-label">
          <p-dropdown [options]="userTypes" [autoDisplayFirst]="false" [(ngModel)]="inviteUserData.userType" optionLabel="userTypeName"
            [appendTo]="'body'" (onChange)="userTypeChanged()"></p-dropdown>
          <label>User type</label>
        </span>
      </div>
    </div>

    <div class="ui-g-12" *ngIf="inviteUserData.userType !== null && (inviteUserData.userType.userTypeCode == 2 || inviteUserData.userType.userTypeCode == 3) && institutionUserGroup.length > 0">
      <div class="ui-control">
        <span class="ui-float-label">
          <p-dropdown [disabled]="institutionUserGroup.length == 1" [options]="institutionUserGroup" [autoDisplayFirst]="false" [(ngModel)]="inviteUserData.userGroup"
            optionLabel="userGroupName" [appendTo]="'body'" (onChange)="userGroupChanged()"></p-dropdown>
          <label>User group</label>
        </span>
      </div>
    </div>

    <div class="ui-g-12" *ngIf="inviteUserData.userGroup !== null">
      <div class="ui-control">
        <span class="ui-float-label">
          <p-dropdown [options]="institutionUserGroupValues" [autoDisplayFirst]="false" [(ngModel)]="inviteUserData.userGroupValue"
            [optionLabel]="inviteUserData.userGroup.userGroupNameColumnName" [appendTo]="'body'"></p-dropdown>
          <label>User group value</label>
        </span>
      </div>
    </div>
  </div>

  <p-footer>
    <button class="btn btn-default" (click)="inviteUserView = false">Cancel</button>
    <button class="btn btn-default" (click)="sendInvitation()">Invite</button>
  </p-footer>
</p-dialog>