/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./master.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "../services/app.global";
import * as i6 from "../aside/aside-notification/notification-loading.service";
import * as i7 from "../navbar/navbar-state.service";
import * as i8 from "../aside/aside-state.service";
import * as i9 from "../navbar/navbar.component.ngfactory";
import * as i10 from "../navbar/navbar.component";
import * as i11 from "../../app.menu-items";
import * as i12 from "./master.component";
var styles_MasterComponent = [i0.styles];
var RenderType_MasterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MasterComponent, data: { "animation": [{ type: 7, name: "navState", definitions: [{ type: 0, name: "opened, opened-on-device", styles: { type: 6, styles: { width: "200px" }, offset: null }, options: undefined }, { type: 0, name: "closed", styles: { type: 6, styles: { width: "64px" }, offset: null }, options: undefined }, { type: 0, name: "closed-on-device", styles: { type: 6, styles: { width: "0px" }, offset: null }, options: undefined }, { type: 1, expr: "* => *", animation: { type: 4, styles: null, timings: "100ms linear" }, options: null }], options: {} }, { type: 7, name: "mainState", definitions: [{ type: 0, name: "opened", styles: { type: 6, styles: { paddingLeft: "200px" }, offset: null }, options: undefined }, { type: 0, name: "closed", styles: { type: 6, styles: { paddingLeft: "64px" }, offset: null }, options: undefined }, { type: 0, name: "closed-on-device, opened-on-device", styles: { type: 6, styles: { paddingLeft: "0px" }, offset: null }, options: undefined }, { type: 1, expr: "* => *", animation: { type: 4, styles: null, timings: "100ms linear" }, options: null }], options: {} }] } });
export { RenderType_MasterComponent as RenderType_MasterComponent };
export function View_MasterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "app-master-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "header", [["class", "app-header-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(3, 114688, null, 0, i3.HeaderComponent, [i4.Router, i5.AppGlobal, i6.NotificationLoadingService, i7.NavbarStateService, i8.AsideStateService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 6, "main", [["class", "app-main-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "aside", [["class", "app-navbar-wrapper"]], [[24, "@navState", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-navbar", [], null, null, null, i9.View_NavbarComponent_0, i9.RenderType_NavbarComponent)), i1.ɵdid(7, 114688, null, 0, i10.NavbarComponent, [i5.AppGlobal, i11.MenuItemsService, i8.AsideStateService], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "section", [["class", "app-section-wrapper"]], [[24, "@mainState", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(10, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 7, 0); _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animationState; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.animationState; _ck(_v, 8, 0, currVal_1); }); }
export function View_MasterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-master", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_MasterComponent_0, RenderType_MasterComponent)), i1.ɵdid(1, 245760, null, 0, i12.MasterComponent, [i7.NavbarStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MasterComponentNgFactory = i1.ɵccf("app-master", i12.MasterComponent, View_MasterComponent_Host_0, {}, {}, []);
export { MasterComponentNgFactory as MasterComponentNgFactory };
