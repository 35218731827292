import { Component, OnInit } from '@angular/core';
import { FilterAsideStateService } from '../../shared/filter-aside/filter-aside-state.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  filterOpen = false;
  quillModules: any;
  users: any[];

  constructor(private _filter: FilterAsideStateService) {
    this.quillModules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'link'],
        [{ 'list': 'bullet' }, { 'list': 'ordered' }, 'blockquote'],
        [{ 'color': [] }, 'clean'],
      ]
    };
  }

  ngOnInit() {
    this.users = MESSAGE_DATA;
  }

  openFilter(event) {
    this._filter.openFilter();
  }
  getFilterStatus(ev) {
    this.filterOpen = ev.filterOpen;
  }

  readMessages(userMessageCode) {
    this.users.filter(user => {
      if (user.userMessageCode === userMessageCode) {
        user.userMessageActive = true;
      } else {
        user.userMessageActive = false;
      }
    });

    // HERE LOAD MESSAGES FOR SELECTED USER
  }

}

export const MESSAGE_DATA: any = [
  {
    'userMessageCode': 0,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': false,
    'userMessageActive': true
  },
  {
    'userMessageCode': 1,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': false,
    'userMessageActive': false
  },
  {
    'userMessageCode': 2,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': false,
    'userMessageActive': false
  },
  {
    'userMessageCode': 3,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': false,
    'userMessageActive': false
  },
  {
    'userMessageCode': 4,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': true,
    'userMessageActive': false
  },
  {
    'userMessageCode': 5,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': true,
    'userMessageActive': false
  },
  {
    'userMessageCode': 6,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': true,
    'userMessageActive': false
  },
  {
    'userMessageCode': 7,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': true,
    'userMessageActive': false
  },
  {
    'userMessageCode': 8,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': true,
    'userMessageActive': false
  },
  {
    'userMessageCode': 9,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': true,
    'userMessageActive': false
  },
  {
    'userMessageCode': 10,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': true,
    'userMessageActive': false
  },
  {
    'userMessageCode': 11,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': true,
    'userMessageActive': false
  },
  {
    'userMessageCode': 12,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': true,
    'userMessageActive': false
  },
  {
    'userMessageCode': 13,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': true,
    'userMessageActive': false
  },
  {
    'userMessageCode': 14,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': true,
    'userMessageActive': false
  },
  {
    'userMessageCode': 15,
    'userImage': './assets/img/user_placeholder.png',
    'userFullName': 'John Doe',
    'userMessage': 'Hi, how are you?',
    'userMessageDate': 'Today at 02:20',
    'userMessageSeen': true,
    'userMessageActive': false
  }
];
