import { Injectable } from '@angular/core';
import { Response, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { HttpParameterCodec, HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppCommunication } from './app.communication';
import { AppGlobal } from './app.global';
import { Router } from '@angular/router';
import { StorageService } from './storage-service';
import { APP_API_HOST } from '../../../environments/environment';

// Fix Angular default encoding which stuffs up the '+' symbol, see: https://stackoverflow.com/a/54921164/172973
export class HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string {
    return standardEncoding(k);
  }
  encodeValue(v: string): string {
    return standardEncoding(v);
  }
  decodeKey(k: string): string {
    return decodeURIComponent(k);
  }
  decodeValue(v: string) {
    return decodeURIComponent(v);
  }
}

function standardEncoding(v: string): string {
  return encodeURIComponent(v);
}

@Injectable()
export class Database {
  _dCode = null;
  constructor(
    public http: HttpClient,
    public _global: AppGlobal,
    public router: Router,
    private _storage: StorageService,
    private _communication: AppCommunication
  ) {
    this.getDeviceFingerprint(code => {
      this._dCode = code;
      document.cookie = '_dCode=' + this._dCode;
    });
  }

  executeRequest(location, type, params) {
    // Call query procedures
    if (this.getCookie('_dCode') === undefined || this.getCookie('_dCode') === null) {
      this.getDeviceFingerprint(code => {
        this._dCode = code;
        document.cookie = '_dCode=' + this._dCode;
      });
    }

    return new Promise((resolve, error) => {
      const json = JSON.stringify(params);
      const url = `${APP_API_HOST}${location}`;

      // GET
      const getOptions = new RequestOptions({ withCredentials: true });

      // POST
      const httpParams = new HttpParams({
        encoder: new HttpUrlEncodingCodec()
      }).set('data', json);
      const postOptions = new RequestOptions({
        withCredentials: true,
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });

      this.http[type](url, type === 'get' || type === 'delete' ? getOptions : httpParams, postOptions)
        .pipe(
          map(res => {
            //res.json()
            return res;
          })
        )
        .subscribe(
          dataRes => {
            if (dataRes.code !== 401) {
              if (resolve) {
                resolve(dataRes);
              }
            } else {
              if (error) {
                error(dataRes);
              }
              this._communication.logoutUser.next();
            }
          },
          err => {
            try {
              err = err.json();
            } catch (err) {}
            if (err.code !== 401) {
              if (error) {
                error({
                  code: err.error.code,
                  success: false,
                  status: 'ERROR',
                  message: err.error.message,
                  data: []
                });
              }
            } else {
              this._communication.logoutUser.next();
            }
          }
        );
    });
  }

  locationSearch(value) {
    return this.executeRequest('/api/locationSearch/' + value, 'get', {});
  }

  forwardGeocode(value) {
    return this.executeRequest('/api/forwardGeocode/' + value, 'get', {});
  }

  backwardGeocode(lat, lng) {
    return this.executeRequest('/api/backwardGeocode/' + lat + '/' + lng, 'get', {});
  }

  refreshToken() {
    return new Promise((resolve, error) => {
      this.getDeviceFingerprint((fingerprint, status) => {
        const token = this.getCookie('auth_token_nicromAstir');
        this.executeRequest('/api/refreshToken', 'post', {
          deviceCode: fingerprint
        }).then(
          res => {
            resolve(res);
          },
          err => {
            error(err);
          }
        );
      });
    });
  }

  loginUser(email, password, institutionCode, userTypeCode, userGroupValue) {
    password = MD5(password);
    return new Promise((resolve, error) => {
      this.getDeviceFingerprint((fingerprint, status) => {
        const params = {
          email,
          password,
          deviceCode: fingerprint,
          institutionCode,
          userTypeCode,
          userGroupValue
        };

        this.executeRequest('/api/login', 'post', params).then(
          res => {
            resolve(res);
          },
          err => {
            error(err);
          }
        );
      });
    });
  }

  forgotPassword(email) {
    return new Promise((resolve, error) => {
      this.getDeviceFingerprint((fingerprint, status) => {
        const params = {
          email,
          deviceCode: fingerprint
        };

        this.executeRequest('/api/forgotPassword', 'post', params).then(
          res => {
            resolve(res);
          },
          err => {
            error(err);
          }
        );
      });
    });
  }

  getUserData() {
    const userToken = this.getCookie('auth_token_nicromAstir');
    return this.parseJwt(userToken);
  }

  getDeviceFingerprint(cb) {
    'use strict';

    let strOnError, strKey, Value, strValue, strTmp, strOut;

    strOnError = 'Error';
    strKey = '';
    Value = '';
    strValue = '';
    strTmp = '';
    strOut = '';

    try {
      // tslint:disable-next-line:forin
      for (strKey in navigator) {
        Value = navigator[strKey];

        if (Value === null || (typeof Value !== 'function' && typeof Value !== 'object')) {
          strValue = String(Value);
          if (strValue === 'null') {
            strValue = 'NULL';
          }
          if (strValue === '') {
            strValue = '_';
          }
          strTmp = strTmp + strKey + '=' + strValue + '|';
        }
      }
      strOut = strTmp.slice(0, strTmp.length - 1);
      cb(MD5(strOut), true);
    } catch (err) {
      cb(MD5(strOnError), false);
    }
  }

  getCookie(cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return undefined;
  }

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
}

const MD5 = function (s) {
  function L(k, d) {
    // tslint:disable-next-line:no-bitwise
    return (k << d) | (k >>> (32 - d));
  }
  function K(G, k) {
    // tslint:disable-next-line:no-bitwise
    let I, d, F, H, x;
    F = G & 2147483648;
    H = k & 2147483648;
    I = G & 1073741824;
    d = k & 1073741824;
    x = (G & 1073741823) + (k & 1073741823);
    if (I & d) {
      return x ^ 2147483648 ^ F ^ H;
    }
    if (I | d) {
      if (x & 1073741824) {
        return x ^ 3221225472 ^ F ^ H;
      } else {
        return x ^ 1073741824 ^ F ^ H;
      }
    } else {
      return x ^ F ^ H;
    }
  }
  function r(d, F, k) {
    // tslint:disable-next-line:no-bitwise
    return (d & F) | (~d & k);
  }
  function q(d, F, k) {
    // tslint:disable-next-line:no-bitwise
    return (d & k) | (F & ~k);
  }
  function p(d, F, k) {
    // tslint:disable-next-line:no-bitwise
    return d ^ F ^ k;
  }
  function n(d, F, k) {
    // tslint:disable-next-line:no-bitwise
    return F ^ (d | ~k);
  }
  function u(G, F, aa, Z, k, H, I) {
    G = K(G, K(K(r(F, aa, Z), k), I));
    return K(L(G, H), F);
  }
  function f(G, F, aa, Z, k, H, I) {
    G = K(G, K(K(q(F, aa, Z), k), I));
    return K(L(G, H), F);
  }
  function D(G, F, aa, Z, k, H, I) {
    G = K(G, K(K(p(F, aa, Z), k), I));
    return K(L(G, H), F);
  }
  function t(G, F, aa, Z, k, H, I) {
    G = K(G, K(K(n(F, aa, Z), k), I));
    return K(L(G, H), F);
  }
  function e(G) {
    let Z;
    const F = G.length;
    const x = F + 8;
    const k = (x - (x % 64)) / 64;
    const I = (k + 1) * 16;
    const aa = Array(I - 1);
    let d = 0;
    let H = 0;
    while (H < F) {
      Z = (H - (H % 4)) / 4;
      d = (H % 4) * 8;
      // tslint:disable-next-line:no-bitwise
      aa[Z] = aa[Z] | (G.charCodeAt(H) << d);
      H++;
    }
    Z = (H - (H % 4)) / 4;
    d = (H % 4) * 8;
    // tslint:disable-next-line:no-bitwise
    aa[Z] = aa[Z] | (128 << d);
    // tslint:disable-next-line:no-bitwise
    aa[I - 2] = F << 3;
    // tslint:disable-next-line:no-bitwise
    aa[I - 1] = F >>> 29;
    return aa;
  }
  function B(x) {
    // tslint:disable-next-line:no-bitwise
    let k = '',
      F = '',
      G,
      d;
    for (d = 0; d <= 3; d++) {
      G = (x >>> (d * 8)) & 255;
      F = '0' + G.toString(16);
      k = k + F.substr(F.length - 2, 2);
    }
    return k;
  }
  function J(k) {
    k = k.replace(/rn/g, 'n');
    let d = '';
    for (let F = 0; F < k.length; F++) {
      const x = k.charCodeAt(F);
      if (x < 128) {
        d += String.fromCharCode(x);
      } else {
        if (x > 127 && x < 2048) {
          // tslint:disable-next-line:no-bitwise
          d += String.fromCharCode((x >> 6) | 192);
          // tslint:disable-next-line:no-bitwise
          d += String.fromCharCode((x & 63) | 128);
        } else {
          // tslint:disable-next-line:no-bitwise
          d += String.fromCharCode((x >> 12) | 224);
          // tslint:disable-next-line:no-bitwise
          d += String.fromCharCode(((x >> 6) & 63) | 128);
          // tslint:disable-next-line:no-bitwise
          d += String.fromCharCode((x & 63) | 128);
        }
      }
    }
    return d;
  }
  let C = Array();
  let P, h, E, v, g, Y, X, W, V;
  const S = 7,
    Q = 12,
    N = 17,
    M = 22;
  const A = 5,
    z = 9,
    y = 14,
    w = 20;
  const o = 4,
    m = 11,
    l = 16,
    j = 23;
  const U = 6,
    T = 10,
    R = 15,
    O = 21;
  s = J(s);
  C = e(s);
  Y = 1732584193;
  X = 4023233417;
  W = 2562383102;
  V = 271733878;
  for (P = 0; P < C.length; P += 16) {
    h = Y;
    E = X;
    v = W;
    g = V;
    Y = u(Y, X, W, V, C[P + 0], S, 3614090360);
    V = u(V, Y, X, W, C[P + 1], Q, 3905402710);
    W = u(W, V, Y, X, C[P + 2], N, 606105819);
    X = u(X, W, V, Y, C[P + 3], M, 3250441966);
    Y = u(Y, X, W, V, C[P + 4], S, 4118548399);
    V = u(V, Y, X, W, C[P + 5], Q, 1200080426);
    W = u(W, V, Y, X, C[P + 6], N, 2821735955);
    X = u(X, W, V, Y, C[P + 7], M, 4249261313);
    Y = u(Y, X, W, V, C[P + 8], S, 1770035416);
    V = u(V, Y, X, W, C[P + 9], Q, 2336552879);
    W = u(W, V, Y, X, C[P + 10], N, 4294925233);
    X = u(X, W, V, Y, C[P + 11], M, 2304563134);
    Y = u(Y, X, W, V, C[P + 12], S, 1804603682);
    V = u(V, Y, X, W, C[P + 13], Q, 4254626195);
    W = u(W, V, Y, X, C[P + 14], N, 2792965006);
    X = u(X, W, V, Y, C[P + 15], M, 1236535329);
    Y = f(Y, X, W, V, C[P + 1], A, 4129170786);
    V = f(V, Y, X, W, C[P + 6], z, 3225465664);
    W = f(W, V, Y, X, C[P + 11], y, 643717713);
    X = f(X, W, V, Y, C[P + 0], w, 3921069994);
    Y = f(Y, X, W, V, C[P + 5], A, 3593408605);
    V = f(V, Y, X, W, C[P + 10], z, 38016083);
    W = f(W, V, Y, X, C[P + 15], y, 3634488961);
    X = f(X, W, V, Y, C[P + 4], w, 3889429448);
    Y = f(Y, X, W, V, C[P + 9], A, 568446438);
    V = f(V, Y, X, W, C[P + 14], z, 3275163606);
    W = f(W, V, Y, X, C[P + 3], y, 4107603335);
    X = f(X, W, V, Y, C[P + 8], w, 1163531501);
    Y = f(Y, X, W, V, C[P + 13], A, 2850285829);
    V = f(V, Y, X, W, C[P + 2], z, 4243563512);
    W = f(W, V, Y, X, C[P + 7], y, 1735328473);
    X = f(X, W, V, Y, C[P + 12], w, 2368359562);
    Y = D(Y, X, W, V, C[P + 5], o, 4294588738);
    V = D(V, Y, X, W, C[P + 8], m, 2272392833);
    W = D(W, V, Y, X, C[P + 11], l, 1839030562);
    X = D(X, W, V, Y, C[P + 14], j, 4259657740);
    Y = D(Y, X, W, V, C[P + 1], o, 2763975236);
    V = D(V, Y, X, W, C[P + 4], m, 1272893353);
    W = D(W, V, Y, X, C[P + 7], l, 4139469664);
    X = D(X, W, V, Y, C[P + 10], j, 3200236656);
    Y = D(Y, X, W, V, C[P + 13], o, 681279174);
    V = D(V, Y, X, W, C[P + 0], m, 3936430074);
    W = D(W, V, Y, X, C[P + 3], l, 3572445317);
    X = D(X, W, V, Y, C[P + 6], j, 76029189);
    Y = D(Y, X, W, V, C[P + 9], o, 3654602809);
    V = D(V, Y, X, W, C[P + 12], m, 3873151461);
    W = D(W, V, Y, X, C[P + 15], l, 530742520);
    X = D(X, W, V, Y, C[P + 2], j, 3299628645);
    Y = t(Y, X, W, V, C[P + 0], U, 4096336452);
    V = t(V, Y, X, W, C[P + 7], T, 1126891415);
    W = t(W, V, Y, X, C[P + 14], R, 2878612391);
    X = t(X, W, V, Y, C[P + 5], O, 4237533241);
    Y = t(Y, X, W, V, C[P + 12], U, 1700485571);
    V = t(V, Y, X, W, C[P + 3], T, 2399980690);
    W = t(W, V, Y, X, C[P + 10], R, 4293915773);
    X = t(X, W, V, Y, C[P + 1], O, 2240044497);
    Y = t(Y, X, W, V, C[P + 8], U, 1873313359);
    V = t(V, Y, X, W, C[P + 15], T, 4264355552);
    W = t(W, V, Y, X, C[P + 6], R, 2734768916);
    X = t(X, W, V, Y, C[P + 13], O, 1309151649);
    Y = t(Y, X, W, V, C[P + 4], U, 4149444226);
    V = t(V, Y, X, W, C[P + 11], T, 3174756917);
    W = t(W, V, Y, X, C[P + 2], R, 718787259);
    X = t(X, W, V, Y, C[P + 9], O, 3951481745);
    Y = K(Y, h);
    X = K(X, E);
    W = K(W, v);
    V = K(V, g);
  }
  const i = B(Y) + B(X) + B(W) + B(V);
  return i.toLowerCase();
};

export interface DatabaseResponse {
  code: number;
  success: boolean;
  status: 'OK' | 'ERROR' | 'FAIL';
  message: string | null;
}
