import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { CodesReactiveParserService } from './services/codes-reactive-parser.service';
import { CodesReactiveService } from './services/codes-reactive.service';

import { CodesReactiveComponent } from './codes-reactive.component';
import { CodesReactiveFormComponent } from './codes-reactive-form/codes-reactive-form.component';
import { CodesReactiveDisplaySettingsComponent } from './codes-reactive-display-settings/codes-reactive-display-settings.component';
import { FormControlAutocompleteComponent } from './formControls/form-control-autocomplete/form-control-autocomplete.component';
import { FormControlCheckboxComponent } from './formControls/form-control-checkbox/form-control-checkbox.component';
import { FormControlColorComponent } from './formControls/form-control-color/form-control-color.component';
import { FormControlDateComponent } from './formControls/form-control-date/form-control-date.component';
import { FormControlDropdownComponent } from './formControls/form-control-dropdown/form-control-dropdown.component';
import { FormControlTextboxComponent } from './formControls/form-control-textbox/form-control-textbox.component';
import { FormControlNumberComponent } from './formControls/form-control-number/form-control-number.component';
import { FormControlTextEditorComponent } from './formControls/form-control-text-editor/form-control-text-editor.component';
import { FormControlTextareaComponent } from './formControls/form-control-textarea/form-control-textarea.component';


@NgModule({
    imports: [SharedModule],
    declarations: [
        CodesReactiveComponent,
        CodesReactiveFormComponent,
        CodesReactiveDisplaySettingsComponent,
        FormControlAutocompleteComponent,
        FormControlCheckboxComponent,
        FormControlColorComponent,
        FormControlDateComponent,
        FormControlDropdownComponent,
        FormControlNumberComponent,
        FormControlTextboxComponent,
        FormControlTextEditorComponent,
        FormControlTextareaComponent
    ],
    exports: [
        CodesReactiveFormComponent,
        CodesReactiveDisplaySettingsComponent,
        FormControlAutocompleteComponent,
        FormControlCheckboxComponent,
        FormControlColorComponent,
        FormControlDateComponent,
        FormControlDropdownComponent,
        FormControlNumberComponent,
        FormControlTextboxComponent,
        FormControlTextEditorComponent,
        FormControlTextareaComponent
    ],
    providers: [
        CodesReactiveParserService,
        CodesReactiveService
    ]
})
export class CodesReactiveModule { }
