// SHARED SINGLETON SERVICES, APP-LEVEL COMPONENTS
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// modules
import { SharedModule } from '../shared/shared.module';

// components
import { MasterComponent } from './master/master.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HeaderComponent } from './header/header.component';
import { AsideComponent } from './aside/aside.component';
import { AsideNotificationComponent } from './aside/aside-notification/aside-notification.component';

// services
import { AppGlobal } from './services/app.global';
import { AppCommunication } from './services/app.communication';
import { NavbarStateService } from './navbar/navbar-state.service';
import { AsideStateService } from './aside/aside-state.service';
import { FilterAsideStateService } from '../shared/filter-aside/filter-aside-state.service';

import { StorageService } from './services/storage-service';
import { UploadService } from './services/upload-service';
import { Database } from './services/database';
import { SocketService } from './services/socket-service';
import { SharedService } from './services/shared-service';
import { PermissionGuard } from './services/permission-guard';
import { PermissionService } from './services/permission-service';
import { UserStateService } from './services/userstate.service';

import { SelectTableService } from './services/select-table.service';

import { NotificationLoadingService } from './aside/aside-notification/notification-loading.service';


@NgModule({
  imports: [
    RouterModule,
    CommonModule,

    SharedModule,
  ],
  declarations: [
    MasterComponent,
    NavbarComponent,
    HeaderComponent,
    AsideComponent,
    AsideNotificationComponent
  ],
  exports: [],
  providers: [
    AppGlobal,
    AppCommunication,
    NavbarStateService,
    AsideStateService,
    FilterAsideStateService,
    StorageService,
    UploadService,
    Database,
    SocketService,
    SharedService,
    PermissionGuard,
    PermissionService,

    SelectTableService,

    NotificationLoadingService,
    UserStateService
  ]
})
export class CoreModule { }
