import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CodesRaw, CodesParsed, CodesTableHeader } from './interfaces/codes.types';
import { CodesReactiveParserService } from './services/codes-reactive-parser.service';
import { LoadingDirectiveInterface } from '../../shared/directives/loading-directive/loading-directive.directive';
import { NotificationService } from '../../core/notification/notification.service';
import { CodesReactiveService } from './services/codes-reactive.service';
import { ITableRowOptions } from '../../shared/data-table/data-table.component';



@Component({
  selector: 'app-codes-reactive',
  templateUrl: './codes-reactive.component.html',
  styleUrls: ['./codes-reactive.component.scss']
})
export class CodesReactiveComponent implements OnInit {
  // http://localhost:3500/codesreactive/riskAssessmentQuestion?title=Question
  codesRawData: CodesRaw[] = []; // Raw database response data
  codesParsedData: CodesParsed[] = []; // Parsed data - types, visibility, required etc
  codesTableData: any[] = []; // All codes data from database;
  codesTableHeader: CodesTableHeader[] = []; // Header of table defined
  rowOptions: ITableRowOptions[];

  codesReady: boolean; // If all parsing is ready
  codesTable: string; // Table that we are running codes on
  displayTableName: string; // Display table name
  allowedActions: string[] = [];

  dropdownData: any;
  addEditCodes: boolean; // Add edit codes mode (modal)
  addEditCodesRowData: any; // If edit - row data

  editCodesDisplay: boolean;

  loadingStatus: LoadingDirectiveInterface; // Loading


  constructor(
    private activatedRoute: ActivatedRoute,
    private codesParser: CodesReactiveParserService,
    private codesService: CodesReactiveService,
    private _notif: NotificationService
  ) {
    this.addEditCodes = null;
    this.codesReady = false;

    this.rowOptions = [
      {
        text: 'Edit',
        icon: 'fa-edit',
        action: (event) => {
          this.editCodesRow(event.data);
        }
      },
      {
        text: 'Delete',
        icon: 'fa-trash',
        action: (event) => {
          this.deleteCodes(event.data);
        }
      }
    ];

  }

  onRowSelect(event) {
    this.editCodesRow(event.data);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((param) => {
      this.loadingStatus = { isLoading: true, text: 'Loading...', show: true, class: 'default' };
      this.codesTable = param['table'];
      this.initCodes();
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.displayTableName = params['title'] ? params['title'] : this.codesTable;
    });
  }
  initCodes(): void {
    this.codesService.getCodes(this.codesTable).then((res: CodesRaw[]) => {
      if (res.length === 0) {
        this.loadingStatus = { isLoading: false, text: `Server error, please try again later`, show: true, class: 'error' };
      } else {
        this.codesRawData = res;
        this.prepareCodesData();
        this.loadCodesPerrmisions();
        this.prepareCodesTableHeader();
        this.loadCodesTableData();
      }
    }, (err) => {
      this.loadingStatus = { isLoading: false, text: `Server error, please try again later`, show: true, class: 'error' };
    });
  }
  prepareCodesData(): void {
    this.codesParser.prepareRawData(this.codesRawData).then((parsed: CodesParsed[]) => {
      this.codesParsedData = parsed;
      this.codesReady;
    });
  }
  loadCodesPerrmisions(): void {
    this.codesService.getCodesPermissions(this.codesTable).then((res: string[]) => {
      this.allowedActions = res;
    });
  }
  loadCodesTableData(): void {
    this.loadingStatus = { isLoading: true, text: 'Loading...', show: true, class: 'default' };
    this.codesService.getCodesTableData(this.codesTable).then((data) => {
      this.codesTableData = data;
      this.loadingStatus = { isLoading: false, text: '', show: false, class: 'default' };
    });
  }
  prepareCodesTableHeader(): void {
    this.codesParser.generateCodesTableHeader(this.codesRawData).then((res: CodesTableHeader[]) => {
      this.codesTableHeader = res;
    });
  }
  processTableValue(value: any, index: number): any {
    return this.codesParser.prepareTableValue(value, this.codesParsedData[index]);
  }
  newCodesRow(): void {
    this.addEditCodesRowData = null;
    this.addEditCodes = true;
  }
  editCodesRow(row): void {
    this.addEditCodesRowData = row;
    this.addEditCodes = true;
  }
  addEditCodesClosed(data): void {
    this.addEditCodes = false;
    if (data.hasInserted || data.hasUpdated) { this.loadCodesTableData(); }
  }
  deleteCodes(rowData): void {
    const data = this.codesParser.generatePOSTDataFromRaw(rowData, this.codesParsedData, 'delete');
    this.codesService.deleteCodes(data, this.codesTable).then((res) => {
      if (res) { this.loadCodesTableData(); }
    });
  }
  editDisplaySettings(): void {
    this.editCodesDisplay = true;
  }
  editDisplaySettingsClosed(data): void {
    this.editCodesDisplay = false;
    this.prepareCodesTableHeader();
  }
  serverError(): void {
    this._notif.create({
      title: 'There was an error',
      message: 'Please try again later.',
      type: 'error',
      autoClose: true,
      duration: 3000,
      position: 'top-right'
    });
  }
  isCodesActionAllowed(action): boolean {
    return this.allowedActions.includes(action);
  }
}
