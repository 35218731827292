// SHARED COMPONENTS, DIRECTIVES AND PIPES
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';

import { PropertyReportPreviewComponent } from './features/property-report-preview/property-report-preview.component';
import { PropertyAddEditComponent } from './features/property-add-edit/property-add-edit.component';
import { ClientAddEditComponent } from './features/client-add-edit/client-add-edit.component';

import { SyncReportData } from './services/sync-report-data';
import { SharedModule } from '../shared/shared.module';
import { FormHelperService } from './services/form-helper-service';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PDFExportModule
  ],
  declarations: [
    PropertyReportPreviewComponent,
    PropertyAddEditComponent,
    ClientAddEditComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PropertyReportPreviewComponent,
    PropertyAddEditComponent,
    ClientAddEditComponent

  ],
  providers: [
    SyncReportData,
    FormHelperService
  ]
})
export class DynSharedModule { }
