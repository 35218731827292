import { Injectable, EventEmitter, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

export interface SelectTableOptions {
  doFilter: boolean;
  noDataDisplayMessage?: string;
  displayDelimiter?: string;
  tableColumns: {
    key: string;
    displayName: string;
    showInTable: boolean;
    showInInput: boolean;
    isPK: boolean;
  }[];
  onChange?: EventEmitter<any>;
  onKeyDown?: EventEmitter<any>;
  onKeyUp?: EventEmitter<any>;
}

@Injectable()
export class SelectTableService {
  renderer: Renderer2;

  onKeyUp: EventEmitter<any> = new EventEmitter();
  onKeyDown: EventEmitter<any> = new EventEmitter();
  onChange: EventEmitter<any> = new EventEmitter();

  _isValid = true; // If settings were valid
  _inputRef = null; // Reference to input
  _tableContainer = null; // Container that holds all DOM
  _table = null; // Reference to table
  _tableHead = null; // Reference to table header
  _tableBody = null; // Reference to table body
  _parent = null; // Reference to parent
  _options: SelectTableOptions;
  _data;
  filterData = []; // Data that is shown in table
  filterTimeout = 500; // Timeout on keyup before search
  filterTimeoutObject = null; // Timeout object for filter search
  activeRowData = null; // Selected row data

  dontTriggerOnKey = [
    16, // Shift
    17, // Ctrl
    18, // Alt
    19, // PBreak
    20, // Caps
    27, // Esc
    33, // PgUp
    34, // PgDown
    35, // End
    36, // Home
    37, // Left
    38, // Up
    39, // Right
    40, // Down
    45, // Insert
    46, // Delete
    112, // F1
    113, // F2
    114, // F3
    115, // F4
    116, // F5
    117, // F6
    118, // F7
    119, // F8
    120, // F9
    121, // F10
    122, // F11
    123, // F12
    145 // Scroll lock
  ];

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Function to create new instance for selecttable
   *
   * After creating you will have available functions:
   * selectTableObject will now have next functions attached:
   * showTable, // Opens dropdown
   * hideTable, // Closes dropdown
   * getValue, // Return selected row data
   * setValue, // Requires JSON with all pk keys and values, example bellow
   * getData, // Returns all data
   * setData, //Set new dataset
   * getFilteredData // Returns filtered data(if using doFilter == true)
   *
   * this.test.setValue({ id: 2 }).then((res) => {
   *     console.log(res);
   * }, (err) => {
   *     console.log(err);
   * });
   *
   *
   * USAGE EXAMPLE
   ````
      HTML -
          <mat-form-field>
              <input matInput placeholder="Text" #test>
          </mat-form-field>

      TS -
          @ViewChild('test') element: ElementRef;
          import { SelectTable } from '~selectTable/main';

          ...
          constructor(private selectTable: SelectTable) {

          }
          ...

          const selectTableObject = this.selectTable.run(this.element.nativeElement, [{
              doFilter: true, //If it should filter on keyup
              displayDelimiter: ',', //Delimiter used for spliting values
              tableColumns: [// Columns that will be in dataset
              { key: 'id', displayName: 'Id', showInTable: true, showInInput: false, isPK: true },
              { key: 'name', displayName: 'Name', showInTable: true, showInInput: true, isPK: false }
              ]
          }],
              [ // Data
                  { id: '1', name: 'Test1' },
                  { id: '2', name: 'Test2' },
                  { id: '3', name: 'Test3' }
              ]
          );

          // { key: 'name', displayName: 'Name', showInTable: true, showInInput: true, isPK: false }
          // key: Keyname in dataset,
          // displayName: How it should show it in table head
          // showInTable: If it should show it in table
          // showInInput: If it will show as final result in input (delimited with displayDelimiter if more than one);
          // isPK: IF it is primary key, it must have at least one PK (will be used for setting data);
  ````
  For editing any css use root class .selectTableDir (div that contains table and floats under input);
  Inside it there will be table;
  */
  run(element: HTMLElement, options: SelectTableOptions[], data?: any[]) {
    this._inputRef = element;
    this._options = options[0];
    this._data = data === undefined ? [] : data;
    this.filterData = Object.assign([], this._data);

    const $this = this;
    runTableGenerator();

    return {
      showTable: showTable,
      hideTable: hideTable,
      getValue: getValue,
      setValue: setValue,
      getData: getData,
      setData: setData,
      clearValue: clearValue,
      getFilteredData: getFilteredData
    };

    function runTableGenerator() {
      $this._parent = $this._inputRef.parentNode;
      $this._tableContainer = $this.renderer.createElement('div');
      $this._tableContainer.className = 'selectTableDir';
      hideTable();
      // Prepare thead tbody and table

      $this._table = $this.renderer.createElement('table'); // Render it to table container
      $this._tableContainer.appendChild($this._table);

      $this._tableHead = $this.renderer.createElement('thead');
      $this._table.appendChild($this._tableHead);

      $this._tableBody = $this.renderer.createElement('tbody');
      $this._table.appendChild($this._tableBody);

      runStyles();
      createTableHeader();
      createTableBody();

      // Add table container to DOM;
      $this._parent.prepend($this._tableContainer);

      // Listen for input focus and blur - show hide table
      $this._inputRef.addEventListener('focus', () => {
        showTable();
      });
      $this._inputRef.addEventListener('blur', () => {
        setTimeout(() => {
          hideTable();
        }, 100);
      });

      $this._inputRef.addEventListener('keyup', e => {
        if ($this._options.onKeyUp) {
          $this._options.onKeyUp.emit(e);
        }
        if ($this.dontTriggerOnKey.indexOf(e.keyCode) === -1) {
          if ($this._options.doFilter === true) {
            runFilter($this._inputRef.value);
          }
        }
      });

      $this._inputRef.addEventListener('keydown', e => {
        if ($this._options.onKeyDown) {
          $this._options.onKeyDown.emit(e);
        }
      });
    }

    /**
     * Open table dropdown;
     */
    function showTable() {
      $this._tableContainer.setAttribute('hiddenSelect', false);
    }

    /**
     * Hide table dropdown;
     */
    function hideTable() {
      $this._tableContainer.setAttribute('hiddenSelect', true);
    }

    /**
     * Create table head;
     */
    function createTableHeader() {
      const tr = $this.renderer.createElement('tr');
      for (const row of $this._options.tableColumns) {
        if (row.showInTable === true) {
          // If it is set so it shows in table
          const th = $this.renderer.createElement('th'); // Create th
          th.innerHTML = row.displayName !== undefined ? row.displayName : row.key; // Add th content
          tr.appendChild(th);
        }
      }
      // Listen for row click and stop propagation so it wont focus element again
      tr.addEventListener('click', event => {
        event.stopPropagation();
      });
      $this._tableHead.appendChild(tr); // Add tr to thead
    }

    /**
     * Create table body;
     */
    function createTableBody() {
      $this._tableBody.innerHTML = ''; // Reset tbody to blank
      if ($this.filterData.length === 0) {
        const div = $this.renderer.createElement('div');
        div.classList = 'noDataDiv';
        const label = $this.renderer.createElement('label');
        label.innerHTML =
          $this._options.noDataDisplayMessage !== undefined &&
          $this._options.noDataDisplayMessage !== null &&
          $this._options.noDataDisplayMessage !== ''
            ? $this._options.noDataDisplayMessage
            : 'No data';

        div.appendChild(label);
        $this._tableBody.appendChild(div);
      } else {
        for (const row of $this.filterData) {
          // Go through DB response
          const tr = $this.renderer.createElement('tr'); // Create row
          tr.style.cursor = 'pointer'; // Update row styles
          let hasData = false; // Validator
          for (const columns of $this._options.tableColumns) {
            // Go through columns from settings
            const key = columns.key;
            // Check if actual value exists in DB response and if it allowed to show in table
            if (row[key] !== undefined && columns.showInTable === true) {
              hasData = true; // Update that it actualy had one column
              const td = $this.renderer.createElement('td'); // Create TD and append to row
              td.innerHTML = row[key] === '' ? '--' : row[key];
              tr.appendChild(td);
            }
          }
          if (hasData) {
            // Check if row had any data
            // Listen for click
            tr.addEventListener('click', event => {
              event.stopPropagation();
              selectRow(row); // Update selection
            });
            $this._tableBody.appendChild(tr);
          }
        }
      }
    }

    /**
     * Select row action callback;
     */
    function selectRow(rowData) {
      console.log('row', rowData);
      if (rowData && rowData !== undefined && rowData != null && rowData !== {}) {
        $this.activeRowData = rowData; // Set that activeRowData for this instance is selection
        const delimiter = $this._options.displayDelimiter === undefined ? ', ' : $this._options.displayDelimiter; // Collect delimiter for settings
        let inputValue = '';
        // Go through columns from settings, and if it is allowed to show in input,
        // append its value to inputValue with delimiter
        for (const columns of $this._options.tableColumns) {
          const key = columns.key;
          const value = rowData[key];
          if (columns.showInInput === true && value !== '') {
            inputValue = inputValue + delimiter + value;
          }
        }
        inputValue = inputValue.replace(delimiter, ''); // Remove first delimiter
        $this._inputRef.value = inputValue; // Change value
        /////
        // $this.onSelectChange.emit({ data: rowData, textValue: inputValue }); // Emit action onSelectChange so controller can listen to it
        hideTable();
        $this._inputRef.blur();
      }
    }

    /**
     * Run filter for data on keyup
     */
    function runFilter(value) {
      $this.filterData = [];
      for (const row of $this._data) {
        const keys = Object.keys(row);
        for (const key of keys) {
          const rowKeyValue = row[key].toString().toLowerCase();
          const inputValue = value.toString().toLowerCase();
          if (rowKeyValue.indexOf(inputValue) === 0 || rowKeyValue === inputValue) {
            $this.filterData.push(row);
            break;
          }
        }
      }
      createTableBody();
    }

    /**
     * Set dataset;
     */
    function setData(data_: any[]) {
      $this._data = data === undefined || data === null ? [] : data;
      $this.filterData = $this._data;
      createTableBody();
    }

    /**
     * Returns all data for select table;
     */
    function getData() {
      return $this._data;
    }

    /**
     * Return filtered data;
     */
    function getFilteredData() {
      return $this.filterData;
    }

    /**
     * Get selected row data;
     */
    function getValue() {
      return $this.activeRowData;
    }

    /**
     * Set value (row), it expects all primary keys to be sent
     */
    function setValue(primaryKeyValue) {
      return new Promise((resolve, error) => {
        const primaryKeys = Object.keys(primaryKeyValue);
        const notFound = [];
        for (const column of $this._options.tableColumns) {
          if (column.isPK === true && primaryKeys.indexOf(column.key) === -1) {
            notFound.push(column.key);
          }
        }

        if (notFound.length > 0) {
          error({
            success: false,
            errorMessage: 'Error assigning value to selectTable, missing keys:' + notFound.toString()
          });
        } else {
          const index = $this._data.findIndex((value, i) => {
            let valid = true;
            for (const key of primaryKeys) {
              if (value[key].toString() !== primaryKeyValue[key].toString()) {
                valid = false;
              }
            }
            return valid === true;
          });
          if (index !== -1) {
            selectRow($this._data[index]);
            resolve({ success: true, errorMessage: null });
            $this.filterData = $this._data;
            createTableBody();
          } else {
            resolve({
              success: false,
              errorMessage: 'No row found to set as value'
            });
          }
        }
      });
    }

    /**
     * Clear value, it clears current active row
     */
    function clearValue() {
      $this._inputRef.value = '';
      $this.activeRowData = null;
    }

    // Generate styles and add them to body
    function runStyles() {
      const styles = $this.renderer.createElement('style');
      styles.setAttribute('_table_selectTable', true);
      if ($this.document.querySelectorAll('style[_table_selectTable]')[0]) {
        return;
      }
      let inputHeight = $this._inputRef.offsetHeight + 'px';
      inputHeight = '2.15em';
      styles.innerHTML =
        `
            .selectTableDir{
                width:100%;
                background: white;
                position:absolute;
                left:0;
                top:` +
        inputHeight +
        `;
                max-height:10em;
                min-height:4em;
                display:block;
                z-index:999;
                box-shadow: 0 3px 6px 0 rgba(64, 83, 100, 0.43);
                transition: all 0.5s ease;
                overflow:scroll;
            }
            .selectTableDir[hiddenSelect="true"]{
                display:none;
            }
            .selectTableDir[hiddenSelect="false"]{
                display:block;
            }
            .selectTableDir table{
                width: 100%;
                max-width: 100%;
                background-color: #fff !important;
                font-family: 'RobotoDraft', 'Roboto', 'Helvetica Neue, Helvetica, Arial', sans-serif;
                font-style: normal;
                font-weight: 300;
                font-size: 1.4rem;
                line-height: 2rem;
                letter-spacing: 0.01rem;
                color: #212121;
                background-color: #f5f5f5;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                text-rendering: optimizeLegibility;
                display:table;
            }
            .selectTableDir table thead{
                display: table-header-group;
                vertical-align: middle;
                border-color: inherit;
            }

            .selectTableDir table thead tr, .selectTableDir table tbody tr{
                transition: all 0.3s ease;
                display: table-row;
                vertical-align: inherit;
                border-color: inherit;
            }
            .selectTableDir table thead tr th{
                font-weight: 400;
                color: #757575;
                vertical-align: bottom;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                background-color:#fafafa;
                text-align: left;
                padding: 1.6rem;
                padding-top:.5rem;
                padding-bottom:.5rem;
                transition: all 0.3s ease;
                white-space: nowrap;
                position:sticky;
                top:0;
            }
            .selectTableDir table tbody{
                display: table-row-group;
                vertical-align: middle;
                border-color: inherit;
                border-collapse: separate;
            }
            .selectTableDir table tbody tr td{
                font-weight: 400;
                color: #757575;
                vertical-align: bottom;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                text-align: left;
                padding: 1.6rem;
                padding-top:.5rem;
                padding-bottom:.5rem;
                transition: all 0.3s ease;
                display: table-cell;
                vertical-align: top;
                border-top: 0;
                transition: all 0.3s ease;
            }
            .selectTableDir table tbody tr:last-child td{
                border-bottom:0 !important;
            }
            .selectTableDir table tbody div.noDataDiv{
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
            `;
      $this.document.body.appendChild(styles);
    }
  }
}

/*
EXAMPLE FOR USING;
HTML -
    <mat-form-field>
        <input matInput placeholder="Text" #test>
    </mat-form-field>

TS -
    @ViewChild('test') element: ElementRef;
    import { SelectTable } from '~selectTable/main';

    ...
    constructor(private selectTable: SelectTable) {

    }
    ...


    const selectTableObject = this.selectTable.run(this.element.nativeElement, [{
        doFilter: true, //If it should filter on keyup
        displayDelimiter: ',', //Delimiter used for spliting values
        tableColumns: [// Columns that will be in dataset
        { key: 'id', displayName: 'Id', showInTable: true, showInInput: false, isPK: true },
        { key: 'name', displayName: 'Name', showInTable: true, showInInput: true, isPK: false }
        ]
    }],
        [ // Data
            { id: '1', name: 'Test1' },
            { id: '2', name: 'Test2' },
            { id: '3', name: 'Test3' }
        ]
    );


    // selectTableObject will now have next functions attached:
    // showTable, // Opens dropdown
    // hideTable, // Closes dropdown
    // getValue, // Return selected row data
    // setValue, // Requires JSON with all pk keys and values, example bellow
    // getData, // Returns all data
    // setData, //Set new dataset
    // getFilteredData // Returns filtered data(if using doFilter == true)

    // this.test.setValue({ id: 2 }).then((res) => {
    //     console.log(res);
    // }, (err) => {
    //     console.log(err);
    // });


    // { key: 'name', displayName: 'Name', showInTable: true, showInInput: true, isPK: false }
    // key: Keyname in dataset,
    // displayName: How it should show it in table head
    // showInTable: If it should show it in table
    // showInInput: If it will show as final result in input (delimited with displayDelimiter if more than one);
    // isPK: IF it is primary key, it must have at least one PK (will be used for setting data);

*/
