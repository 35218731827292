import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class NavbarStateService {
  private navbarStateService = new Subject<any>();
  private navbarIsOpen: boolean;

  private renderer: Renderer2;
  private listenerFn: () => void;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  initNavbarState() {
    if (window.innerWidth <= 992) {
      this.navbarIsOpen = false;
    } else {
      this.navbarIsOpen = true;
    }

    this.setNavbarState(this.navbarIsOpen);
    // console.log('Open: ' + this.navbarIsOpen, '\nWidth: ' + window.innerWidth + 'px');
  }

  /** Just toggles the state, no checking at all => Called from HAMBURGER ICON */
  toggleNavbarState(): void {
    this.navbarIsOpen = !this.navbarIsOpen;
    this.setNavbarState(this.navbarIsOpen);
  }

  /** subscibe to recieve state and selected item as observable */
  getNavbarState(): Observable<any> {
    return this.navbarStateService.asObservable();
  }

  private setNavbarState(state: boolean): void {
    // remove reference to eventListener if exists
    if (this.listenerFn) {
      this.listenerFn();
    }

    if (state === true) {
      // open
      document.body.classList.add('navbar-open');
      document.body.classList.remove('navbar-closed');

      if (window.innerWidth <= 992) {
        // add reference to eventListener
        setTimeout(() => {
          this.listenerFn = this.renderer.listen(document, 'click', this.onClick.bind(this));
        }, 10);
      }
    } else {
      // close
      document.body.classList.remove('navbar-open');
      document.body.classList.add('navbar-closed');
    }

    this.navbarIsOpen = state;
    this.navbarStateService.next({ state: this.navbarIsOpen });
  }

  /**
   * event listener to close navbar if on device (width <= 992) when user clicks away from component
   * @param event click event from user
   */
  private onClick(event): void {
    const ignoredElements = '.app-navbar-wrapper, .app-navbar-wrapper *, app-header .menu-icon';

    if (!event.target.matches(ignoredElements)) {
      this.setNavbarState(false);
    }
  }
}
