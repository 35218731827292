import { Directive, ElementRef, OnInit, OnDestroy, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '.content'
})
export class ContentHeightDirective implements OnInit, OnDestroy {
  content;
  section;
  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    this.content = this.el.nativeElement;

    setTimeout(() => {
      this.setHeight();
    }, 0);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setHeight();
  }

  setHeight() {
    const header = document.querySelector('.app-header-wrapper');
    const section = document.querySelector('.app-section-wrapper');
    const footer = section.querySelector('.app-footer-wrapper') ? section.querySelector('.app-footer-wrapper').clientHeight : 0;

    const offsetTop = this.content.offsetTop - header.clientHeight;

    const elHeight = section.clientHeight - offsetTop - footer;
    this.content.style.height = elHeight + 'px';

    // console.log(
    //   'header.clientHeight', header.clientHeight,
    //   'section.clientHeight', section.clientHeight,
    //   'content.offsetTop', offsetTop,
    //   'footer', footer, 'height', elHeight
    // );
  }

  ngOnDestroy(): void { }
}
