import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AppGlobal } from '../../../core/services/app.global';
import { Database } from '../../../core/services/database';
import { StorageService } from '../../../core/services/storage-service';
import { MessageService } from 'primeng/components/common/messageservice';
import { UserStateService } from '../../../core/services/userstate.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  msgs = [];
  appName: string;
  emailPattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  formSubmited = false;
  userEmail = new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]);
  userPassword = new FormControl('', [Validators.required, Validators.minLength(6)]);
  userRemember = false;
  loginError = null;
  passwordType = 'password';
  passwordShow = false;

  loginStep: 'login' | 'sInstitution' | 'sInstitutionRole' | 'sInstitutionRoleGroup';
  userLoginData: any;
  userSelectInstitutionIndex: number;
  userSelectInstitutionRoleIndex: number;
  userSelectInstitutionRoleGroupIndex: number;

  constructor(
    _global: AppGlobal,
    private router: Router,
    private _database: Database,
    private _storage: StorageService,
    private messageService: MessageService,
    private user: UserStateService
  ) {
    this.appName = _global.get('appName');

    this.loginStep = 'login';
    this.userSelectInstitutionIndex = null;
    this.userSelectInstitutionRoleIndex = null;
    this.userSelectInstitutionRoleGroupIndex = null;
  }

  ngOnInit() {}

  togglePasswordShow() {
    this.passwordShow = !this.passwordShow;
    if (this.passwordShow) {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }

  loginHangler() {
    this.loginError = null;
    this.formSubmited = true;
    if (this.userEmail.valid && this.userPassword.valid) {
      this._database.loginUser(this.userEmail.value.trim(), this.userPassword.value.trim(), null, null, null).then(
        (loginResponse: any) => {
          if (loginResponse.success === false && loginResponse.code === 550) {
            this.loginError = loginResponse.message || 'Wrong Credentials';

            this.msgs.push({
              severity: 'error',
              summary: 'Login error',
              detail: loginResponse.message || 'Please check your email and password are correct'
            });
          } else if (loginResponse.success === true && loginResponse.code === 200) {
            this.rerouteUser(loginResponse.data[0]);
          } else if (loginResponse.success === true && loginResponse.code === 201) {
            this.userLoginData = loginResponse.data;
            if (this.userLoginData.length === 1) {
              // Single institution;
              this.selectInstitution(0, true);
              return;
            }
            this.loginStep = 'sInstitution';
          }
        },
        error => {
          if (error.code === 550) {
            this.loginError = error.message || 'Wrong Credentials';

            this.msgs.push({
              severity: 'error',
              summary: 'Login error',
              detail: error.message || 'Please check your email and password are correct'
            });

            return;
          }
          // Server error;
          this.msgs = [];
          this.msgs.push({ severity: 'error', summary: 'Server error', detail: 'Please try again later' });
        }
      );
    }
  }

  rerouteUser(data) {
    this.user.loginUser(data, true);
  }

  selectInstitution(index: number, moveNext: boolean) {
    this.userSelectInstitutionIndex = index;
    if (moveNext) {
      this.nextForInstitution();
    }
  }
  backForInstitution() {
    this.userSelectInstitutionIndex = null;
    this.loginStep = 'login';
  }
  nextForInstitution() {
    if (this.userLoginData[this.userSelectInstitutionIndex].roles.length === 1) {
      this.selectInstitutionRole(0, true);
      return;
    }
    this.loginStep = 'sInstitutionRole';
  }

  selectInstitutionRole(index: number, moveNext: boolean) {
    this.userSelectInstitutionRoleIndex = index;
    if (moveNext) {
      this.nextForInstitutionRole();
    }
  }
  backForInstitutionRole() {
    this.userSelectInstitutionRoleIndex = null;
    this.loginStep = 'sInstitution';
  }
  nextForInstitutionRole() {
    if (
      this.userLoginData[this.userSelectInstitutionIndex].roles[this.userSelectInstitutionRoleIndex].userTypeGroups
        .length === 1
    ) {
      this.selectInstitutionRoleGroup(0, true);
      return;
    } else if (
      this.userLoginData[this.userSelectInstitutionIndex].roles[this.userSelectInstitutionRoleIndex].userTypeCode === 1
    ) {
      this.loginUserForInstitutionAndRole();
      return;
    }
    this.loginStep = 'sInstitutionRoleGroup';
  }

  selectInstitutionRoleGroup(index, moveNext: boolean) {
    this.userSelectInstitutionRoleGroupIndex = index;
    if (moveNext) {
      this.nextForInstitutionRoleGroup();
    }
  }
  backForInstitutionRoleGroup() {
    this.userSelectInstitutionRoleGroupIndex = null;
    this.loginStep = 'sInstitutionRole';
  }
  nextForInstitutionRoleGroup() {
    this.loginUserForInstitutionAndRole();
  }

  loginUserForInstitutionAndRole() {
    // ask for new login

    const institutionCode = this.userLoginData[this.userSelectInstitutionIndex].institutionCode;
    const userTypeCode =
      this.userLoginData[this.userSelectInstitutionIndex].roles[this.userSelectInstitutionRoleIndex].userTypeCode;
    const userGroupValue =
      this.userSelectInstitutionRoleGroupIndex === null
        ? null
        : this.userLoginData[this.userSelectInstitutionIndex].roles[this.userSelectInstitutionRoleIndex].userTypeGroups[
            this.userSelectInstitutionRoleGroupIndex
          ].userGroupValue;
    this._database
      .loginUser(
        this.userEmail.value.trim(),
        this.userPassword.value.trim(),
        institutionCode,
        userTypeCode,
        userGroupValue
      )
      .then(
        (loginResponse: any) => {
          console.log('loginUser', loginResponse);
          if (loginResponse.success === true && loginResponse.code === 200) {
            this.rerouteUser(loginResponse.data[0]);
          } else {
            this.msgs = [];
            this.msgs.push({ severity: 'error', summary: 'Something went wrong', detail: 'Please try again later' });
          }
        },
        error => {
          console.error('Login error', error);
          // Server error;
          this.msgs = [];
          if (error.success === false && error.code === 550) {
            this.loginError = error.message || 'Wrong Credentials';
            this.msgs.push({
              severity: 'error',
              summary: 'Login error',
              detail: error.message || 'Please check your email and password are correct'
            });
          } else {
            this.msgs.push({ severity: 'error', summary: 'Server error', detail: 'Please try again later' });
          }
        }
      );
  }
}
