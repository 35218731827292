import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { OverlayPanel } from 'primeng/overlaypanel';
import { Database } from '../../../core/services/database';
import { StorageService } from '../../../core/services/storage-service';
import { NotificationService } from '../../../core/notification/notification.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  // userData = {};
  // addNewUserGroupView = false;

  // activeEditUserIndex: any;
  // activeEditUser: any = {};
  // activeUserGroupDropdown: { userGroup: any; i: number };

  // editUserView = false;
  activeDropdown: { user: any; i: number; };
  users = [];
  userTypes: { userTypeCode: number; userTypeName: string }[];
  allowedUserTypes: { userTypeCode: number; userTypeName: string }[];
  userStatus: { userStatusCode: string; userStatusName: string }[];
  institutionUserGroup: {
    userGroupCode: string;
    userGroupName: string;
    userGroupTableName: string;
    userGroupCodeColumnName: string;
    userGroupNameColumnName: string;
    userGroupGatherName: string;
  }[];

  institutionUserGroupValues = [];
  loadingStatus = { isLoading: true, text: 'Loading', show: true, class: 'default' };
  inviteUserView = false;

  inviteUserData: {
    userEmail: string;
    userType: { userTypeCode: number; userTypeName: string },
    userGroup: {
      userGroupCode: string;
      userGroupName: string;
      userGroupTableName: string;
      userGroupCodeColumnName: string;
      userGroupNameColumnName: string;
      userGroupGatherName: string;
    },
    userGroupValue: { any }
  };

  // inviteUserDataForUser: {
  //   userEmail: string;
  //   userType: { userTypeCode: number; userTypeName: string },
  //   userGroup: {
  //     userGroupCode: string;
  //     userGroupName: string;
  //     userGroupTableName: string;
  //     userGroupCodeColumnName: string;
  //     userGroupNameColumnName: string;
  //     userGroupGatherName: string;
  //   },
  //   userGroupValue: { any }
  // };

  constructor(
    private _notif: NotificationService,
    private _database: Database,
    private _storage: StorageService,
    private router: Router) {
    // this.activeUserGroupDropdown = { userGroup: {}, i: null };
    this.userTypes = [];
    this.allowedUserTypes = [];
    this.userStatus = [];
    this.institutionUserGroup = [];
    this.inviteUserData = {
      userEmail: null,
      userType: null,
      userGroup: null,
      userGroupValue: null
    };
    // this.inviteUserDataForUser = {
    //   userEmail: null,
    //   userType: null,
    //   userGroup: null,
    //   userGroupValue: null
    // };
  }

  ngOnInit() {
    this.loadGathers();
    this.loadUsers();
  }

  loadGathers() {
    this._database.executeRequest('/api/userTypeGather', 'get', {}).then((userTypesResponse: any) => {
      this.userTypes = userTypesResponse.data;
    });

    this._database.executeRequest('/api/userStatusGather', 'get', {}).then((userStatusResponse: any) => {
      this.userStatus = userStatusResponse.data;
    });

    this._database.executeRequest('/api/institutionUserGroupGather', 'get', {}).then((institutionUserGroupResponse: any) => {
      this.institutionUserGroup = institutionUserGroupResponse.data;
    });
  }


  loadUsers() {
    this._database.executeRequest('/api/userSingle', 'get', {}).then((users: any) => {
      if (users.success === true) {
        this.users = users.data;
        this.loadingStatus = { isLoading: false, text: '', show: false, class: 'default' };
      } else {
        this.users = [];
        this.loadingStatus = { isLoading: false, text: 'Server error, please try again later', show: true, class: 'error' };
      }
    }, (err) => {
      this.loadingStatus = { isLoading: false, text: 'Server error, please try again later', show: true, class: 'error' };
    });
  }

  deleteUser(user, index) {
    this._notif.create({
      title: 'Delete user?',
      message: 'This will permanently delete the record.',
      type: 'info',
      autoClose: false,
      backdrop: true,
      position: 'center-center',
      buttons: [
        {
          text: 'Delete',
          action: (_data) => {
            this._database.executeRequest('/api/userSingle/' + user.userCode, 'delete', {}).then((response: any) => {
              if (response.success === true) {
                this._notif.create({
                  title: 'Success',
                  message: 'User deleted',
                  type: 'success',
                  autoClose: true,
                  duration: 3000,
                  position: 'top-right'
                });
                this.users.splice(index, 1);
              } else {
                this.serverError();
              }
            }, (err) => {
              this.serverError();
            });
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          action: (() => { })
        }
      ]
    });
  }

  hasExpired(date) {
    return new Date(date).getTime() < new Date().getTime() ? true : false;
  }

  inviteUser() {
    this.inviteUserData = {
      userEmail: null,
      userType: null,
      userGroup: null,
      userGroupValue: null
    };
    this.inviteUserView = true;
  }

  sendInvitation() {
    const data = {
      userEmail: this.inviteUserData.userEmail,
      userTypeCode: this.inviteUserData.userType == null ? null : this.inviteUserData.userType.userTypeCode,
      userGroupCode: this.inviteUserData.userGroup == null ? null : this.inviteUserData.userGroup.userGroupCode,
      userGroupValue: this.inviteUserData.userGroupValue == null ? null : this.inviteUserData.userGroupValue[this.inviteUserData.userGroup.userGroupCodeColumnName],
      userGroupValueName: this.inviteUserData.userGroupValue == null ? null : this.inviteUserData.userGroupValue[this.inviteUserData.userGroup.userGroupNameColumnName]
    };

    this._database.executeRequest('/api/userSingle/invite', 'post', data).then((userInviteResponse: any) => {
      if (userInviteResponse.success === true) {
        this._notif.create({
          title: 'Success',
          message: 'Invitation sent.',
          type: 'success',
          autoClose: true,
          duration: 3000,
          position: 'top-right'
        });
        this.loadUsers();
        this.inviteUserView = false;
        this.inviteUserData = {
          userEmail: null,
          userType: null,
          userGroup: null,
          userGroupValue: null
        };
      } else {
        this.serverError();
      }
    }, (err) => {
      this.serverError();
    });
  }

  serverError() {
    this._notif.create({
      title: 'There was an error',
      message: 'Please try again later.',
      type: 'error',
      autoClose: true,
      duration: 3000,
      position: 'top-right'
    });
  }

  showOptions($ev, user, i, overlay: OverlayPanel) {
    this.activeDropdown = { user: user, i: i };
    overlay.show($ev);
  }



  userTypeChanged() {
    this.inviteUserData.userGroup = null;
    this.inviteUserData.userGroupValue = null;
    if (this.institutionUserGroup.length === 1 && this.inviteUserData.userType !== null && this.inviteUserData.userType.userTypeCode !== 1) {
      this.inviteUserData.userGroup = this.institutionUserGroup[0];
      this.userGroupChanged();
    }
  }

  userGroupChanged() {
    this._database.executeRequest('/api/' + this.inviteUserData.userGroup.userGroupGatherName + '/null/null', 'get', {}).then((response: any) => {
      this.institutionUserGroupValues = response.data;
    });
  }


  formatDate(_date) {
    const date = new Date(_date);
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
    const year = date.getFullYear() < 10 ? '0' + date.getFullYear() : date.getFullYear();
    return day + '.' + month + '.' + year;
  }



  editUser(user, index) {
    this.router.navigate(['/userManagement/' + user.userCode + '/access', { userFullName: user.userFullName, userEmail: user.userEmail }]);
    // this.addNewUserGroupView = false;
    // this.inviteUserDataForUser = {
    //   userEmail: null,
    //   userType: null,
    //   userGroup: null,
    //   userGroupValue: null
    // };
    // this.editUserView = true;
    // this.activeEditUser = user;
    // this.activeEditUserIndex = index;

  }


}
