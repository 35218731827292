// SHARED COMPONENTS, DIRECTIVES AND PIPES (not services)
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// external modules
import { PrimeNgModule } from './prime-ng.module';
import { ChartsModule } from 'ng2-charts';
import { QuillModule } from 'ngx-quill';
import { LightboxModule } from 'ngx-lightbox';

// components
import { DataTableComponent } from './data-table/data-table.component';
import { DataViewComponent } from './data-view/data-view.component';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { FilterAsideComponent } from './filter-aside/filter-aside.component';
import { FilterListComponent } from './filter-list/filter-list.component';
import { CHeaderComponent } from './c-header/c-header.component';

// directives
import { ContentHeightDirective } from './directives/content-height/content-height.directive';
import { LoadingDirectiveDirective } from './directives/loading-directive/loading-directive.directive';
import { IconsDirective } from './directives/icons/icons.directive';
import { TabsDirective } from './directives/tabs/tabs.directive';
import { ChartsDirective } from './directives/charts/charts.directive';
import { RippleDirective } from './directives/ripple/ripple.directive';
import { ProcessingActionDirective } from './directives/processing-action/processing-action.directive';
import { PermissionDirective } from './directives/permission-directive/permission-directive';

// pipes
import { PercentagePipe } from './pipes/percentage.pipe';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PrimeNgModule, ChartsModule, QuillModule, LightboxModule],
  declarations: [
    DataTableComponent,
    DataViewComponent,
    AppLoaderComponent,
    SubheaderComponent,
    FilterAsideComponent,
    FilterListComponent,
    CHeaderComponent,

    ContentHeightDirective,
    LoadingDirectiveDirective,
    IconsDirective,
    TabsDirective,
    ChartsDirective,
    RippleDirective,
    ProcessingActionDirective,
    PermissionDirective,

    PercentagePipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    PrimeNgModule,
    ChartsModule,
    QuillModule,
    LightboxModule,

    DataTableComponent,
    DataViewComponent,
    AppLoaderComponent,
    SubheaderComponent,
    FilterAsideComponent,
    FilterListComponent,
    CHeaderComponent,

    ContentHeightDirective,
    LoadingDirectiveDirective,
    IconsDirective,
    TabsDirective,
    ChartsDirective,
    RippleDirective,
    ProcessingActionDirective,
    PermissionDirective,

    PercentagePipe
  ],
  providers: []
})
export class SharedModule {}
