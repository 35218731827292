<p-dialog [header]="rowData ? 'Update' : 'Insert'" [draggable]="false" [(visible)]="show" modal="modal" width="650" [responsive]="true"
  [appendTo]="'body'" (onHide)="emmitClose()">

  <form [formGroup]="codesForm" id="codesForm" novalidate (ngSubmit)="saveCodesForm()" [class.ng-submited]="codesFormSubmited"
    *ngIf="codesForm">
    <div class="inline-form-info">
      <ng-container *ngFor="let inputRow of codesParsedData">
        <app-form-control-autocomplete *ngIf="inputRow.isVisible && inputRow.inputType === 'autocomplete'" [formGroup]="codesForm"
          [inputData]="inputRow"></app-form-control-autocomplete>
        <app-form-control-checkbox *ngIf="inputRow.isVisible && inputRow.inputType === 'checkbox'" [formGroup]="codesForm" [inputData]="inputRow"></app-form-control-checkbox>
        <app-form-control-color *ngIf="inputRow.isVisible && inputRow.inputType === 'color'" [formGroup]="codesForm" [inputData]="inputRow"></app-form-control-color>
        <app-form-control-date *ngIf="inputRow.isVisible && inputRow.inputType === 'date'" [formGroup]="codesForm" [inputData]="inputRow"></app-form-control-date>
        <app-form-control-dropdown *ngIf="inputRow.isVisible && inputRow.inputType === 'select'" [formGroup]="codesForm" [inputData]="inputRow"></app-form-control-dropdown>
        <app-form-control-number *ngIf="inputRow.isVisible && inputRow.inputType === 'number'" [formGroup]="codesForm" [inputData]="inputRow"></app-form-control-number>
        <app-form-control-text-editor *ngIf="inputRow.isVisible && inputRow.inputType === 'texteditor'" [formGroup]="codesForm" [inputData]="inputRow"></app-form-control-text-editor>
        <app-form-control-textbox *ngIf="inputRow.isVisible && inputRow.inputType === 'text'" [formGroup]="codesForm" [inputData]="inputRow"></app-form-control-textbox>
        <app-form-control-textarea *ngIf="inputRow.isVisible && inputRow.inputType === 'textarea'" [formGroup]="codesForm" [inputData]="inputRow"></app-form-control-textarea>
      </ng-container>
    </div>

  </form>
  <p-footer>
    <button class="btn btn-default" (click)="emmitClose()">Close</button>
    <button class="btn btn-default" form="codesForm" *ngIf="checkAllowedSaveState()">Save</button>
  </p-footer>
</p-dialog>