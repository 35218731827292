<div class="subheader-wrapper">
  <div class="s-left">
    <button class="btn btn-default btn-nav-back" (click)="backHandler()" *ngIf="back">
      <span [appIcon]="'tt-chevron-left'"></span>
      Back
    </button>

    <ng-content select="[subheader-left]"></ng-content>
  </div>

  <div class="s-right">
    <h2 class="title">{{title}}</h2>
    <ng-content select="[subheader-right]"></ng-content>
  </div>
</div>
