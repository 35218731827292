import { Injectable } from '@angular/core';
import { CodesRaw, CodesParsed, CodesTableHeader } from '../interfaces/codes.types';
import { Database, DatabaseResponse } from '../../../core/services/database';
import { NotificationService } from '../../../core/notification/notification.service';


@Injectable()
export class CodesReactiveService {

    constructor(
        private database: Database,
        private _notif: NotificationService
    ) {
    }

    public getCodes(codesTable): Promise<CodesRaw[]> {
        return new Promise((resolve, error) => {

            this.database.executeRequest(`/api/cms/codes/${codesTable}`, 'get', {})
                .then((res: DatabaseResponse & { data: CodesRaw[] }) => {
                    resolve(res.data);
                }, (err) => {
                    error();
                });
        });
    }
    public getCodesTableData(codesTable): Promise<any> {
        return new Promise((resolve) => {
            this.database.executeRequest(`/api/cms/codes/${codesTable}/getData`, 'get', {}).then((res: any) => {
                resolve(res.data);
            }, (err) => {
                resolve([]);
            });
        });
    }
    public getCodesPermissions(codesTable): Promise<string[]> {
        return new Promise((resolve) => {
            this.database.executeRequest(`/api/cms/codes/${codesTable}/info`, 'get', {}).then((res: any) => {
                if (res.success === true) {
                    resolve(res.data[0].allowedActions.length === 0 ? ['INSERT', 'UPDATE', 'DELETE'] : res.data[0].allowedActions.map(item => item.codesActionName));
                }
                resolve([]);
            }, (err) => {
                resolve([]);
            });
        });
    }
    public deleteCodes(params, codesTable): Promise<boolean> {
        return new Promise((resolve) => {
            this._notif.create({
                title: 'Delete record?',
                message: 'Are you sure you want to delete this record, there is no turning back.',
                type: 'info',
                autoClose: false,
                backdrop: true,
                position: 'center-center',
                buttons: [
                    {
                        text: 'Delete',
                        action: (data) => {
                            this.deleteCodesFromDB(params, codesTable).then((status) => {
                                resolve(status);
                            });
                        }
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        action: (() => { })
                    }
                ]
            });
        });
    }
    public insertCodes(params, codesTable): Promise<boolean> {
        return new Promise((resolve) => {
            this.database
                .executeRequest(`/api/cms/codes/${codesTable}`, 'post', params)
                .then(
                    (res: any) => {
                        if (res.success === true) {
                            this._notif.create({
                                title: 'Success',
                                message: 'Row saved',
                                type: 'success',
                                autoClose: true,
                                duration: 3000,
                                position: 'top-right'
                            });
                            resolve(true);
                        } else {
                            resolve(false);
                            this.serverError();
                        }
                    },
                    err => {
                        resolve(false);
                        this.serverError();
                    }
                );
        });
    }
    public updateCodes(params, codesTable): Promise<boolean> {
        return new Promise((resolve) => {
            this.database
                .executeRequest(`/api/cms/codes/${codesTable}`, 'put', params)
                .then(
                    (res: any) => {
                        if (res.success === true) {
                            this._notif.create({
                                title: 'Success',
                                message: 'Row updated',
                                type: 'success',
                                autoClose: true,
                                duration: 3000,
                                position: 'top-right'
                            });
                            resolve(true);
                        } else {
                            resolve(false);
                            this.serverError();
                        }
                    },
                    err => {
                        resolve(false);
                        this.serverError();
                    }
                );
        });
    }
    public getCodesGather(gather, params, key, value): Promise<any[]> {
        return new Promise((resolve) => {
            this.database.executeRequest(`/api/cms/codes/gather/${gather}`, 'post', params)
                .then((gatherRes: DatabaseResponse & { data: any[] }) => {
                    let dropdownOptions = [];
                    if (key && value) {
                        dropdownOptions = gatherRes.data.map(row => ({ code: row[key], name: row[value] }));
                    } else {
                        dropdownOptions = gatherRes.data;
                    }
                    resolve(dropdownOptions);
                }, (err) => {
                    resolve([]);
                });
        });
    }
    public saveCodesDisplay(data, codesTable): Promise<boolean> {
        return new Promise((resolve) => {
            this.database.executeRequest(`/api/cms/codes/${codesTable}/updateDisplay`, 'post', data).then((response) => {
                this._notif.create({
                    title: 'Success',
                    message: 'Display settings saved',
                    type: 'success',
                    autoClose: true,
                    duration: 3000,
                    position: 'top-right'
                });
                resolve(true);
            }, (err) => {
                resolve(false);
                this.serverError();
            });
        });
    }
    private deleteCodesFromDB(data, codesTable): Promise<boolean> {
        return new Promise((resolve) => {
            this.database.executeRequest(`/api/cms/codes/${codesTable}/delete`, 'post', data).then((response: any) => {
                if (response.success === true) {
                    this._notif.create({
                        title: 'Success',
                        message: 'Record deleted.',
                        type: 'success',
                        autoClose: true,
                        duration: 3000,
                        position: 'top-right'
                    });
                    resolve(true);
                } else {
                    resolve(false);
                    this.serverError();
                }
            }, (err) => {
                resolve(false);
                this.serverError();
            });
        });
    }
    private serverError(): void {
        this._notif.create({
            title: 'There was an error',
            message: 'Please try again later.',
            type: 'error',
            autoClose: true,
            duration: 3000,
            position: 'top-right'
        });
    }
}

