import { Injectable, EventEmitter } from '@angular/core';
import { HttpRequest, HttpEventType, HttpResponse, HttpClient } from '@angular/common/http';
import { Database } from './database';
import { APP_API_HOST } from '../../../environments/environment';

@Injectable()
export class UploadService {
  _dCode = null;
  config = {
    storage: {
      maxImageSize: '5242880',
      maxFileSize: '10485760',
      maxImageWHSize: 1280,
      thumbWHSize: 250,
      allowedImageMimeTypes: [
        'image/bmp',
        'image/gif',
        'image/x-icon',
        'image/jpeg',
        'image/pjpeg',
        'image/pict',
        'image/png',
        'image/tiff'
      ],
      allowedFileMimeTypes: [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.oasis.opendocument.text',
        'application/excel',
        'application/vnd.ms-excel',
        'application/x-excel',
        'application/x-msexcel',
        'application/x-rar-compressed',
        'application/octet-stream',
        'application/zip',
        'application/octet-stream'
      ],
      allowedAudioMimeTypes: ['audio/mpeg3', 'audio/x-mpeg-3'],
      allowedVideoMimeTypes: ['video/mpeg', 'video/x-mpeg', 'video/x-flv', 'video/mp4', 'video/3gpp']
    }
  };

  activeUploads = [];

  maxImageSize = this.config.storage.maxImageSize;
  maxFileSize = this.config.storage.maxFileSize;

  allowedImageMimeTypes = this.config.storage.allowedImageMimeTypes;
  allowedFileMimeTypes = this.config.storage.allowedFileMimeTypes;
  allowedAudioMimeTypes = this.config.storage.allowedAudioMimeTypes;
  allowedVideoMimeTypes = this.config.storage.allowedVideoMimeTypes;
  constructor(private _database: Database, private httpClient: HttpClient) {
    this._database.getDeviceFingerprint(fp => {
      this._dCode = fp;
    });
  }
  // xhr: XMLHttpRequest = new XMLHttpRequest();

  uploadFiles($files, location: string, options) {
    const _files = Object.assign([], $files);
    let isUploading = false;
    const uploadFiles = [];
    const listen: EventEmitter<any> = new EventEmitter();
    const onUploadFinish: EventEmitter<any> = new EventEmitter();
    const onFileFinish: EventEmitter<any> = new EventEmitter();

    const updateLocation = (_location: string) => {
      location = _location;
    };

    const addFiles = files => {
      for (const file of files) {
        file['id'] = Math.floor(Math.random() * 10000) + 1;
        file['originalName'] = file.name;
        file['status'] = 'Waiting';
        file['percentage'] = 0;
        file['serverResponse'] = {};

        const validateFile = this.validateFile(file);
        file['valid'] = validateFile.valid;
        if (validateFile.valid === false) {
          file.status = 'Fail';
          file['message'] = validateFile.message;
        }

        if (file.type.indexOf('image') !== -1) {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            file['src'] = event.target.result;
          };
          reader.readAsDataURL(file);
          uploadFiles.push(file);
        } else {
          uploadFiles.push(file);
        }
      }
    };

    const getFiles = () => {
      return uploadFiles;
    };

    const startUpload = () => {
      if (isUploading === false) {
        isUploading = true;
        processNextFile();
      }
    };

    const processNextFile = () => {
      const index = uploadFiles.findIndex((value, i) => {
        return value.status === 'Waiting';
      });
      if (index !== -1) {
        processFile(index);
      } else {
        onUploadFinish.emit(uploadFiles);
      }
    };

    const processFile = index => {
      if (uploadFiles[index]) {
        if (uploadFiles[index].valid === false || uploadFiles[index].status === 'Finished') {
          processNextFile();
          // cb
          return;
        }
        uploadFiles[index]['status'] = 'Uploading';

        const formData = new FormData();
        formData.append('file[]', uploadFiles[index]);

        const url = `${APP_API_HOST}${location}`;
        const req = new HttpRequest('POST', url, formData, {
          withCredentials: true,
          reportProgress: true
        });

        const request = this.httpClient.request(req).subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              uploadFiles[index]['percentage'] = Math.round((100 * event.loaded) / event.total);
              listen.emit(uploadFiles);
            } else if (event instanceof HttpResponse) {
              uploadFiles[index]['percentage'] = 100;

              uploadFiles[index]['status'] =
                (event.body.data[0] && event.body.data[0].serverResponse.databaseCode == null) ||
                event.body.success === false
                  ? 'Fail'
                  : 'Finished';
              uploadFiles[index]['success'] =
                (event.body.data[0] && event.body.data[0].serverResponse.databaseCode == null) ||
                event.body.success === false
                  ? false
                  : true;
              uploadFiles[index]['message'] = event.body.message;
              if (event.body.success === true && event.body.data[0]) {
                uploadFiles[index]['originalName'] = event.body.data[0].originalName;
                uploadFiles[index]['newName'] = event.body.data[0].newName;
                uploadFiles[index]['extension'] = event.body.data[0].extension;
                uploadFiles[index]['serverResponse'] = event.body.data[0].serverResponse;
              }

              listen.emit(uploadFiles);
              this.removeFromActiveRequests(uploadFiles[index].id);
              onFileFinish.emit(uploadFiles[index]);
              processNextFile();
            }
          },
          error => {
            uploadFiles[index]['status'] = 'Fail';
            uploadFiles[index]['success'] = false;
            uploadFiles[index]['message'] = error;
            uploadFiles[index]['originalName'] = null;
            uploadFiles[index]['newName'] = null;
            uploadFiles[index]['extension'] = null;
            uploadFiles[index]['serverResponse'] = {};

            listen.emit(uploadFiles);
            onFileFinish.emit(uploadFiles[index]);
            this.removeFromActiveRequests(uploadFiles[index].id);
            processNextFile();
          }
        );
        this.activeUploads.push({ id: uploadFiles[index].id, request: request });
      } else {
        listen.emit(uploadFiles);
        onUploadFinish.emit(uploadFiles);
      }
    };

    const removeFile = fileID => {
      const index = uploadFiles.findIndex(item => {
        return item.id === fileID;
      });
      if (index !== -1) {
        if (uploadFiles[index].status === 'Uploading') {
          this.removeFromActiveRequests(uploadFiles[index].id);
          uploadFiles.splice(index, 1);
          processNextFile();
          listen.emit(uploadFiles);
        } else {
          uploadFiles.splice(index, 1);
          listen.emit(uploadFiles);
        }
      }
    };

    addFiles(_files);

    return {
      onUploadFinish: onUploadFinish,
      onFileFinish: onFileFinish,
      listen: listen,
      removeFile: removeFile,
      getFiles: getFiles,
      startUpload: startUpload,
      addFiles: addFiles,
      updateLocation: updateLocation
    };
  }

  removeFromActiveRequests(id) {
    const index = this.activeUploads.findIndex(item => {
      return item.id === id;
    });
    if (index !== -1) {
      this.activeUploads[index].request.unsubscribe();
      this.activeUploads.splice(index, 1);
    }
  }

  bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return '0 Byte';
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
    const size = bytes / Math.pow(1024, i);
    return size.toFixed(2) + ' ' + sizes[i];
  }

  checkPicture(url) {
    this.httpClient.get(url).subscribe(response => {});
  }

  validateFile(file) {
    const type = file.type.indexOf('image') !== -1 ? 'image' : 'file';
    const maxSize = type === 'image' ? this.maxImageSize : this.maxFileSize;
    if (file.size > maxSize) {
      return { valid: false, message: 'File exceed max file size' };
    } else {
      if (
        (type === 'image' && this.allowedImageMimeTypes.indexOf(file.type) === -1) ||
        (type === 'file' &&
          this.allowedFileMimeTypes.indexOf(file.type) === -1 &&
          this.allowedImageMimeTypes.indexOf(file.type) === -1 &&
          this.allowedAudioMimeTypes.indexOf(file.type) === -1 &&
          this.allowedVideoMimeTypes.indexOf(file.type) === -1)
        //  || (type === 'audio' && this.allowedAudioMimeTypes.indexOf(file.type) === -1) ||
        // (type === 'video' && this.allowedVideoMimeTypes.indexOf(file.type) === -1)
      ) {
        // Invalid file type
        return { valid: false, message: 'Invalid file type' };
      } else {
        return { valid: true, message: null };
      }
    }
  }

  validateFiles(options, files, callback) {
    const maxSize = options.type === 'image' ? this.maxImageSize : this.maxFileSize;
    const fileSizeFailed = [];
    const fileTypeFailed = [];
    let failedFiles = 0;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > maxSize) {
        // Invalid file size
        files[i].valid = false;
        files[i].status = 'Validation error';
        files[i].done = true;
        files[i].success = false;
        files[i].errorMessage =
          files[i].errorMessage +
          'File(' +
          this.bytesToSize(file.size) +
          ') exceeds maximum file size of ' +
          this.bytesToSize(maxSize) +
          '. ';
      }

      if (
        (options.type === 'image' && this.allowedImageMimeTypes.indexOf(file.type) === -1) ||
        (options.type === 'file' &&
          this.allowedFileMimeTypes.indexOf(file.type) === -1 &&
          this.allowedImageMimeTypes.indexOf(file.type) === -1 &&
          this.allowedAudioMimeTypes.indexOf(file.type) === -1 &&
          this.allowedVideoMimeTypes.indexOf(file.type) === -1) ||
        (options.type === 'audio' && this.allowedAudioMimeTypes.indexOf(file.type) === -1) ||
        (options.type === 'video' && this.allowedVideoMimeTypes.indexOf(file.type) === -1)
      ) {
        // Invalid file type
        files[i].valid = false;
        files[i].status = 'Validation error';
        files[i].done = true;
        files[i].success = false;
        files[i].errorMessage = files[i].errorMessage + 'File type is not allowed (' + file.type + '). ';
      }

      if (files[i].valid === false) {
        failedFiles = failedFiles + 1;
      }
    }

    callback(failedFiles, files);
  }
}
