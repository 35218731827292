import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';

// component
import { AppComponent } from './app.component';
import { BlankComponent } from './features/blank/blank.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { MessagesComponent } from './features/messages/messages.component';

import { LoginComponent } from './features/user/login/login.component';
import { ForgotPasswordComponent } from './features/user/forgot-password/forgot-password.component';
import { RegisterComponent } from './features/user/register/register.component';
import { UserManagementComponent } from './features/user/user-management/user-management.component';
import { UserAccessComponent } from './features/user/user-access/user-access.component';

// services
import { MenuItemsService } from './app.menu-items';
import { MessageService } from 'primeng/components/common/messageservice';

import { FeedbackViewComponent } from './features/feedback/feedback-view/feedback-view.component';
import { FeedbackListComponent } from './features/feedback/feedback-list/feedback-list.component';
import { FeedbackAddComponent } from './features/feedback/feedback-add/feedback-add.component';

// modules
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { NotificationModule } from './core/notification/notification.module';

import { CodesModule } from './features/codes/codes.module';
import { DynSharedModule } from './dynamyx/dynamyx.module';
import { CodesReactiveModule } from './features/codes-reactive/codes-reactive.module';

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    CoreModule,
    SharedModule,
    NotificationModule,
    DynSharedModule,
    CodesModule,
    CodesReactiveModule
  ],

  declarations: [
    AppComponent,
    BlankComponent,
    DashboardComponent,
    MessagesComponent,

    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    UserManagementComponent,
    UserAccessComponent,

    FeedbackViewComponent,
    FeedbackListComponent,
    FeedbackAddComponent
  ],
  providers: [Title, MessageService, MenuItemsService],
  bootstrap: [AppComponent]
})
export class AppModule {}
