/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./subheader.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../directives/ripple/ripple.directive";
import * as i3 from "../directives/icons/icons.directive";
import * as i4 from "@angular/common";
import * as i5 from "./subheader.component";
var styles_SubheaderComponent = [i0.styles];
var RenderType_SubheaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SubheaderComponent, data: {} });
export { RenderType_SubheaderComponent as RenderType_SubheaderComponent };
function View_SubheaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn-default btn-nav-back"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i2.RippleDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), i1.ɵdid(3, 81920, null, 0, i3.IconsDirective, [i1.ElementRef, i1.Renderer], { appIcon: [0, "appIcon"] }, null), (_l()(), i1.ɵted(-1, null, [" Back "]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = "tt-chevron-left"; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SubheaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "subheader-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "s-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubheaderComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "s-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.back; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 7, 0, currVal_1); }); }
export function View_SubheaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-subheader", [], null, null, null, View_SubheaderComponent_0, RenderType_SubheaderComponent)), i1.ɵdid(1, 114688, null, 0, i5.SubheaderComponent, [i4.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubheaderComponentNgFactory = i1.ɵccf("app-subheader", i5.SubheaderComponent, View_SubheaderComponent_Host_0, { title: "title", back: "back" }, {}, ["[subheader-left]", "[subheader-right]"]);
export { SubheaderComponentNgFactory as SubheaderComponentNgFactory };
