import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppGlobal } from '../../../core/services/app.global';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  appName: string;

  emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  formSubmited: boolean;

  userFirstName = new FormControl('', [Validators.required]);
  userLastName = new FormControl('', [Validators.required]);
  userEmail = new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]);
  userPassword = new FormControl('', [Validators.required, Validators.minLength(6)]);
  userConfirmPassword = new FormControl('', [Validators.required, Validators.minLength(6)]);

  userRemember: boolean;

  passwordType: string;
  confirmPasswordType: string;
  passwordShow: boolean;
  confirmPasswordShow: boolean;

  constructor(
    _global: AppGlobal,
    private router: Router,
  ) {
    this.appName = _global.get('appName');

    this.formSubmited = false;
    this.userRemember = false;
    this.passwordType = 'password';
    this.confirmPasswordType = 'password';
    this.passwordShow = false;
    this.confirmPasswordShow = false;
  }

  ngOnInit() {
  }



  togglePasswordShow() {
    this.passwordShow = !this.passwordShow;
    if (this.passwordShow) {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }
  toggleConfirmPasswordShow() {
    this.confirmPasswordShow = !this.confirmPasswordShow;
    if (this.confirmPasswordShow) {
      this.confirmPasswordType = 'text';
    } else {
      this.confirmPasswordType = 'password';
    }
  }


  registerHangler() {
    this.formSubmited = true;
    // console.log(this.userFirstName.value, this.userFirstName.valid);

    const valid = this.userFirstName.valid &&
      this.userLastName.valid &&
      this.userEmail.valid &&
      this.userPassword.valid &&
      this.userConfirmPassword.valid;

    if (valid) {
      const user = this.userEmail.value;
      document.cookie = `user=${user}`;
      this.router.navigate(['/']);
    }
  }

}
