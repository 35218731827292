import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

export interface NotificationOptions {
  title: string;
  message: string;
  icon?: string;
  type?: string;
  autoClose?: boolean;
  backdrop?: boolean;
  duration?: number;
  position?: string;
  inputs?: InputOption[];
  buttons?: ButtonOption[];
  // onRemove?: Function;
}
export interface InputOption {
  name: string;
  placeholder?: string;
  value?: string;
  type?: string;
}
export interface ButtonOption {
  text: string;
  action: Function;
  role?: string;
}

@Injectable()
export class NotificationService {
  private notificationSubject = new Subject<any>();
  activeNotifications: any = [];
  timeout;

  constructor(private router: Router) {
    router.events.pipe(filter(e => e instanceof NavigationEnd)).forEach(e => this.clear());
  }

  getNotifications(): Observable<any> {
    return this.notificationSubject.asObservable();
  }

  /**
   * Function to create new notification
   *
   * param: `notificationOptions: object`;
   * * **required**
   `title: string;`
   `message: string;`
   * * **optional**
   *
   | params:type | default values | description  |
   | :--- | :---: | ------------------ |
   | `icon:string` | null | choose from teslatools icon pack |
   | `type:string` | *'default'* | class ("default", "success", "error", "warn", "info") |
   | `autoClose:boolean` | *true* | should notification close its self automatically
   | `duration:number` | *3000* | notification duration |
   | `position:string`| *'bottom-right'* | position ("top-left", "top-center", "top-right", "center-center", "bottom-left", "bottom-center", "bottom-right") |
   | `inputs:InputOption[]` | empty[ ] | object params {placeholder?: string, value?: string, type?: string |
   | `buttons:ButtonOption[]` | empty[ ] | object params {text: string; action: Function, role?: string; cancel to close, no role: sends data of inputs if exists}; |
   *
   #
   * ------ **EXAMPLE**  ------
   * Minimal usage
   * ```
   this._notificationService.create({
      title: "Title",
      message: "Some message..."
   });
   ```
   * Full usage
   ```
   this._notificationService.create({
      title: "Title",
      message: "Some message...",
      icon: "tt2",
      type: "success",
      autoClose: true,
      duration: 3000,
      position: "top-right",
      inputs: [
        {
          name: 'code',
          type: 'number',
          placeholder: 'enter code'
        },
        {
          name: 'name',
          placeholder: 'enter name'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          action: () => {
            console.log('cancel');
          }
        },
        {
          text: 'OK',
          action: (data) => {
            console.log('ok');
          }
        }
      ]
   });
   ```
   * @param notificationOptions notification option object
   */
  create(notificationOptions: NotificationOptions) {
    // console.log(notificationOptions);
    const notification = this.generateNotification(notificationOptions);

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    const parent = document.getElementsByTagName('app-notification');
    // clear class for parent to add a new one
    parent[0].className = '';
    // add class for parent position; sets global position for all notifications
    parent[0].classList.add(notification.position);

    // add to active notifications array which is send in observable
    this.activeNotifications.push(notification);

    // setTimeout to close notification
    if (notification.autoClose === true) {
      this.activateTimer(notification);
    }

    // send new notification to notification component
    // console.log(this.activeNotifications.length, this.activeNotifications);
    this.notificationSubject.next(this.activeNotifications);
    // return notification;
  }

  /**
   * Remove notification from active list when user click on close() or duration is expired
   * @param id id of selected notification
   */
  remove(id) {
    let idx = null;
    this.activeNotifications.filter((notification, index) => {
      if (notification.id === id) {
        idx = index;
      }
    });

    if (idx !== null) {
      // console.log('removed notification index:', idx);
      this.activeNotifications.splice(idx, 1);

      this.notificationSubject.next(this.activeNotifications);
    }
  }

  /**
   * Clear all active notification on user request or route change
   */
  clear() {
    this.activeNotifications = [];

    // console.log('clear all');
    this.notificationSubject.next(this.activeNotifications);
  }

  generateNotification(notificationOptions) {
    const $self = notificationOptions;
    let autoAddedButtons = false;

    $self.id = generateId();
    $self.title = $self.title;
    $self.message = $self.message;
    $self.icon = $self.icon !== undefined ? $self.icon : null;
    $self.type = $self.type !== undefined ? $self.type.toLowerCase() : 'default';

    if ($self.autoClose !== undefined && $self.autoClose === true) {
      $self.autoClose = true;
    } else {
      // if autoClose is off and no buttons add-force a button to enable user to close an notification
      if ($self.buttons === undefined || $self.buttons.length === 0) {
        $self.buttons = [{ text: 'OK', role: 'cancel', action: () => {} }];
        autoAddedButtons = true;
      }
      $self.autoClose = false;
    }

    if ($self.backdrop === undefined) {
      $self.backdrop = false;
    } else if ($self.backdrop === true) {
      $self.backdrop = true;
      $self.autoClose = false; // if has backdrop turn off autoClose
      if ($self.buttons === undefined || $self.buttons.length === 0) {
        $self.buttons = [{ text: 'OK', role: 'cancel', action: () => {} }];
        autoAddedButtons = true;
      }
    } else {
      $self.backdrop = false;
    }

    $self.duration = $self.duration !== undefined && isNumber($self.duration) ? $self.duration : 3000;
    $self.position = $self.position !== undefined ? $self.position.toLowerCase() : 'bottom-right';
    $self.inputs = $self.inputs !== undefined ? $self.inputs : [];

    if ($self.buttons !== undefined && $self.buttons.length > 0) {
      $self.buttons = $self.buttons;
      $self.autoClose = false; // if has buttons turn off autoClose
    } else {
      if (autoAddedButtons === false) {
        $self.buttons = [];
      }
    }

    // onRemove: $self.onRemove !== undefined && isFunction($self.onRemove) ? $self.onRemove : null,

    // remove existing notification if new one is with backdrop!
    // prevents posibility to display some previous notification! UX BAD!!!
    if ($self.backdrop === true) {
      this.clear();
    }

    function generateId() {
      return Math.random().toString(36).substr(2, 6);
    }
    function isNumber(obj: any): boolean {
      return typeof obj === 'number';
    }
    function isFunction(obj: any): boolean {
      return typeof obj === 'function';
    }

    return $self;
  }

  activateTimer(notification) {
    const $this = this;
    const $delay = notification.duration ? notification.duration : 3000;

    function Timer(callback, delay) {
      let timerId,
        start,
        remaining = delay;

      this.pause = function () {
        window.clearTimeout(timerId);
        remaining -= +new Date() - start;
      };

      this.resume = function () {
        start = new Date();
        window.clearTimeout(timerId);
        timerId = window.setTimeout(callback, remaining);
      };

      this.resume();
    }

    $this.timeout = new Timer(() => {
      $this.remove(notification.id);
    }, $delay);
  }

  triggerHover() {
    this.timeout.pause();
  }
  cancelHover() {
    this.timeout.resume();
  }
}
