import { Component, OnInit } from '@angular/core';
declare var google: any;
import * as Chart from 'chart.js';
import 'chartjs-plugin-datalabels';
import { Database, DatabaseResponse } from '../../core/services/database';
import { UserStateService } from '../../core/services/userstate.service';
import { RiskValue } from '../../dynamyx/features/client/client-view/client-properties/client-properties.component';
import { LoadingDirectiveInterface } from '../../shared/directives/loading-directive/loading-directive.directive';

export enum RecommendationStatusCode {
  new = 1,
  inProgress = 2,
  postponed = 3,
  completed = 4
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  businessPartnerCode: number;

  propertiesData: Array<any> = [];
  treatmentValues = [
    { code: 1, name: 'Extreme', color: '#F35B5B', from: 0, to: 0.3, count: 0 },
    {
      code: 2,
      name: 'High',
      color: '#f9c841',
      from: 0.300001,
      to: 0.65,
      count: 0
    },
    {
      code: 3,
      name: 'Medium',
      color: '#31ca42',
      from: 0.650001,
      to: 0.85,
      count: 0
    },
    {
      code: 4,
      name: 'Low',
      color: '#3ebae1',
      from: 0.8500001,
      to: 1,
      count: 0
    },
    {
      code: 5,
      name: 'Undefined',
      color: '#cccccc',
      from: null,
      to: null,
      count: 0
    }
  ];

  logData: Array<any> = [];
  incidentsData: Array<any> = [];
  incidentsType: Array<any> = [];
  incidentsStatus: Array<any> = [];
  totalIncidents: number | null = null;
  recommendationsData: Array<any> = [];
  openRecommendations: Array<any> = [];
  recommendationsStatus: any = {
    labels: [],
    colors: [],
    data: []
  };

  loadingProperty: LoadingDirectiveInterface = { isLoading: true, text: 'Loading', show: true, class: 'default' };
  loadingLog: LoadingDirectiveInterface = { isLoading: true, text: 'Loading', show: true, class: 'default' };
  loadingIncidents: LoadingDirectiveInterface = { isLoading: true, text: 'Loading', show: true, class: 'default' };
  loadingRecommendations: LoadingDirectiveInterface = {
    isLoading: true,
    text: 'Loading',
    show: true,
    class: 'default'
  };

  constructor(private _database: Database, private user: UserStateService) {}

  ngOnInit() {
    const userData = this.user.getUserData();
    if (userData.userTypeName === 'Client') {
      this.businessPartnerCode = userData.userGroupValue;

      this.getData();
    }

    // admin charts
    this.runDashboardPieChart();
    this.runDashboardLineChart();
    this.runDashboardBarHorizontalChart();
    this.runDashboardBarChart();
    this.runDashboardPolarChart();
    this.runDashboardRadarChart();
  }

  getData() {
    this.loadPropertiesData();
    this.loadLogData();
    this.loadIncidentsData();
    this.loadRecommendationData();
  }

  loadPropertiesData() {
    this._database
      .executeRequest(`/api/clientdashboard/${this.businessPartnerCode}/properties`, 'get', {})
      .then((response: DatabaseResponse & { data: any }) => {
        this.propertiesData = response.data
          .map(d => {
            const riskValue: any = d.treatmentValueWeighted;
            const riskRow = this.treatmentValues.filter((value: RiskValue & { count: number }) => {
              if (riskValue === null) {
                return value.code === 5;
              } else {
                return riskValue >= value.from && riskValue <= value.to;
              }
            });
            this.treatmentValues = this.treatmentValues.map((value: RiskValue & { count: number }) => {
              if (value.code === riskRow[0].code) {
                return { ...value, count: (value.count += 1) };
              }
              return value;
            });

            d = { ...d, riskValue: riskValue, riskRow: riskRow[0] };
            return d;
          })
          .sort((a, b) => parseFloat(a.riskValue) - parseFloat(b.riskValue));
        this.loadingProperty = { isLoading: false, text: '', show: false, class: 'default' };
        // console.log(this.propertiesData);
      })
      .catch(err => console.warn(err));
  }

  loadLogData() {
    this._database
      .executeRequest(`/api/clientdashboard/${this.businessPartnerCode}/log`, 'get', {})
      .then((response: DatabaseResponse & { data: any }) => {
        this.logData = response.data.map(log => {
          log.url = '';
          log.hash = null;

          if (log.logData.businessPartnerPropertyCode) {
            log.url = `/property/${log.logData.businessPartnerPropertyCode}`;
          }
          if (log.logData.businessPartnerPropertyIncidentsCode) {
            // log.url = `${log.url}#incidents`;
            log.hash = 'incidents';
          }
          if (log.logData.propertyRiskAssessmentQuestionRecommendationCode) {
            // log.url = `${log.url}#recommendations`;
            log.hash = 'recommendations';
          }
          return log;
        });
        this.loadingLog = { isLoading: false, text: '', show: false, class: 'default' };
      })
      .catch(err => console.warn(err));
  }

  loadIncidentsData() {
    Promise.all([this.getIncidentsData(), this.getIncidentsStatus(), this.getIncidentsType()]).then(res => {
      // TOO SLOW
      //this.runDashboardClientLineChart();
      this.loadingIncidents = { isLoading: false, text: '', show: false, class: 'default' };
    });
  }
  private getIncidentsData() {
    return new Promise((resolve, reject) => {
      this._database
        .executeRequest(`/api/clientdashboard/${this.businessPartnerCode}/incidents`, 'get', {})
        .then((response: DatabaseResponse & { data: any }) => {
          this.incidentsData = response.data;
          resolve();
        })
        .catch(err => console.warn(err));
    });
  }
  private getIncidentsStatus() {
    return new Promise((resolve, reject) => {
      this._database
        .executeRequest(`/api/clientdashboard/${this.businessPartnerCode}/incidentsStatus`, 'get', {})
        .then((response: DatabaseResponse & { data: any }) => {
          this.incidentsStatus = response.data;
          resolve();
        })
        .catch(err => console.warn(err));
    });
  }
  private getIncidentsType() {
    return new Promise((resolve, reject) => {
      this._database
        .executeRequest(`/api/clientdashboard/${this.businessPartnerCode}/incidentsType`, 'get', {})
        .then((response: DatabaseResponse & { data: any }) => {
          this.incidentsType = response.data
            .filter(type => type.propertyIncidentsCount > 0)
            .sort((a, b) => parseFloat(b.propertyIncidentsCount) - parseFloat(a.propertyIncidentsCount));
          this.totalIncidents = 0;
          this.incidentsType.forEach(type => (this.totalIncidents += type.propertyIncidentsCount));
          resolve();
        })
        .catch(err => console.warn(err));
    });
  }

  loadRecommendationData() {
    Promise.all([this.getRecommendationData(), this.getRecommendationStatus()]).then(res => {
      this.runDashboardClientRecommendationsChart();
      this.loadingRecommendations = { isLoading: false, text: '', show: false, class: 'default' };
    });
  }
  private getRecommendationData() {
    return new Promise((resolve, reject) => {
      this._database
        .executeRequest(`/api/clientdashboard/${this.businessPartnerCode}/recommendations`, 'get', {})
        .then((response: DatabaseResponse & { data: any }) => {
          this.recommendationsData = response.data;
          this.openRecommendations = this.recommendationsData.filter(r => {
            return (
              r.recommendationStatusCode === RecommendationStatusCode.new ||
              r.recommendationStatusCode === RecommendationStatusCode.inProgress
            );
          });
          resolve();
        })
        .catch(err => console.warn(err));
    });
  }
  private getRecommendationStatus() {
    return new Promise((resolve, reject) => {
      this._database
        .executeRequest(`/api/clientdashboard/${this.businessPartnerCode}/recommendationsStatus`, 'get', {})
        .then((response: DatabaseResponse & { data: any }) => {
          this.recommendationsStatus = {
            labels: [],
            colors: [],
            data: []
          };
          response.data.forEach(value => {
            if (value.propertyRecommendationsCount === 0) {
              return;
            }
            this.recommendationsStatus.labels.push(value.recommendationStatusName);
            this.recommendationsStatus.colors.push(this.getRecommendationStatusColor(value.recommendationStatusCode));
            this.recommendationsStatus.data.push(value.propertyRecommendationsCount);
          });
          resolve();
        })
        .catch(err => console.warn(err));
    });
  }

  getRecommendationStatusColor(code) {
    if (code === 1) {
      // new
      return '#F35B5B';
    } else if (code === 2) {
      // in progress
      return '#3ebae1';
    } else if (code === 3) {
      // postponed
      return '#F9C841';
    } else {
      // completed
      return '#31ca42';
    }
  }

  runDashboardLineChart() {
    const data = [
      {
        data: [2300, 2000, 1000, 1200, 1400, 1800, 2300, 1900, 1600, 0],
        label: 'Users',
        borderColor: '#F37329',
        borderWidth: 2,
        backgroundColor: 'rgba(243, 98, 30, .5)',
        fill: true
      }
    ];

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];

    const ctx = 'dashboardLineChart';
    const _chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: data
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        elements: {
          line: {
            tension: 0.5
          }
        },
        legend: { display: false },
        plugins: {
          datalabels: {
            display: false
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        }
      }
    });
  }

  runDashboardPieChart() {
    const colors = ['#F37329', 'rgba(243, 98, 30, .5)'];

    const labels = ['Active', 'Inactive'];

    const data = [31, 12];

    const ctx = 'dashboardPieChart';

    const _chart = new Chart(ctx, {
      type: 'pie',
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          position: 'right',
          labels: {
            usePointStyle: true,
            padding: 15,
            fontColor: '#787878',
            fontSize: 12
          }
        },
        tooltips: {
          enabled: false
        },
        plugins: {
          datalabels: {
            color: 'white',
            font: {
              weight: 'bold',
              size: 30
            },
            formatter: Math.round
          }
        }
      },
      data: {
        labels: labels,
        datasets: [
          {
            backgroundColor: colors,
            data: data
          }
        ]
      }
    });
  }

  runDashboardBarHorizontalChart() {
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];

    const colors = ['#F37329', '#F37329', '#F37329', '#F37329', '#F37329', '#F37329'];

    const data = [12, 8, 9, 3, 14, 6, 15];

    const ctx = 'dashboardBarHorizontalChart';

    const _chart = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Posts',
            backgroundColor: colors,
            data: data
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: { display: false },
        title: {
          display: false
        },
        layout: {
          padding: {
            top: 15
          }
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: 5,
            color: function (context) {
              return context.dataset.backgroundColor;
            },
            font: {
              weight: '500',
              size: 20
            }
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false
              },
              ticks: {
                suggestedMin: 0,
                beginAtZero: true,
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false
              },
              ticks: {
                display: true
              }
            }
          ]
        }
      }
    });
  }

  runDashboardBarChart() {
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];

    const colors = ['#F37329', '#F37329', '#F37329', '#F37329', '#F37329', '#F37329'];
    const colors1 = [
      'rgba(243, 98, 30, .5)',
      'rgba(243, 98, 30, .5)',
      'rgba(243, 98, 30, .5)',
      'rgba(243, 98, 30, .5)',
      'rgba(243, 98, 30, .5)',
      'rgba(243, 98, 30, .5)'
    ];

    const data = [8, 5, 6, 3, 12, 5, 13];
    const data1 = [5, 2, 11, 1, 1, 5, 13];

    const ctx = 'dashboardBarChart';

    const _chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Views',
            backgroundColor: colors,
            data: data
          },
          {
            label: 'Comments',
            backgroundColor: colors1,
            data: data1
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: { display: true },
        title: {
          display: false,
          text: 'Title'
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                suggestedMin: 0,
                beginAtZero: true
              }
            }
          ]
        },
        plugins: {
          datalabels: {
            display: false
          }
        }
      }
    });
  }

  runDashboardPolarChart() {
    const labels = ['News', 'Blog', 'Reviews'];

    const colors = ['#F37329', 'rgba(243, 98, 30, .75)', 'rgba(243, 98, 30, .5)'];

    const data = [36, 21, 13];

    const ctx = 'dashboardPolarChart';
    const _chart = new Chart(ctx, {
      type: 'polarArea',
      options: {
        responsive: true,
        maintainAspectRatio: true,
        title: {
          display: false
        },
        plugins: {
          datalabels: {
            display: false
          }
        },
        legend: {
          position: 'right',
          labels: {
            usePointStyle: true,
            padding: 15,
            fontColor: '#787878',
            fontSize: 12
          }
        },
        layout: {
          padding: {
            top: 15
          }
        }
      },
      data: {
        labels: labels,
        datasets: [
          {
            backgroundColor: colors,
            data: data
          }
        ]
      }
    });
  }

  runDashboardRadarChart() {
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];

    const datasets = [
      {
        label: 'News',
        fill: true,
        backgroundColor: 'rgba(243, 98, 30, .1)',
        borderColor: 'rgba(243, 98, 30, .5)',
        pointBorderColor: '#FFF',
        pointBackgroundColor: '#F37329',
        data: [20, 8, 6, 5, 8, 10]
      },
      {
        label: 'Blog',
        fill: true,
        backgroundColor: 'rgba(64, 83, 100, .1)',
        borderColor: 'rgba(64, 83, 100, .5)',
        pointBorderColor: '#FFF',
        pointBackgroundColor: '#405364',
        data: [14, 15, 9, 7, 3, 8]
      },
      {
        label: 'Reviews',
        fill: true,
        backgroundColor: 'rgba(91, 192, 222, .1)',
        borderColor: 'rgba(91, 192, 222, .5)',
        pointBorderColor: '#FFF',
        pointBackgroundColor: '#5bc0de',
        data: [6, 6, 7, 2, 8, 1]
      }
    ];

    const ctx = 'dashboardRadarChart';
    const _chart = new Chart(ctx, {
      type: 'radar',
      options: {
        responsive: true,
        maintainAspectRatio: true,
        title: {
          display: false
        },
        plugins: {
          datalabels: {
            display: false
          }
        },
        legend: {
          position: 'right',
          labels: {
            usePointStyle: true,
            padding: 15,
            fontColor: '#787878',
            fontSize: 12
          }
        },
        layout: {
          padding: {
            top: 15
          }
        }
      },
      data: {
        labels: labels,
        datasets: datasets
      }
    });
  }

  // CLIENT CHARTS
  runDashboardClientRecommendationsChart() {
    // const labels = ['Extreme', 'High', 'Medium', 'Low', 'Undefined'];
    // const colors = ['#f35b5b', '#f9c841', '#31ca42', '#3ebae1', '#cccccc'];
    // const data = [12, 8, 9, 14, 6];
    const labels = this.recommendationsStatus.labels;
    const colors = this.recommendationsStatus.colors;
    const data = this.recommendationsStatus.data;

    const ctx = 'dashboardClientRecommendationsChart';
    const _chart = new Chart(ctx, {
      type: 'doughnut',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          position: 'top',
          text: 'All Recommendations'
        },
        legend: {
          // display: false,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            padding: 15,
            fontColor: '#787878',
            fontSize: 12
          },
          onClick: () => {} // disable toggle behaviour
        },
        tooltips: {
          enabled: true
        },
        plugins: {
          datalabels: {
            color: 'white',
            font: {
              weight: 'bold',
              size: 24
            },
            formatter: Math.round
          }
        }
      },
      data: {
        labels: labels,
        datasets: [
          {
            backgroundColor: colors,
            data: data
          }
        ]
      }
    });
  }

  runDashboardClientLineChart() {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dSet: any[] = [
      { month: months.slice(new Date().getMonth() - 5)[0], count: 0 },
      { month: months.slice(new Date().getMonth() - 4)[0], count: 0 },
      { month: months.slice(new Date().getMonth() - 3)[0], count: 0 },
      { month: months.slice(new Date().getMonth() - 2)[0], count: 0 },
      { month: months.slice(new Date().getMonth() - 1)[0], count: 0 },
      { month: months.slice(new Date().getMonth())[0], count: 0 }
    ];

    const labels = [
      months.slice(new Date().getMonth() - 5)[0],
      months.slice(new Date().getMonth() - 4)[0],
      months.slice(new Date().getMonth() - 3)[0],
      months.slice(new Date().getMonth() - 2)[0],
      months.slice(new Date().getMonth() - 1)[0],
      months.slice(new Date().getMonth())[0]
    ];

    // get only if not older than a 6 months
    const beforeHalfYear = new Date();
    beforeHalfYear.setMonth(beforeHalfYear.getMonth() - 5);
    this.incidentsData.forEach(inc => {
      const d = inc.businessPartnerPropertyIncidentsDate
        ? new Date(inc.businessPartnerPropertyIncidentsDate)
        : new Date('1900');
      if (+new Date(beforeHalfYear) > +new Date(d)) {
        return;
      }
      dSet.forEach(ds => {
        if (ds.month === months[d.getMonth()]) {
          ds.count += 1;
        }
      });
    });

    const data = [
      {
        data: dSet.map(ds => ds.count),
        label: 'Incidents',
        borderColor: '#F37329',
        borderWidth: 2,
        backgroundColor: 'rgba(243, 98, 30, .5)',
        fill: true
      }
    ];
    // console.log(this.incidentsData, dSet, labels);

    const ctx = 'dashboardClient6MonthsIncidentsChart';
    const _chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: data
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0.5
          }
        },
        legend: { display: false },
        plugins: {
          datalabels: {
            display: false
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                suggestedMin: 0,
                beginAtZero: true
              }
            }
          ]
        }
      }
    });
  }
}
